import { SelectProps } from '@cloudscape-design/components/select';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import moment from 'moment-timezone';

import { BLOCK_CATEGORY_MAP, TAGS_LIST } from './constants/block-constants';
import { AuthConstants } from './constants/string-constants';
import AuthProfileData from './interfaces/AuthProfileData';
import { ArtifactEnvironment } from './types/enums';
import { AutomationExecutableDetail } from './types/interfaces';

/**
 * Copy the text to the clipboard.
 * @param text The text to copy to clipboard.
 */
export async function copyToClipboard(text: string) {
  return await navigator.clipboard.writeText(text);
}

/**
 * Capitalizes the first letter of a string and keeps the rest of the string unchanged.
 * @param text - The input string to be capitalized.
 * @returns The input string with the first letter capitalized.
 */
export function toSentenceCase(text: string) {
  if (!text) {
    return text;
  }

  // Capitalize the first letter and keep the rest of the string as is
  return text.charAt(0).toUpperCase() + text.slice(1);
}

/**
 * Extracts the name of the code pipeline service from the URL.
 * @param pipelineUrl URL of the code pipeline.
 * @return Name of the code pipeline service.
 */
export function extractPipelineName(pipelineUrl: string) {
  const matchResult = pipelineUrl.match(/^.*pipelines\/(.*?)\/view.*/);
  return matchResult ? matchResult[1] : null;
}

/**
 * Get the URL of the code pipeline from the name.
 * @param codePipelineName Name of the code pipeline.
 * @param region AWS region.
 * @return URL of the code pipeline.
 */
export function getCodePipelineURL(codePipelineName: string, region: string) {
  return 'https://{{{region}}}.console.aws.amazon.com/codesuite/codepipeline/pipelines/{{{codePipelineName}}}/view?region={{{region}}}'
    .replaceAll('{{{codePipelineName}}}', codePipelineName)
    .replaceAll('{{{region}}}', region);
}

/**
 * Extracts the name of the code repository name from the URL.
 * @param codeRepoUrl URL of the code repository.
 * @return Name of the code repository.
 */
export function extractCodeRepoName(codeRepoUrl: string) {
  const matchResult = codeRepoUrl.match(/^.*(tw-automation-.*?-cc-repo).*/);
  return matchResult ? matchResult[1] : null;
}

/**
 * Remove the prefix from the string value.
 * @param value The string value
 * @param prefix The prefix to remove
 * @return The string with the prefix removed if the prefix exists.
 */
export function removePrefix(value: string, prefix: string) {
  const hasPrefix = value.indexOf(prefix) === 0;
  return hasPrefix ? value.substring(prefix.length) : value;
}

/**
 * @returns user Auth Profile string (client id and role).
 */
export const getAuthProfileString = async () => {
  const response = await Auth.currentUserInfo();
  return response.attributes ? response.attributes['custom:clientIdAndRole'] : null;
};

export const createAuthProfileDataObject = async () => {
  const authProfileString = await getAuthProfileString();
  if (!authProfileString) {
    return null;
  }

  const authProfileArray = authProfileString.split(AuthConstants.MultipleClientIdAndRoleSeparationDelimiter);

  // sorting string to group all client id together
  // sample authProfileObj = [{ClientId : C1, Role : R1},{ClientId : C1, Role : R2},{ClientId : C2, Role : R1}]
  const authProfileObj: AuthProfileData[] = authProfileArray.sort().map((authProfilePair: string) => {
    const splitArray = authProfilePair.split(AuthConstants.ClientIdAndRoleSeparationDelimiter);
    const pairObj: AuthProfileData = {
      ClientId: splitArray[0],
      Role: splitArray[1],
    };
    return pairObj;
  });

  return authProfileObj;
};

/**
 * Convert the date or moment to string with ISO format without seconds and with time zone abbreviation.
 * @param date The date which is to be converted.
 * @return The ISO formatted string with time zone
 */
export const convertDateToISOWithTimeZone = (date: Date | moment.Moment | undefined) => {
  if (!date) {
    return null;
  }

  const currentTimeZoneAbbr = moment().tz(moment.tz.guess()).zoneAbbr();
  return moment(date).format('YYYY-MM-DD HH:mm ') + currentTimeZoneAbbr;
};

/**
 * Get valid username.
 * @param username username having alias
 * @return valid username without AmazonFederate_ prefix
 */
export const getValidUsername = (username: string) => {
  return username?.split('AmazonFederate_')[1] || username;
};

/**
 * Get list of block tags.
 * @return list of block tags.
 */
export const getBlockTagsListForSelection = (): SelectProps.Options => {
  return TAGS_LIST.map((tag) => {
    return {
      label: tag,
      value: tag,
    };
  });
};

/**
 * Get tags list for selection from drop down
 * @return tags list for selection
 */
export const getBlockCategoryListForSelection = (): SelectProps.Options => {
  return Object.keys(BLOCK_CATEGORY_MAP).map((category) => {
    return {
      label: BLOCK_CATEGORY_MAP[category].name,
      value: category,
    };
  });
};

/**
 * Get Ace object to load assets required for Code Editor
 * @return ace object
 */
export const loadAce = async () => {
  const ace = await import('ace-builds');
  await import('ace-builds/webpack-resolver');
  return ace;
};

/**
 * Checks if the provided string is a valid JSON
 * @return true if is valid JSON
 */
export const isValidJSON = (value: string) => {
  try {
    JSON.parse(value);
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * Gets the matches count string
 * @return matches string
 */
export const getMatchesCountText = (count: number) => {
  return count === 1 ? '1 match' : `${count} matches`;
};

/**
 * Retrieves UI input data from a pre-signed URL based on the specified artifact environment.
 *
 * @param executableList - An array of AutomationExecutableDetail objects.
 * @param artifactEnvironment - The artifact environment to filter by.
 * @returns The UI input data obtained from the pre-signed URL, or undefined if not found.
 */

export const getUIInputJSONFromPresignedURL = async (
  executableList: AutomationExecutableDetail[],
  artifactEnvironment: ArtifactEnvironment,
) => {
  if (!executableList) {
    return;
  }
  const preSignedURL = executableList.find(
    (item) => item.artifactEnvironment === artifactEnvironment,
  )?.uiInputPreSignedURL;
  try {
    return preSignedURL && (await axios.get(preSignedURL)).data;
  } catch (e) {
    return undefined;
  }
};

/**
 * Check if clientId and Role exists in selected auth profile
 *
 * @param selectedAuthProfile selected auth profile
 * @return true when clientId and role is missing in selected auth profile
 */
export const checkIfClientIdAndRoleMissing = (selectedAuthProfile: AuthProfileData): boolean => {
  return !selectedAuthProfile?.ClientId || !selectedAuthProfile.Role;
};

/**
 * Removes properties from an object if they are undefined or if all their child properties are undefined.
 *
 * @param {any} objectToSanitize - The object from which to remove undefined properties.
 * @returns {any} A new object with undefined properties and their child properties removed.
 *
 * @example
 * const obj = {
 *   a: undefined,
 *   b: 'B',
 *   c: { d: undefined },
 *   e: { f: { g: undefined } },
 *   h: {},
 * };
 *
 * const cleanedObj = removeUndefinedProperties(obj);
 * console.log(cleanedObj); // Output: { b: 'B', e: undefined }
 */
export const removeUndefinedProperties = (objectToSanitize: any): any => {
  if (typeof objectToSanitize !== 'object' || objectToSanitize === null) {
    return objectToSanitize;
  }

  const entries = Object.entries(objectToSanitize);
  const filteredEntries = entries.flatMap(([key, value]) => {
    if (value === undefined) {
      return [];
    } else if (typeof value === 'object') {
      const childObj = removeUndefinedProperties(value);
      return Object.keys(childObj).length === 0 ? [] : [[key, childObj]];
    } else {
      return [[key, value]];
    }
  });

  return Object.fromEntries(filteredEntries);
};
