import { yupResolver } from '@hookform/resolvers/yup';
import { UseFormProps } from 'react-hook-form/dist/types';
import { boolean, InferType, number, object, string } from 'yup';
import { WindowsEC2SourceType } from '../../../../common/types/enums';
import {
  AUTOMATION_NAME,
  DASHBOARD_LINK,
  EMAIL_ADDRESS,
  EXECUTION_AWS_REGION,
  EXECUTION_ENVIRONMENT_MEMORY,
  EXECUTION_ENVIRONMENT_RESOURCE_ID,
  EXECUTION_ENVIRONMENT_RESOURCE_TYPE,
  EXECUTION_ENVIRONMENT_STORAGE,
  IS_WINDOWS_APPLICATION_AUTOMATION,
  SUPPORTED_CONCURRENT_EXECUTIONS,
} from '../constants/form-constants';

export const AutomationDetailsSchema = object({
  [AUTOMATION_NAME]: string().required().min(3).max(200).label('Automation name'),
  [EMAIL_ADDRESS]: string().required().email().min(10).max(100).label('Email address'),
  [DASHBOARD_LINK]: string().url().min(10).max(1000).label('Analytics URL'),
  [EXECUTION_ENVIRONMENT_RESOURCE_TYPE]: string()
    .matches(new RegExp(getExecutionEnvironmentResourceTypeRegex()))
    .label('Resource type'),
  [EXECUTION_ENVIRONMENT_MEMORY]: number().moreThan(0).label('Required memory'),
  [EXECUTION_ENVIRONMENT_STORAGE]: number().moreThan(0).label('Required storage'),
  [EXECUTION_ENVIRONMENT_RESOURCE_ID]: string().matches(new RegExp('^(ami|i)-[0-9a-z]{8,17}$')).label('Resource ID'),
  [EXECUTION_AWS_REGION]: string().matches(new RegExp('^[a-z]{2}-[a-z]{4,9}-[0-9]$')).label('AWS region'),
  [SUPPORTED_CONCURRENT_EXECUTIONS]: number().moreThan(0).label('Supported concurrent executions'),
  [IS_WINDOWS_APPLICATION_AUTOMATION]: boolean().label('Is Windows application automation'),
});

export type AutomationDetailsSchemaType = InferType<typeof AutomationDetailsSchema>;

export const AutomationDetailsFormProps: UseFormProps<AutomationDetailsSchemaType, any> = {
  mode: 'onSubmit',
  reValidateMode: 'onChange',
  resolver: yupResolver(AutomationDetailsSchema),
};

function getExecutionEnvironmentResourceTypeRegex() {
  let regex = '';
  Object.values(WindowsEC2SourceType).forEach((resourceType) => {
    regex += resourceType + '|';
  });
  return regex.slice(0, -1);
}
