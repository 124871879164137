import { ContentLayout, Header, SpaceBetween } from '@cloudscape-design/components';
import React from 'react';
import { useSelector } from 'react-redux';

import { AutomationRequestStatus } from '../../../../common/enums/automation-request-enums';
import IAutomationRequestDetails from '../../../../common/interfaces/AutomationRequestDetails';
import { LoadingStatus } from '../../../../common/types/enums';
import { selectListAutomationRequestsResponse } from '../../../../slices/AutomationRequestSlice';
import AutomationOngoingRequestsTable from './AutomationOngoingRequestsTable';
import AutomationQueuedRequestsTable from './AutomationQueuedRequestsTable';

function getAutomationSegregatedRequestsList(listAutomationRequestsData: {
  data: IAutomationRequestDetails[];
  status: LoadingStatus;
}) {
  const queuedRequestsList: IAutomationRequestDetails[] = [];
  const ongoingRequestsList: IAutomationRequestDetails[] = [];
  if (listAutomationRequestsData.status !== LoadingStatus.SUCCESS) {
    return { queuedRequestsList, ongoingRequestsList };
  }

  listAutomationRequestsData.data.forEach((automationRequest) => {
    if (automationRequest.status === AutomationRequestStatus.REQUESTED) {
      queuedRequestsList.push(automationRequest);
    } else {
      ongoingRequestsList.push(automationRequest);
    }
  });

  return { queuedRequestsList, ongoingRequestsList };
}

export default function AutomationRequestsPageContent() {
  const listAutomationRequestsData = useSelector(selectListAutomationRequestsResponse);

  const { queuedRequestsList, ongoingRequestsList } = getAutomationSegregatedRequestsList(listAutomationRequestsData);

  return (
    <ContentLayout header={<Header variant="h1">Request management</Header>}>
      <SpaceBetween size="xl">
        <AutomationQueuedRequestsTable requestsList={queuedRequestsList} />
        <AutomationOngoingRequestsTable requestsList={ongoingRequestsList} />
      </SpaceBetween>
    </ContentLayout>
  );
}
