import { ControlledInput, ControlledInputProps } from '@amzn/react-hook-form-cloudscape';
import { FormField } from '@cloudscape-design/components';
import { FormFieldProps } from '@cloudscape-design/components/form-field';
import { InputProps } from '@cloudscape-design/components/input';
import { FieldValues, get, useFormContext } from 'react-hook-form';

interface IInputFormFieldProps extends Partial<InputProps>, FormFieldProps, ControlledInputProps<FieldValues> {
  name: string;
}

export default function InputFormField(props: IInputFormFieldProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <FormField {...props} errorText={get(errors, `${props.name}.message`)}>
      <ControlledInput {...props} name={props.name} control={control} />
    </FormField>
  );
}
