import { Container, Header, SpaceBetween, Tabs } from '@cloudscape-design/components';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { QUERY_PARAMS } from '../../../../common/constants/string-constants';
import { BlockDetailTabId } from '../../../../common/types/enums';
import BoxElement from '../../../../components/polaris/BoxElement';
import { selectGetBlockResponse } from '../../../../slices/BlockDetailSlice';
import BlockExecutables from './BlockExecutables';
import BlockFunctionsTab from './BlockFunctionsTab';

export default function BlockDetailContainer() {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentBlock = useSelector(selectGetBlockResponse);
  const [activeTabId, setActiveTabId] = useState(BlockDetailTabId.EXECUTABLES.toString());

  useEffect(() => {
    searchParams.set(QUERY_PARAMS.tabId, searchParams.get(QUERY_PARAMS.tabId) || activeTabId);
    setSearchParams(searchParams);
    setActiveTabId(searchParams.get(QUERY_PARAMS.tabId) || activeTabId);
  }, []);

  return (
    currentBlock.data && (
      <SpaceBetween size="l">
        <Container header={<Header variant="h2">Summary</Header>}>
          <BoxElement headerText="" description={currentBlock.data.summary || '-'} />
        </Container>
        <Tabs
          ariaLabel="block-detail"
          activeTabId={activeTabId}
          onChange={(tabDetails) => {
            searchParams.set(QUERY_PARAMS.tabId, tabDetails.detail.activeTabId);
            setActiveTabId(tabDetails.detail.activeTabId);
            setSearchParams(searchParams);
          }}
          tabs={[
            {
              label: 'Executables',
              id: BlockDetailTabId.EXECUTABLES,
              content: <BlockExecutables />,
            },
            {
              label: 'Functions',
              id: BlockDetailTabId.FUNCTIONS,
              content: <BlockFunctionsTab />,
            },
          ]}
        />
      </SpaceBetween>
    )
  );
}
