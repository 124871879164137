import { AppLayout, ContentLayout, Flashbar, SpaceBetween } from '@cloudscape-design/components';
import { FlashbarProps } from '@cloudscape-design/components/flashbar';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { checkIfClientIdAndRoleMissing } from '../../common/utils';
import GlobalHelpPanel from '../../components/GlobalHelpPanel';
import GlobalNavigationPanel from '../../components/GlobalNavigationPanel';
import { AuthProfileSelector } from '../../slices/AuthStateSlice';
import { listAutomations, selectListAutomationsResponse } from '../../slices/AutomationDetailSlice';
import {
  selectIsGetStartedContainerDismissed,
  selectIsNavigationPanelOpen,
  setNavigationPanelOpen,
} from '../../slices/SitePreferencesSlice';
import { AppDispatch } from '../../store/store';
import AutomationsTable from './components/AutomationsTable';
import GetStartedContainer from './components/GetStartedContainer';
import HomePageContentHeader from './components/HomePageContentHeader';

function HomePageContent() {
  const isGetStartedContainerDismissed = useSelector(selectIsGetStartedContainerDismissed);
  return (
    <ContentLayout header={<HomePageContentHeader />}>
      <SpaceBetween size="xl">
        {!isGetStartedContainerDismissed && <GetStartedContainer />}
        <AutomationsTable />
      </SpaceBetween>
    </ContentLayout>
  );
}

export default function HomePage() {
  const dispatch = useDispatch<AppDispatch>();
  const isNavigationPanelOpen = useSelector(selectIsNavigationPanelOpen);
  const [notifications, setNotifications] = useState<FlashbarProps.MessageDefinition[]>([]);
  const selectedAuthProfile = useSelector(AuthProfileSelector);
  const listAutomationsResponse = useSelector(selectListAutomationsResponse);

  const removeNotification = (notificationId: string) => {
    const filteredNotification = notifications.filter((notification) => notification.id !== notificationId);
    setNotifications(filteredNotification);
  };

  const navigationChangeHandler = () => {
    dispatch(setNavigationPanelOpen(!isNavigationPanelOpen));
  };

  useEffect(() => {
    if (checkIfClientIdAndRoleMissing(selectedAuthProfile)) {
      return;
    }

    void dispatch(listAutomations());
  }, [selectedAuthProfile]);

  useEffect(() => {
    if (listAutomationsResponse.error) {
      setNotifications((prevState) => [
        ...prevState,
        {
          header: 'Failed to load automations',
          type: 'error',
          content: listAutomationsResponse.error,
          dismissible: true,
          dismissLabel: 'Dismiss error',
          onDismiss: () => removeNotification('api-error'),
          id: 'api-error',
        },
      ]);
    }
  }, [listAutomationsResponse.error]);

  return (
    <AppLayout
      headerSelector="#tw-header"
      notifications={<Flashbar items={notifications} />}
      stickyNotifications={false}
      content={<HomePageContent />}
      navigation={<GlobalNavigationPanel />}
      navigationOpen={isNavigationPanelOpen}
      onNavigationChange={navigationChangeHandler}
      tools={<GlobalHelpPanel />}
    />
  );
}
