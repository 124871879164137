import { AppLayout, ContentLayout, Header, SpaceBetween } from '@cloudscape-design/components';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { HOME } from '../../../common/constants/route-paths';
import { getAutomationDetailPagePath } from '../../../common/page-route-path';
import { LoadingStatus } from '../../../common/types/enums';
import GlobalHelpPanel from '../../../components/GlobalHelpPanel';
import GlobalNavigationPanel from '../../../components/GlobalNavigationPanel';
import BreadcrumbLinkGroup from '../../../components/polaris/BreadcrumbLinkGroup';
import { selectCreateAutomationResponse } from '../../../slices/AutomationDetailSlice';
import { selectIsNavigationPanelOpen, setNavigationPanelOpen } from '../../../slices/SitePreferencesSlice';
import { AppDispatch } from '../../../store/store';
import CreateAutomationForm from './components/CreateAutomationForm';

function CreateAutomationPageContent() {
  return (
    <ContentLayout header={<Header variant="h1">Create automation</Header>}>
      <SpaceBetween size="xl">
        <CreateAutomationForm />
      </SpaceBetween>
    </ContentLayout>
  );
}

export default function CreateAutomationPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const isNavigationPanelOpen = useSelector(selectIsNavigationPanelOpen);
  const createAutomationResponse = useSelector(selectCreateAutomationResponse);

  const navigationChangeHandler = () => {
    dispatch(setNavigationPanelOpen(!isNavigationPanelOpen));
  };

  useEffect(() => {
    if (createAutomationResponse.status === LoadingStatus.SUCCESS) {
      navigate(getAutomationDetailPagePath(createAutomationResponse.automationId));
    }
  }, [createAutomationResponse?.status]);

  return (
    <AppLayout
      headerSelector="#tw-header"
      content={<CreateAutomationPageContent />}
      contentType="form"
      navigation={<GlobalNavigationPanel />}
      navigationOpen={isNavigationPanelOpen}
      onNavigationChange={navigationChangeHandler}
      tools={<GlobalHelpPanel />}
      breadcrumbs={
        <BreadcrumbLinkGroup
          links={[
            {
              text: 'Home',
              href: HOME,
            },
            {
              text: 'Create automation',
              href: '#',
            },
          ]}
        />
      }
    />
  );
}
