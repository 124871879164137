import {
  Badge,
  CollectionPreferences,
  CollectionPreferencesProps,
  StatusIndicator,
  TableProps,
} from '@cloudscape-design/components';
import {
  automationIdColId,
  automationNameColId,
  automationStatusColId,
  awsAccountIdColId,
  codePipelineColId,
  codeRepositoryColId,
  colIdToNameMap,
  createdByColId,
  createdOnColId,
  lastBuildStatusColId,
  lastUpdatedByColId,
  processClientIdColId,
} from './automations-table-column-constants';
import { IListAutomationsData } from '../../../common/types/interfaces';
import { PipelineBuildStatus } from '../../../common/types/enums';
import CopyText from '../../../components/CopyText';
import { extractCodeRepoName, extractPipelineName } from '../../../common/utils';
import TALink from '../../../components/polaris/TALink';
import { getAutomationDetailPagePath } from '../../../common/page-route-path';
import React from 'react';
import { AutomationStatusConfig } from '../../../common/constants/automation-constants';

export const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<IListAutomationsData>[] = [
  {
    id: automationNameColId,
    header: colIdToNameMap[automationNameColId],
    cell: (item) => <TALink href={getAutomationDetailPagePath(item.automationId)}>{item.automationName}</TALink>,
    sortingField: automationNameColId,
  },
  {
    id: automationIdColId,
    header: colIdToNameMap[automationIdColId],
    cell: (item) => (
      <CopyText
        copyText={item.automationId}
        copyButtonLabel="Copy automation ID"
        successText="Automation ID copied"
        errorText="Failed to copy"
      />
    ),
  },
  {
    id: processClientIdColId,
    header: colIdToNameMap[processClientIdColId],
    cell: (item) => item.processClientId,
    sortingField: processClientIdColId,
  },
  {
    id: codePipelineColId,
    header: colIdToNameMap[codePipelineColId],
    cell: (item) =>
      item.codePipeline ? (
        <TALink external externalIconAriaLabel="Opens in a new tab" href={item.codePipeline}>
          {extractPipelineName(item.codePipeline)}
        </TALink>
      ) : (
        '-'
      ),
  },
  {
    id: codeRepositoryColId,
    header: colIdToNameMap[codeRepositoryColId],
    cell: (item) =>
      item.codeRepository ? (
        <CopyText
          copyText={extractCodeRepoName(item.codeRepository)}
          copyButtonLabel="Copy repository name"
          successText="Repository name copied"
          errorText="Failed to copy"
        />
      ) : (
        '-'
      ),
  },
  {
    id: awsAccountIdColId,
    header: colIdToNameMap[awsAccountIdColId],
    cell: (item) => item.awsAccountId,
    sortingField: awsAccountIdColId,
  },
  {
    id: automationStatusColId,
    header: colIdToNameMap[automationStatusColId],
    cell: (item) => {
      const automationStatusConfig = AutomationStatusConfig[item.automationStatus];
      return (
        <div>
          <Badge {...automationStatusConfig?.automationStatusBadgeProps}>
            {automationStatusConfig?.automationStatusDisplayText}
          </Badge>
        </div>
      );
    },
    sortingField: automationStatusColId,
  },
  {
    id: createdByColId,
    header: colIdToNameMap[createdByColId],
    cell: (item) => item.createdBy,
    sortingField: createdByColId,
  },
  {
    id: lastUpdatedByColId,
    header: colIdToNameMap[lastUpdatedByColId],
    cell: (item) => item.updatedBy,
    sortingField: lastUpdatedByColId,
  },
  {
    id: lastBuildStatusColId,
    header: colIdToNameMap[lastBuildStatusColId],
    cell: (item) =>
      item.lastBuildStatus ? (
        <StatusIndicator type={item.lastBuildStatus === PipelineBuildStatus.SUCCESS ? 'success' : 'error'}>
          {item.lastBuildStatus === PipelineBuildStatus.SUCCESS ? 'Pass' : 'Fail'}
        </StatusIndicator>
      ) : (
        '-'
      ),
    sortingField: lastBuildStatusColId,
  },
  {
    id: createdOnColId,
    header: colIdToNameMap[createdOnColId],
    cell: (item) => new Date(item.createdOn).toISOString().split('T')[0],
    sortingField: createdOnColId,
  },
];

export const DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences = {
  visibleContent: [
    automationIdColId,
    automationNameColId,
    codePipelineColId,
    codeRepositoryColId,
    awsAccountIdColId,
    automationStatusColId,
    lastUpdatedByColId,
    lastBuildStatusColId,
    createdOnColId,
  ],
  pageSize: 10,
};

export const VISIBLE_CONTENT_OPTIONS = [
  {
    label: 'Properties',
    options: [
      { id: automationIdColId, label: colIdToNameMap[automationIdColId] },
      { id: processClientIdColId, label: colIdToNameMap[processClientIdColId] },
      { id: codePipelineColId, label: colIdToNameMap[codePipelineColId] },
      { id: codeRepositoryColId, label: colIdToNameMap[codeRepositoryColId] },
      { id: createdByColId, label: colIdToNameMap[createdByColId] },
      { id: createdOnColId, label: colIdToNameMap[createdOnColId] },
      { id: lastUpdatedByColId, label: colIdToNameMap[lastUpdatedByColId] },
    ],
  },
];

export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: '10 automations' },
  { value: 30, label: '30 automations' },
  { value: 50, label: '50 automations' },
];

export const paginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page',
};

interface IPreferencesProps {
  preferences: typeof DEFAULT_PREFERENCES;
  setPreferences: (preferences: typeof DEFAULT_PREFERENCES) => void;
  visibleContentOptions?: typeof VISIBLE_CONTENT_OPTIONS;
  pageSizeOptions?: typeof PAGE_SIZE_OPTIONS;
}

export const Preferences = ({
  preferences,
  setPreferences,
  visibleContentOptions = VISIBLE_CONTENT_OPTIONS,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
}: IPreferencesProps) => (
  <CollectionPreferences
    title="Preferences"
    confirmLabel="Confirm"
    cancelLabel="Cancel"
    preferences={preferences}
    onConfirm={({ detail }) => setPreferences(detail)}
    visibleContentPreference={{
      title: 'Select visible columns',
      options: visibleContentOptions,
    }}
    pageSizePreference={{
      title: 'Page size',
      options: pageSizeOptions,
    }}
  />
);
