import { CodeEditor, CodeEditorProps, FormField, NonCancelableCustomEvent } from '@cloudscape-design/components';
import { FormFieldProps } from '@cloudscape-design/components/form-field';
import { NonCancelableEventHandler } from '@cloudscape-design/components/internal/events';
import React from 'react';
import { Controller, FieldValues, get, UseControllerProps, useFormContext } from 'react-hook-form';

type ControlledCodeEditorProps<T extends FieldValues> = UseControllerProps<T> &
  Omit<CodeEditorProps, 'name' | 'onChange' | 'value'>;

interface ICodeEditorFormFieldProps
  extends Partial<CodeEditorProps>,
    FormFieldProps,
    ControlledCodeEditorProps<FieldValues> {
  ace: any;
  name: string;
  language: CodeEditorProps.Language;
  onPreferencesChange: NonCancelableEventHandler<CodeEditorProps.Preferences>;
  i18nStrings?: CodeEditorProps.I18nStrings & FormFieldProps.I18nStrings;
}

export function CodeEditorFormField(props: ICodeEditorFormFieldProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <FormField {...props} errorText={get(errors, `${props.name}.message`)}>
      <ControlledCodeEditor {...props} name={props.name} control={control} />
    </FormField>
  );
}

function ControlledCodeEditor<T extends FieldValues>(props: ControlledCodeEditorProps<T>) {
  return (
    <Controller
      {...props}
      render={({ field: { onChange, value, ref } }) => (
        <CodeEditor
          {...props}
          onChange={(event: NonCancelableCustomEvent<CodeEditorProps.ChangeDetail>) => {
            const inputValue = event.detail.value;
            // for blank input (represented as an empty string),
            //  whitespace is trimmed from the string before null validation occurs.
            //  this behavior disallows the user from entering whitespace as the first character
            const formValue = !inputValue.trim() ? null : inputValue;
            onChange(formValue);
          }}
          value={value}
          ref={ref}
        />
      )}
    />
  );
}
