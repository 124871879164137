import { Button, Form, SpaceBetween, TextContent } from '@cloudscape-design/components';
import { FlashbarProps } from '@cloudscape-design/components/flashbar';
import React, { SetStateAction, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingStatus } from '../../../common/types/enums';
import { registerTeam, selectRegisterTeamResponse } from '../../../slices/TeamDetailSlice';
import { AppDispatch } from '../../../store/store';
import InputFormField from '../../polaris/InputFormField';
import { TeamDetailsFormProps, TeamDetailsSchemaType } from '../config/register-team-form.config';
import { LDAP_GROUP_NAME, TEAM_NAME } from '../constants/form-constants';

interface RegisterTeamFormProps {
  setNotifications: React.Dispatch<SetStateAction<FlashbarProps.MessageDefinition[]>>;
  removeNotification: (notificationId: string) => void;
}

export default function RegisterTeamForm(props: RegisterTeamFormProps) {
  const dispatch = useDispatch<AppDispatch>();
  const teamDetailsFormMethods = useForm<TeamDetailsSchemaType>(TeamDetailsFormProps);
  const registerTeamResult = useSelector(selectRegisterTeamResponse);
  const [verifyButtonEnabled, setVerifyButtonEnabled] = React.useState(false);
  const [verifyLdapButtonClicked, setVerifyLdapButtonClicked] = React.useState(false);
  const [requestAccessButtonLoading, setRequestAccessButtonLoading] = React.useState(false);

  useEffect(() => {
    if (registerTeamResult.status === LoadingStatus.SUCCESS) {
      setRequestAccessButtonLoading(false);
      props.setNotifications((prevState) => [
        ...prevState,
        {
          header: 'You’ve requested access successfully',
          type: 'success',
          content:
            "All the users in the team's LDAP group now have now have access to Developer console. If you're in the LDAP group, you can now sign in.",
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => props.removeNotification('api-success'),
          id: 'api-success',
        },
      ]);
    } else if (registerTeamResult.status === LoadingStatus.FAILED) {
      setRequestAccessButtonLoading(false);
      props.setNotifications((prevState) => [
        ...prevState,
        {
          header: 'Something went wrong while requesting access',
          type: 'error',
          content: 'Something went wrong while requesting access for your team. Please try again.',
          dismissible: true,
          dismissLabel: 'Dismiss error',
          onDismiss: () => props.removeNotification('api-error'),
          id: 'api-error',
        },
      ]);
    }
  }, [registerTeamResult.status]);

  function handleVerifyLdapButtonClick(e: CustomEvent) {
    void teamDetailsFormMethods.handleSubmit(async () => {
      window.open(
        `https://permissions.amazon.com/group.mhtml?group=${
          { ...teamDetailsFormMethods.getValues() }.authorizerId
        }&group_type=ldap`,
        '_blank',
      );
      setVerifyLdapButtonClicked(true);
    })();
  }

  function handleRequestAccessButtonClick() {
    return async () => {
      await teamDetailsFormMethods.handleSubmit(async () => {
        setRequestAccessButtonLoading(true);
        await dispatch(
          registerTeam({
            ...teamDetailsFormMethods.getValues(),
            authorizerType: 'LDAP',
            entityType: 'DEVELOPER',
          }),
        );
      })();
    };
  }

  return (
    <Form
      actions={
        <SpaceBetween direction="horizontal" size="s">
          <Button
            ariaLabel="Verify LDAP (opens new tab)"
            iconAlign="right"
            iconName="external"
            target="_blank"
            onClick={(event) => {
              handleVerifyLdapButtonClick(event);
            }}
            disabled={!verifyButtonEnabled}
          >
            Verify LDAP
          </Button>
          <Button
            onClick={handleRequestAccessButtonClick()}
            disabled={!verifyLdapButtonClicked}
            loading={requestAccessButtonLoading}
          >
            Request Access
          </Button>
        </SpaceBetween>
      }
    >
      <FormProvider {...teamDetailsFormMethods}>
        <SpaceBetween size="xl">
          Don&apos;t have access? Request team access
          <InputFormField
            name={TEAM_NAME}
            label="Team name"
            description="The name of team that you want to onboard"
            placeholder="e.g. Taskless_Workplace_Automation_Devs"
          />
          <InputFormField
            name={LDAP_GROUP_NAME}
            label="Team LDAP"
            description="Verify you're in the LDAP group for access. Note: submissions are case sensitive"
            placeholder="e.g. taskless-workplace-automation-devs"
            onKeyDown={() => setVerifyButtonEnabled(true)}
          />
        </SpaceBetween>
        <TextContent>
          <p>
            <small>
              Don&apos;t have an LDAP group? Create one{' '}
              <a href="https://permissions.amazon.com/a/team/new" target="_blank" rel="noreferrer">
                here
              </a>
            </small>
          </p>
        </TextContent>
      </FormProvider>
    </Form>
  );
}
