import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import {
  createCapabilityApi,
  getCapabilityApi,
  ICreateCapabilityRequest,
  IGetCapabilityRequest,
  IListLatestCapabilitiesForBlockRequest,
  IUpdateCapabilityRequest,
  listLatestCapabilitiesForBlockApi,
  updateCapabilityApi,
} from '../api/capability-registry-api';
import { CapabilityStatus, CapabilityType, LoadingStatus } from '../common/types/enums';
import { IGetCapabilityData, IListCapabilitiesForBlockData } from '../common/types/interfaces';
import { getValidUsername } from '../common/utils';
import { RootState } from '../store/store';

interface ICapabilityDetailState {
  listCapabilitiesForBlockResponse: {
    data: IListCapabilitiesForBlockData[];
    status: LoadingStatus;
    error?: string;
  };
  createCapabilityResponse: {
    status: LoadingStatus;
    error?: string;
  };
  updateCapabilityResponse: {
    status: LoadingStatus;
    error?: string;
    data?: {
      capabilityName: string;
    };
  };
  getCapabilityResponse: {
    data?: IGetCapabilityData;
    status: LoadingStatus;
    error?: string;
  };
}

const initialState: ICapabilityDetailState = {
  listCapabilitiesForBlockResponse: { data: [], status: LoadingStatus.IDLE },
  createCapabilityResponse: { status: LoadingStatus.IDLE },
  updateCapabilityResponse: { status: LoadingStatus.IDLE },
  getCapabilityResponse: { status: LoadingStatus.IDLE },
};

export const listCapabilitiesForBlock = createAsyncThunk(
  'capabilityDetail/listCapabilitiesForBlock',
  async (request: IListLatestCapabilitiesForBlockRequest): Promise<IListCapabilitiesForBlockData[]> => {
    const listLatestCapabilitiesForBlockResponse = await listLatestCapabilitiesForBlockApi(request);
    return listLatestCapabilitiesForBlockResponse.capabilityList.map((capabilityDetail) => ({
      blockId: request.blockId,
      capabilityId: capabilityDetail.capabilityId,
      capabilityName: capabilityDetail.capabilityName,
      capabilityType: capabilityDetail.capabilityType as CapabilityType,
      capabilityStatus: capabilityDetail.capabilityStatus as CapabilityStatus,
      capabilityVersion: capabilityDetail.capabilityVersion,
      createdAt: capabilityDetail.createdAt,
      createdBy: getValidUsername(capabilityDetail.createdBy),
      updatedBy: getValidUsername(capabilityDetail.updatedBy),
    }));
  },
);

export const createCapability = createAsyncThunk(
  'capabilityDetail/createCapability',
  async (request: ICreateCapabilityRequest): Promise<VoidFunction> => {
    return await createCapabilityApi(request);
  },
);

export const updateCapability = createAsyncThunk(
  'capabilityDetail/updateCapability',
  async (request: IUpdateCapabilityRequest): Promise<{ capabilityName: string }> => {
    return await updateCapabilityApi(request);
  },
);

export const getCapability = createAsyncThunk(
  'capabilityDetail/getCapability',
  async (request: IGetCapabilityRequest): Promise<IGetCapabilityData> => {
    const { capabilityDetail, blockDetail } = await getCapabilityApi(request);
    let capabilityConfigMap;
    if (capabilityDetail.capabilityConfigPresignedUrl) {
      const capabilityConfigMapResponse = await axios.get(capabilityDetail.capabilityConfigPresignedUrl);
      capabilityConfigMap = capabilityConfigMapResponse.data;
    }

    return {
      capabilityDetail: {
        capabilityId: capabilityDetail.capabilityId,
        capabilityName: capabilityDetail.capabilityName,
        capabilityType: capabilityDetail.capabilityType as CapabilityType,
        capabilityStatus: capabilityDetail.capabilityStatus as CapabilityStatus,
        capabilityVersion: capabilityDetail.capabilityVersion,
        summary: capabilityDetail.summary,
        tags: capabilityDetail.tags ?? [],
        minArtifactVersion: capabilityDetail.minArtifactVersion,
        maxArtifactVersion: capabilityDetail.maxArtifactVersion,
        capabilityConfigMap,
        createdAt: capabilityDetail.createdAt,
        updatedAt: capabilityDetail.updatedAt,
        createdBy: getValidUsername(capabilityDetail.createdBy),
        updatedBy: getValidUsername(capabilityDetail.updatedBy),
      },
      blockDetail: {
        blockName: blockDetail.blockName,
      },
    };
  },
);

const capabilityDetailSlice = createSlice({
  name: 'capabilityDetail',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(listCapabilitiesForBlock.fulfilled, (state, action) => {
      state.listCapabilitiesForBlockResponse.error = '';
      state.listCapabilitiesForBlockResponse.status = LoadingStatus.SUCCESS;
      state.listCapabilitiesForBlockResponse.data = action.payload;
    });
    builder.addCase(listCapabilitiesForBlock.rejected, (state) => {
      state.listCapabilitiesForBlockResponse.status = LoadingStatus.FAILED;
      state.listCapabilitiesForBlockResponse.error = 'Something went wrong. Please try again later';
      state.listCapabilitiesForBlockResponse.data = [];
    });
    builder.addCase(listCapabilitiesForBlock.pending, (state) => {
      state.listCapabilitiesForBlockResponse.status = LoadingStatus.PENDING;
      state.listCapabilitiesForBlockResponse.error = '';
      state.listCapabilitiesForBlockResponse.data = [];
    });
    builder.addCase(createCapability.pending, (state) => {
      state.createCapabilityResponse.status = LoadingStatus.PENDING;
      state.createCapabilityResponse.error = '';
    });
    builder.addCase(createCapability.fulfilled, (state) => {
      state.createCapabilityResponse.error = '';
      state.createCapabilityResponse.status = LoadingStatus.SUCCESS;
    });
    builder.addCase(createCapability.rejected, (state, action) => {
      state.createCapabilityResponse.status = LoadingStatus.FAILED;
      state.createCapabilityResponse.error = action?.error?.message || 'Something went wrong. Please try again later';
    });
    builder.addCase(updateCapability.pending, (state) => {
      state.updateCapabilityResponse.status = LoadingStatus.PENDING;
      state.updateCapabilityResponse.error = '';
      state.updateCapabilityResponse.data = null;
    });
    builder.addCase(updateCapability.fulfilled, (state, action) => {
      state.updateCapabilityResponse.error = '';
      state.updateCapabilityResponse.status = LoadingStatus.SUCCESS;
      state.updateCapabilityResponse.data = action.payload;
    });
    builder.addCase(updateCapability.rejected, (state, action) => {
      state.updateCapabilityResponse.status = LoadingStatus.FAILED;
      state.updateCapabilityResponse.error = action?.error?.message || 'Something went wrong. Please try again later';
      state.updateCapabilityResponse.data = null;
    });
    builder.addCase(getCapability.fulfilled, (state, action) => {
      state.getCapabilityResponse.error = '';
      state.getCapabilityResponse.status = LoadingStatus.SUCCESS;
      state.getCapabilityResponse.data = action.payload;
    });
    builder.addCase(getCapability.rejected, (state) => {
      state.getCapabilityResponse.status = LoadingStatus.FAILED;
      state.getCapabilityResponse.error = 'Something went wrong. Please try again later';
      state.getCapabilityResponse.data = null;
    });
    builder.addCase(getCapability.pending, (state) => {
      state.getCapabilityResponse.status = LoadingStatus.PENDING;
      state.getCapabilityResponse.error = '';
      state.getCapabilityResponse.data = null;
    });
  },
});

export const selectListCapabilitiesForBlockResponse = (state: RootState) =>
  state.capabilityDetail.listCapabilitiesForBlockResponse;

export const selectCreateCapabilityResponse = (state: RootState) => state.capabilityDetail.createCapabilityResponse;
export const selectUpdateCapabilityResponse = (state: RootState) => state.capabilityDetail.updateCapabilityResponse;
export const selectGetCapabilityResponse = (state: RootState) => state.capabilityDetail.getCapabilityResponse;

export default capabilityDetailSlice.reducer;
