import { Button, Form, SpaceBetween } from '@cloudscape-design/components';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { HOME } from '../../../../common/constants/route-paths';
import { AutomationRequestType } from '../../../../common/enums/automation-request-enums';
import IAutomationRequestDetails from '../../../../common/interfaces/AutomationRequestDetails';
import { LoadingStatus } from '../../../../common/types/enums';
import { AuthProfileSelector } from '../../../../slices/AuthStateSlice';
import {
  createAutomation,
  generateAutomationId,
  selectCreateAutomationResponse,
  selectGenerateAutomationIdResponse,
} from '../../../../slices/AutomationDetailSlice';
import {
  listAutomationRequests,
  selectListAutomationRequestsResponse,
} from '../../../../slices/AutomationRequestSlice';
import { AppDispatch } from '../../../../store/store';
import { AutomationDetailSchemaType, AutomationDetailsFormProps } from '../config/automation-form.config';
import { AUTOMATION_REQUEST, IS_WINDOWS_APPLICATION_AUTOMATION } from '../constants/form-constants';
import CreateAutomationContainer from './CreateAutomationContainer';

function getRequiredRequestDetail(
  listAutomationRequestsData: {
    data: IAutomationRequestDetails[];
    status: LoadingStatus;
  },
  requestId: string,
): IAutomationRequestDetails {
  if (!requestId) {
    return null;
  }

  const [requestDetail] = listAutomationRequestsData.data.filter(
    (requestDetail) =>
      requestDetail.type === AutomationRequestType.NEW_AUTOMATION && requestDetail.requestId === requestId,
  );
  return requestDetail;
}

export default function CreateAutomationForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const selectedAuthProfile = useSelector(AuthProfileSelector);
  const automationDetailFormMethods = useForm<AutomationDetailSchemaType>(AutomationDetailsFormProps);
  const listAutomationRequestsData = useSelector(selectListAutomationRequestsResponse);
  const generateAutomationIdResponse = useSelector(selectGenerateAutomationIdResponse);
  const createAutomationResponse = useSelector(selectCreateAutomationResponse);
  const [formErrorText, setFormErrorText] = useState('');

  useEffect(() => {
    void dispatch(listAutomationRequests());
  }, [selectedAuthProfile]);

  useEffect(() => {
    if (generateAutomationIdResponse.status === LoadingStatus.FAILED) {
      setFormErrorText('Something went wrong while generating automation id for given inputs. Please try again later');
    }
    if (generateAutomationIdResponse.status === LoadingStatus.SUCCESS) {
      const formFieldValues = automationDetailFormMethods.getValues();
      const requestDetail = getRequiredRequestDetail(listAutomationRequestsData, formFieldValues[AUTOMATION_REQUEST]);
      void dispatch(
        createAutomation({
          automationId: generateAutomationIdResponse.automationId,
          ...formFieldValues,
          processId: requestDetail.processId,
          processClientId: requestDetail.clientId,
          requestId: requestDetail.requestId,
          isWindowsApplicationAutomation: formFieldValues[IS_WINDOWS_APPLICATION_AUTOMATION] ?? false,
        }),
      );
    }
  }, [generateAutomationIdResponse.status]);

  useEffect(() => {
    if (createAutomationResponse.status === LoadingStatus.FAILED) {
      setFormErrorText('Something went wrong while creating the automation. Please try again later');
    }
  }, [createAutomationResponse.status]);

  useEffect(() => {
    automationDetailFormMethods.setValue(
      AUTOMATION_REQUEST,
      getRequiredRequestDetail(listAutomationRequestsData, location.state?.requestId)?.requestId,
    );
  }, [location.state?.requestId]);

  function handleCreateButtonClick() {
    return async () => {
      setFormErrorText('');
      await automationDetailFormMethods.handleSubmit(() => {
        const requestDetail = getRequiredRequestDetail(
          listAutomationRequestsData,
          automationDetailFormMethods.getValues()[AUTOMATION_REQUEST],
        );
        void dispatch(
          generateAutomationId({
            processId: requestDetail.processId,
            processClientId: requestDetail.clientId,
            requestId: requestDetail.requestId,
          }),
        );
      })();
    };
  }

  return (
    <Form
      actions={
        <SpaceBetween direction="horizontal" size="xl">
          <Button formAction="none" variant="link" onClick={() => navigate(HOME)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleCreateButtonClick()}
            loading={
              generateAutomationIdResponse.status === LoadingStatus.PENDING ||
              createAutomationResponse.status === LoadingStatus.PENDING
            }
            disabled={
              generateAutomationIdResponse.status === LoadingStatus.PENDING ||
              createAutomationResponse.status === LoadingStatus.PENDING
            }
          >
            Create
          </Button>
        </SpaceBetween>
      }
      errorText={formErrorText}
      variant="embedded"
    >
      <FormProvider {...automationDetailFormMethods}>
        <CreateAutomationContainer />
      </FormProvider>
    </Form>
  );
}
