import { Box } from '@cloudscape-design/components';
import React from 'react';

function SignInTopNavBar() {
  return (
    <div id="tw-header">
      <img alt="Taskless Workplace Logo" src="https://m.media-amazon.com/images/G/01/taskless/workplace/logo-w.png" />
      <Box fontSize="heading-s" className="tw-white tw-ml1 tw-fw-500">
        Taskless Workplace Developer Console
        {process.env.REACT_APP_ENV && process.env.REACT_APP_ENV.substring(0, 4).toUpperCase() !== 'PROD' ? (
          <Box fontSize="body-m" className="tw-white tw-ml1" variant="span" fontWeight="normal">
            {process.env.REACT_APP_ENV[0].toUpperCase() + process.env.REACT_APP_ENV.substring(1).toLowerCase()}
          </Box>
        ) : null}
      </Box>
    </div>
  );
}

export default SignInTopNavBar;
