import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ISynchronizeResourceAccountRequest, synchronizeResourceAccountApi } from '../api/infra-manager-api';
import { LoadingStatus } from '../common/types/enums';
import { ISynchronizeResourceAccountResponse } from '../common/types/interfaces';
import { RootState } from '../store/store';

interface IInfraManagerState {
  synchronizeResourceAccountResponse: {
    data?: ISynchronizeResourceAccountResponse;
    status: LoadingStatus;
    error?: string;
  };
}

const initialState: IInfraManagerState = {
  synchronizeResourceAccountResponse: { status: LoadingStatus.IDLE },
};

export const synchronizeResourceAccount = createAsyncThunk(
  'infraManager/synchronizeResourceAccount',
  async (request: ISynchronizeResourceAccountRequest): Promise<ISynchronizeResourceAccountResponse> => {
    const response = await synchronizeResourceAccountApi(request);
    return {
      onboardingTemplateUrl: response.stackUrl,
      isSynchronized: response.isSynchronized,
      isSynchronizationTriggered: response.isSynchronizationTriggered,
    };
  },
);

const infraManagerSlice = createSlice({
  name: 'infraManager',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(synchronizeResourceAccount.pending, (state) => {
      state.synchronizeResourceAccountResponse.status = LoadingStatus.PENDING;
      state.synchronizeResourceAccountResponse.error = '';
      state.synchronizeResourceAccountResponse.data = null;
    });
    builder.addCase(synchronizeResourceAccount.fulfilled, (state, action) => {
      state.synchronizeResourceAccountResponse.data = action.payload;
      state.synchronizeResourceAccountResponse.status = LoadingStatus.SUCCESS;
      state.synchronizeResourceAccountResponse.error = '';
    });
    builder.addCase(synchronizeResourceAccount.rejected, (state, action) => {
      state.synchronizeResourceAccountResponse.status = LoadingStatus.FAILED;
      state.synchronizeResourceAccountResponse.data = null;
      state.synchronizeResourceAccountResponse.error =
        action?.error?.message || 'Something went wrong. Please try again later';
    });
  },
});

export const selectSynchronizeResourceAccountResponse = (state: RootState) =>
  state.infraManager.synchronizeResourceAccountResponse;

export default infraManagerSlice.reducer;
