import { Box, Button, SpaceBetween } from '@cloudscape-design/components';
import React from 'react';

export function NoMatchState(props: { clearFilterAction: () => void }) {
  return (
    <Box textAlign="center" color="inherit">
      <SpaceBetween size="s">
        <b>No match found</b>
        <Button onClick={props.clearFilterAction}>Clear filters</Button>
      </SpaceBetween>
    </Box>
  );
}

interface EmptyStateProps {
  children: React.ReactNode;
}

export function EmptyState(props: EmptyStateProps) {
  return (
    <Box textAlign="center" color="inherit">
      <SpaceBetween size="s">{props.children}</SpaceBetween>
    </Box>
  );
}
