import { Button, Form, Header, SpaceBetween } from '@cloudscape-design/components';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { getAutomationDetailPagePath } from '../../../../common/page-route-path';
import { LoadingStatus } from '../../../../common/types/enums';
import {
  createAutomationPipeline,
  selectCreateAutomationPipelineResponse,
  selectGetAutomationDetailResponse,
} from '../../../../slices/AutomationDetailSlice';
import { selectSynchronizeResourceAccountResponse } from '../../../../slices/InfraManagerSlice';
import { AppDispatch } from '../../../../store/store';
import { AutomationPipelineFormProps, AutomationPipelineSchemaType } from '../config/automation-pipeline-form.config';
import CreateAutomationPipelineContainer from './CreateAutomationPipelineContainer';

export default function CreateAutomationPipelineForm() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const automationPipelineFormMethods = useForm<AutomationPipelineSchemaType>(AutomationPipelineFormProps);
  const currentAutomation = useSelector(selectGetAutomationDetailResponse);
  const createAutomationPipelineResponse = useSelector(selectCreateAutomationPipelineResponse);
  const synchronizeResourceAccountResponse = useSelector(selectSynchronizeResourceAccountResponse);
  const [formErrorText, setFormErrorText] = useState('');

  useEffect(() => {
    setFormErrorText(createAutomationPipelineResponse.error);
  }, [createAutomationPipelineResponse.error]);

  function handleButtonClick() {
    return async () => {
      if (!synchronizeResourceAccountResponse.data?.isSynchronized) {
        setFormErrorText('Please synchronize your account before creating pipeline.');
        return;
      }

      const formFieldValues = {
        ...automationPipelineFormMethods.getValues(),
        processId: currentAutomation.data.automationDetail.processId,
        processClientId: currentAutomation.data.automationDetail.processClientId,
        automationId: params.automationId,
      };

      await automationPipelineFormMethods.handleSubmit(() => dispatch(createAutomationPipeline(formFieldValues)))();
    };
  }

  return (
    <Form
      header={<Header variant="h1">Create pipeline</Header>}
      actions={
        <SpaceBetween direction="horizontal" size="xl">
          <Button
            formAction="none"
            variant="link"
            onClick={() => navigate(getAutomationDetailPagePath(params.automationId))}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleButtonClick()}
            loading={createAutomationPipelineResponse.status === LoadingStatus.PENDING}
            disabled={createAutomationPipelineResponse.status === LoadingStatus.PENDING}
          >
            Create
          </Button>
        </SpaceBetween>
      }
      errorText={formErrorText}
      variant="embedded"
    >
      <FormProvider {...automationPipelineFormMethods}>
        <CreateAutomationPipelineContainer setFormErrorText={setFormErrorText} />
      </FormProvider>
    </Form>
  );
}
