import { AppLayout, Flashbar } from '@cloudscape-design/components';
import { FlashbarProps } from '@cloudscape-design/components/flashbar';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { HOME } from '../../../common/constants/route-paths';
import { getAutomationDetailPagePath } from '../../../common/page-route-path';
import GlobalHelpPanel from '../../../components/GlobalHelpPanel';
import GlobalNavigationPanel from '../../../components/GlobalNavigationPanel';
import BreadcrumbLinkGroup from '../../../components/polaris/BreadcrumbLinkGroup';
import { AuthProfileSelector } from '../../../slices/AuthStateSlice';
import { getCompleteAutomationDetail, selectGetAutomationDetailResponse } from '../../../slices/AutomationDetailSlice';
import { selectIsNavigationPanelOpen, setNavigationPanelOpen } from '../../../slices/SitePreferencesSlice';
import { AppDispatch } from '../../../store/store';
import InstantUIPreviewPageContent from './components/InstantUIPreviewPageContent';
import { UI_PREVIEW } from './constants/page-constants';

export default function InstantUIPreviewPage() {
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const isNavigationPanelOpen = useSelector(selectIsNavigationPanelOpen);
  const [notifications, setNotifications] = useState<FlashbarProps.MessageDefinition[]>([]);
  const selectedAuthProfile = useSelector(AuthProfileSelector);
  const currentAutomation = useSelector(selectGetAutomationDetailResponse);

  const removeNotification = (notificationId: string) => {
    const filteredNotification = notifications.filter((notification) => notification.id !== notificationId);
    setNotifications(filteredNotification);
  };

  const navigationChangeHandler = () => {
    dispatch(setNavigationPanelOpen(!isNavigationPanelOpen));
  };

  useEffect(() => {
    void dispatch(getCompleteAutomationDetail(params.automationId));
  }, [selectedAuthProfile]);

  useEffect(() => {
    removeNotification('api-error');
    if (currentAutomation.error) {
      setNotifications((prevState) => [
        ...prevState,
        {
          header: 'Failed to load automation detail',
          type: 'error',
          content: currentAutomation.error,
          dismissible: true,
          dismissLabel: 'Dismiss error',
          onDismiss: () => removeNotification('api-error'),
          id: 'api-error',
        },
      ]);
    }
  }, [currentAutomation.error]);

  return (
    <AppLayout
      headerSelector="#tw-header"
      notifications={<Flashbar items={notifications} />}
      stickyNotifications={false}
      contentType="form"
      navigation={<GlobalNavigationPanel />}
      content={
        <InstantUIPreviewPageContent setNotifications={setNotifications} removeNotification={removeNotification} />
      }
      navigationOpen={isNavigationPanelOpen}
      onNavigationChange={navigationChangeHandler}
      tools={<GlobalHelpPanel />}
      breadcrumbs={
        <BreadcrumbLinkGroup
          links={
            currentAutomation?.data
              ? [
                  {
                    text: 'Home',
                    href: HOME,
                  },
                  {
                    text: `${currentAutomation.data.automationDetail.automationName || ''}`,
                    href: getAutomationDetailPagePath(params.automationId),
                  },
                  {
                    text: UI_PREVIEW,
                    href: 'ui-preview',
                  },
                ]
              : [
                  {
                    text: 'Home',
                    href: HOME,
                  },
                  {
                    text: '',
                    href: '',
                  },
                ]
          }
        />
      }
    />
  );
}
