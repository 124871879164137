export const CAPABILITY_NAME_COL_ID = 'capabilityName';
export const CAPABILITY_VERSION_COL_ID = 'capabilityVersion';
export const CAPABILITY_STATUS_COL_ID = 'capabilityStatus';
export const CREATED_ON_COL_ID = 'createdAt';

export const COL_ID_TO_NAME_MAP = {
  [CAPABILITY_NAME_COL_ID]: 'Name',
  [CAPABILITY_VERSION_COL_ID]: 'Current version',
  [CAPABILITY_STATUS_COL_ID]: 'Status',
  [CREATED_ON_COL_ID]: 'Created on',
};
