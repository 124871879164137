import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store/store';

interface ISitePreferencesState {
  isGetStartedContainerDismissed: boolean;
  isNavigationPanelOpen: boolean;
}

const initialState: ISitePreferencesState = {
  isGetStartedContainerDismissed: false,
  isNavigationPanelOpen: true,
};

const sitePreferencesStateSlice = createSlice({
  name: 'sitePreferences',
  initialState,
  reducers: {
    setGetStartedContainerDismissed: (state, action: PayloadAction<boolean>) => {
      state.isGetStartedContainerDismissed = action.payload;
    },
    setNavigationPanelOpen: (state, action: PayloadAction<boolean>) => {
      state.isNavigationPanelOpen = action.payload;
    },
  },
});

export const { setGetStartedContainerDismissed, setNavigationPanelOpen } = sitePreferencesStateSlice.actions;
export const selectIsGetStartedContainerDismissed = (state: RootState) =>
  state.sitePreferencesState.isGetStartedContainerDismissed;
export const selectIsNavigationPanelOpen = (state: RootState) => state.sitePreferencesState.isNavigationPanelOpen;
export default sitePreferencesStateSlice.reducer;
