import { Button, Form, SpaceBetween } from '@cloudscape-design/components';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { getAutomationDetailPagePath } from '../../../../common/page-route-path';
import { LoadingStatus } from '../../../../common/types/enums';
import { removeUndefinedProperties } from '../../../../common/utils';
import {
  selectGetAutomationDetailResponse,
  selectUpdateAutomationDetailResponse,
  updateAutomationDetail,
} from '../../../../slices/AutomationDetailSlice';
import { AppDispatch } from '../../../../store/store';
import { AutomationDetailsFormProps, AutomationDetailsSchemaType } from '../config/automation-form.config';
import {
  AUTOMATION_NAME,
  DASHBOARD_LINK,
  EMAIL_ADDRESS,
  EXECUTION_AWS_REGION,
  EXECUTION_ENVIRONMENT_MEMORY,
  EXECUTION_ENVIRONMENT_RESOURCE_ID,
  EXECUTION_ENVIRONMENT_RESOURCE_TYPE,
  EXECUTION_ENVIRONMENT_STORAGE,
  IS_WINDOWS_APPLICATION_AUTOMATION,
  SUPPORTED_CONCURRENT_EXECUTIONS,
} from '../constants/form-constants';
import EditAutomationDetailContainer from './EditAutomationDetailContainer';

export default function EditAutomationDetailForm() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const automationDetailsFormMethods = useForm<AutomationDetailsSchemaType>(AutomationDetailsFormProps);
  const currentAutomation = useSelector(selectGetAutomationDetailResponse);
  const updateAutomationDetailResponse = useSelector(selectUpdateAutomationDetailResponse);

  function handleSaveChangeButton() {
    return async () => {
      const formValues = automationDetailsFormMethods.getValues();
      const request = {
        automationId: params.automationId,
        automationName: formValues[AUTOMATION_NAME],
        emailAddress: formValues[EMAIL_ADDRESS],
        dashboardLink: formValues[DASHBOARD_LINK],
        isWindowsApplicationAutomation: formValues[IS_WINDOWS_APPLICATION_AUTOMATION],
        executionEnvironmentDetails: {
          resourceType: formValues[EXECUTION_ENVIRONMENT_RESOURCE_TYPE],
          memory: parseFloat(formValues[EXECUTION_ENVIRONMENT_MEMORY]?.toString()),
          storage: parseFloat(formValues[EXECUTION_ENVIRONMENT_STORAGE]?.toString()),
          resourceId: formValues[EXECUTION_ENVIRONMENT_RESOURCE_ID],
          region: formValues[EXECUTION_AWS_REGION],
          supportedConcurrentExecutions: parseInt(formValues[SUPPORTED_CONCURRENT_EXECUTIONS]?.toString()),
        },
      };

      const sanitizedRequest = removeUndefinedProperties(request);
      await automationDetailsFormMethods.handleSubmit(() => dispatch(updateAutomationDetail(sanitizedRequest)))();
    };
  }

  return (
    currentAutomation.data && (
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xl">
            <Button
              formAction="none"
              variant="link"
              onClick={() => navigate(getAutomationDetailPagePath(params.automationId))}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleSaveChangeButton()}
              loading={updateAutomationDetailResponse.status === LoadingStatus.PENDING}
              disabled={updateAutomationDetailResponse.status === LoadingStatus.PENDING}
            >
              Save Changes
            </Button>
          </SpaceBetween>
        }
        errorText={updateAutomationDetailResponse.error}
        variant="embedded"
      >
        <FormProvider {...automationDetailsFormMethods}>
          <EditAutomationDetailContainer />
        </FormProvider>
      </Form>
    )
  );
}
