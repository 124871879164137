import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { listAutomationRequestsForDevTeam } from '../api/request-manager-api';
import {
  AutomationFrequencyPeriod,
  AutomationRequestStatus,
  AutomationRequestType,
  BusinessPriority,
} from '../common/enums/automation-request-enums';
import AutomationRequestDetails from '../common/interfaces/AutomationRequestDetails';
import { LoadingStatus } from '../common/types/enums';
import { RootState } from '../store/store';

interface IAutomationRequestState {
  listAutomationRequests: {
    data: AutomationRequestDetails[];
    status: LoadingStatus;
    error?: string;
  };
}

const initialState: IAutomationRequestState = {
  listAutomationRequests: { data: [], status: LoadingStatus.IDLE },
};

export const listAutomationRequests = createAsyncThunk('automationRequest/listAutomationRequests', async () => {
  const requestDetails = await listAutomationRequestsForDevTeam({});
  const automationRequestList = requestDetails.requestList.map((request) => {
    const requestDetail: AutomationRequestDetails = {
      requestId: request.requestId,
      clientId: request.clientId,
      processId: request.processId,
      title: request.title,
      type: request.type as AutomationRequestType,
      status: request.status as AutomationRequestStatus,
      automationId: request.automationId,
      automationVersion: request.automationVersion,
      createdBy: request.createdBy,
      createdOn: request.createdOn,
      lastUpdatedBy: request.lastUpdatedBy,
      lastUpdatedOn: request.lastUpdatedOn,
    };

    if (request.metricsMetadata) {
      requestDetail.metricsMetadata = {
        expectedUsersCount: request.metricsMetadata.expectedUsersCount,
        expectedAnnualSavings: request.metricsMetadata.expectedAnnualSavings,
        expectedComplianceSavings: request.metricsMetadata.expectedComplianceSavings,
        desiredGoLiveDate: request.metricsMetadata.desiredGoLiveDate,
        businessPriority: request.metricsMetadata.businessPriority as BusinessPriority,
        automationFrequency: {
          times: request.metricsMetadata.automationFrequency?.times,
          period: request.metricsMetadata.automationFrequency?.period as AutomationFrequencyPeriod,
        },
      };
    }

    return requestDetail;
  });

  return { requestList: automationRequestList };
});

const automationRequestSlice = createSlice({
  name: 'automationRequest',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get automation request reducers
    builder.addCase(listAutomationRequests.pending, (state) => {
      state.listAutomationRequests.status = LoadingStatus.PENDING;
    });
    builder.addCase(listAutomationRequests.fulfilled, (state, action) => {
      state.listAutomationRequests.error = '';
      state.listAutomationRequests.data = action.payload.requestList;
      state.listAutomationRequests.status = LoadingStatus.SUCCESS;
    });
    builder.addCase(listAutomationRequests.rejected, (state, action) => {
      state.listAutomationRequests.status = LoadingStatus.FAILED;
      state.listAutomationRequests.error = action?.error?.message || 'Something went wrong. Please try again later.';
    });
  },
});

export const selectListAutomationRequestsResponse = (state: RootState) =>
  state.automationRequest.listAutomationRequests;

export default automationRequestSlice.reducer;
