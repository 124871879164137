import { ContentLayout, Header } from '@cloudscape-design/components';
import React from 'react';
import { useSelector } from 'react-redux';

import { BlockType } from '../../../../common/types/enums';
import { IListBlocksForClientData } from '../../../../common/types/interfaces';
import { selectListBlocksForClientResponse } from '../../../../slices/BlockDetailSlice';
import MyBlocksPageTable from './MyBlocksPageTable';

function getBlocksListForBlockType(blockList: IListBlocksForClientData[], blockType: BlockType) {
  return blockList.filter((block) => block.blockType === blockType);
}

export default function MyBlocksPageContent() {
  // const [searchParams, setSearchParams] = useSearchParams();
  // const [activeTabId, setActiveTabId] = useState(BLOCK_TYPE_MAP[BlockType.LIBRARY].name);
  const listBlocksForClientResult = useSelector(selectListBlocksForClientResponse);

  // useEffect(() => {
  //   searchParams.set(QUERY_PARAMS.tabId, searchParams.get(QUERY_PARAMS.tabId) || activeTabId);
  //   setSearchParams(searchParams);
  //   setActiveTabId(searchParams.get(QUERY_PARAMS.tabId) || activeTabId);
  // }, []);

  return (
    <ContentLayout header={<Header variant="h1">My blocks</Header>}>
      <MyBlocksPageTable
        blockType={BlockType.LIBRARY}
        blockList={getBlocksListForBlockType(listBlocksForClientResult.blockList, BlockType.LIBRARY)}
      />
      {/* <Tabs */}
      {/*  ariaLabel="block" */}
      {/*  activeTabId={activeTabId} */}
      {/*  onChange={(tabDetails) => { */}
      {/*    searchParams.set(QUERY_PARAMS.tabId, tabDetails.detail.activeTabId); */}
      {/*    setActiveTabId(tabDetails.detail.activeTabId); */}
      {/*    setSearchParams(searchParams); */}
      {/*  }} */}
      {/*  tabs={[ */}
      {/*    { */}
      {/*      label: BLOCK_TYPE_MAP[BlockType.LIBRARY].pascalPluralName, */}
      {/*      id: BLOCK_TYPE_MAP[BlockType.LIBRARY].name, */}
      {/*      content: ( */}
      {/*        <MyBlocksPageTable */}
      {/*          blockType={BlockType.LIBRARY} */}
      {/*          blockList={getBlocksListForBlockType(listBlocksForClientResult.blockList, BlockType.LIBRARY)} */}
      {/*        /> */}
      {/*      ), */}
      {/*    }, */}
      {/*    { */}
      {/*      label: BLOCK_TYPE_MAP[BlockType.SERVICE].pascalPluralName, */}
      {/*      id: BLOCK_TYPE_MAP[BlockType.SERVICE].name, */}
      {/*      content: ( */}
      {/*        <MyBlocksPageTable */}
      {/*          blockType={BlockType.SERVICE} */}
      {/*          blockList={getBlocksListForBlockType(listBlocksForClientResult.blockList, BlockType.SERVICE)} */}
      {/*        /> */}
      {/*      ), */}
      {/*    }, */}
      {/*  ]} */}
      {/* /> */}
    </ContentLayout>
  );
}
