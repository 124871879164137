import { Badge, Button, Header, SpaceBetween } from '@cloudscape-design/components';
import { FlashbarProps } from '@cloudscape-design/components/flashbar';
import React, { SetStateAction, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { AutomationStatusConfig } from '../../../../common/constants/automation-constants';
import {
  getCreateAutomationPipelinePagePath,
  getEditAutomationDetailPagePath,
} from '../../../../common/page-route-path';
import { AutomationType, PipelineStatus } from '../../../../common/types/enums';
import { selectGetAutomationDetailResponse } from '../../../../slices/AutomationDetailSlice';
import UpdateAutomationStatusModal from './UpdateAutomationStatusModal';

interface AutomationDetailContentHeaderProps {
  setNotifications: React.Dispatch<SetStateAction<FlashbarProps.MessageDefinition[]>>;
  removeNotification: (notificationId: string) => void;
}

export default function AutomationDetailContentHeader(props: AutomationDetailContentHeaderProps) {
  const params = useParams();
  const currentAutomation = useSelector(selectGetAutomationDetailResponse);
  const [automationStatusUpdateModalVisible, setAutomationStatusUpdateModalVisible] = useState(false);
  if (!currentAutomation.data) {
    return;
  }

  const { automationDetail, pipelineDetail } = currentAutomation.data;
  const automationStatusConfig = AutomationStatusConfig[automationDetail.automationStatus];

  return (
    <Header
      variant="h1"
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Button
            ariaLabel="Dashboard Link (opens new tab)"
            href={pipelineDetail.dashboardLink}
            iconAlign="right"
            iconName="external"
            target="_blank"
            disabled={!pipelineDetail.dashboardLink}
          >
            View Analytics
          </Button>
          <Button ariaLabel="Edit Details" href={getEditAutomationDetailPagePath(params.automationId)}>
            Edit
          </Button>
          {([PipelineStatus.REQUESTED, PipelineStatus.INITIATED, PipelineStatus.FAILED].find(
            (status) => status === pipelineDetail.pipelineStatus,
          ) ||
            !pipelineDetail.pipelineStatus) &&
          AutomationType.WINDOWS_ASSISTANT === automationDetail.automationType ? (
            <Button
              ariaLabel="Create pipeline"
              href={getCreateAutomationPipelinePagePath(params.automationId)}
              loading={[PipelineStatus.REQUESTED.toString(), PipelineStatus.INITIATED.toString()].includes(
                pipelineDetail.pipelineStatus,
              )}
              disabled={[PipelineStatus.REQUESTED.toString(), PipelineStatus.INITIATED.toString()].includes(
                pipelineDetail.pipelineStatus,
              )}
            >
              {[PipelineStatus.REQUESTED.toString(), PipelineStatus.INITIATED.toString()].includes(
                pipelineDetail.pipelineStatus,
              )
                ? 'Pipeline creation in progress'
                : pipelineDetail.pipelineStatus === PipelineStatus.FAILED
                ? 'Retry pipeline creation'
                : 'Create pipeline'}
            </Button>
          ) : (
            <>
              {automationStatusConfig?.nextAutomationStatus && currentAutomation.data.executableList.length !== 0 && (
                <>
                  <Button
                    ariaLabel="Automation status button"
                    onClick={() => setAutomationStatusUpdateModalVisible(true)}
                  >
                    {automationStatusConfig?.updateAutomationStatusUpdateButtonText}
                  </Button>
                  <UpdateAutomationStatusModal
                    visible={automationStatusUpdateModalVisible}
                    onDismiss={() => setAutomationStatusUpdateModalVisible(false)}
                    automationStatusConfig={automationStatusConfig}
                    {...props}
                  />
                </>
              )}
            </>
          )}
        </SpaceBetween>
      }
    >
      <SpaceBetween direction="horizontal" size="s">
        {automationDetail.automationName}
        <Badge {...automationStatusConfig?.automationStatusBadgeProps}>
          {automationStatusConfig?.automationStatusDisplayText}
        </Badge>
      </SpaceBetween>
    </Header>
  );
}
