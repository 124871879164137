import { AppLayout } from '@cloudscape-design/components';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { checkIfClientIdAndRoleMissing } from '../../../common/utils';
import GlobalHelpPanel from '../../../components/GlobalHelpPanel';
import GlobalNavigationPanel from '../../../components/GlobalNavigationPanel';
import { AuthProfileSelector } from '../../../slices/AuthStateSlice';
import { listAutomationRequests } from '../../../slices/AutomationRequestSlice';
import { selectIsNavigationPanelOpen, setNavigationPanelOpen } from '../../../slices/SitePreferencesSlice';
import { AppDispatch } from '../../../store/store';
import AutomationRequestsPageContent from './components/AutomationRequestsPageContent';

export default function AutomationRequestsPage() {
  const dispatch = useDispatch<AppDispatch>();
  const isNavigationPanelOpen = useSelector(selectIsNavigationPanelOpen);
  const selectedAuthProfile = useSelector(AuthProfileSelector);

  const navigationChangeHandler = () => {
    dispatch(setNavigationPanelOpen(!isNavigationPanelOpen));
  };

  useEffect(() => {
    if (checkIfClientIdAndRoleMissing(selectedAuthProfile)) {
      return;
    }

    void dispatch(listAutomationRequests());
  }, [selectedAuthProfile]);

  return (
    <AppLayout
      headerSelector="#tw-header"
      content={<AutomationRequestsPageContent />}
      navigation={<GlobalNavigationPanel />}
      navigationOpen={isNavigationPanelOpen}
      onNavigationChange={navigationChangeHandler}
      tools={<GlobalHelpPanel />}
    />
  );
}
