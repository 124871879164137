import axios, { AxiosError } from 'axios';

import { ArtifactEnvironment, ArtifactPlatform } from '../common/types/enums';
import { automationRegistryApiEndpoint } from '../config/endpoints';
import { store } from '../store/store';

interface IListAutomationPipelineRequest {
  pageSize?: number;
  lastEvaluatedKey?: string;
}

interface IListAutomationPipelineResponse {
  pipelineList: Array<{
    automationId: string;
    automationName: string;
    processClientId: string;
    codePipelineUrl: string;
    codeRepositoryUrl: string;
    awsAccountId: string;
    automationStatus: string;
    createdBy: string;
    updatedBy: string;
    buildStatus: string;
    createdTimestamp: number;
  }>;
  lastEvaluatedKey: string;
}

export async function listAutomationPipelineApi(requestBody: IListAutomationPipelineRequest) {
  const response = await axios.post<IListAutomationPipelineResponse>(
    `${automationRegistryApiEndpoint}/automn-reg/list-automation-pipeline`,
    { ...requestBody, devTeamId: store.getState().authState.selectedAuthProfile.ClientId },
  );
  return response.data;
}

export interface IGenerateAutomationIdRequest {
  processClientId: string;
  processId: string;
  requestId: string;
}

export interface IGenerateAutomationIdResponse {
  automationId: string;
}

export async function generateAutomationIdApi(requestBody: IGenerateAutomationIdRequest) {
  const response = await axios.post<IGenerateAutomationIdResponse>(
    `${automationRegistryApiEndpoint}/automn-reg/generate-automation-id`,
    { ...requestBody, devTeamId: store.getState().authState.selectedAuthProfile.ClientId },
  );
  return response.data;
}

export interface ICreateAutomationRequest {
  processClientId: string;
  processId: string;
  requestId: string;
  automationId: string;
  automationName: string;
  automationType: string;
  executionEnvironment: string;
  emailAddress: string;
  isWindowsApplicationAutomation?: boolean;
}

export interface ICreateAutomationResponse {
  automationId: string;
}

export async function createAutomationApi(requestBody: ICreateAutomationRequest) {
  try {
    const response = await axios.post<ICreateAutomationResponse>(
      `${automationRegistryApiEndpoint}/automn-reg/create-automation`,
      { ...requestBody, devTeamId: store.getState().authState.selectedAuthProfile.ClientId },
    );
    return response.data;
  } catch (error) {
    if ((error as AxiosError)?.response?.data) {
      throw (error as AxiosError).response.data;
    }
    throw error;
  }
}

interface IGetAutomationDetailRequest {
  automationId: string;
}

export interface IGetAutomationDetailResponse {
  automationDetail: {
    automationId: string;
    automationName: string;
    automationType: string;
    processId: string;
    processClientId: string;
    awsAccountId: string;
    sdkAccessRoleArn: string;
    automationStatus: string;
    emailAddress: string;
    createdBy: string;
    updatedBy: string;
    createdTimestamp: number;
    executionEnvironment: string;
    isWindowsApplicationAutomation: boolean;
    executionEnvironmentDetails: {
      resourceType: string;
      memory: number;
      storage: number;
      resourceId: string;
      awsAccountId: string;
      region: string;
      supportedConcurrentExecutions: number;
    };
  };
  pipelineDetail: {
    pipelineStatus: string;
    codePipelineUrl: string;
    codeRepositoryUrl: string;
    dashboardLink: string;
  };
  artifactBuildInfoList?: Array<{
    platform: string;
    buildStatus: string;
    buildTimestamp: number;
  }>;
}

export async function getAutomationDetailApi(requestBody: IGetAutomationDetailRequest) {
  try {
    const response = await axios.post<IGetAutomationDetailResponse>(
      `${automationRegistryApiEndpoint}/automn-reg/get-automation-detail`,
      { ...requestBody, devTeamId: store.getState().authState.selectedAuthProfile.ClientId },
    );
    return response.data;
  } catch (error) {
    if ((error as AxiosError)?.response?.data) {
      throw (error as AxiosError).response.data;
    }
    throw error;
  }
}

interface IAutomationExecutableRequest {
  automationId: string;
}

export interface IAutomationExecutableResponse {
  executableList: Array<{
    platform: string;
    version: string;
    artifactEnvironment: string;
    uiInputPreSignedURL: string;
    createdTimestamp: number;
  }>;
}

export async function getAutomationExecutableApi(requestBody: IAutomationExecutableRequest) {
  try {
    const response = await axios.post<IAutomationExecutableResponse>(
      `${automationRegistryApiEndpoint}/automn-reg/get-automation-executable`,
      { ...requestBody, devTeamId: store.getState().authState.selectedAuthProfile.ClientId },
    );
    return response.data;
  } catch (error) {
    if ((error as AxiosError)?.response?.data) {
      throw (error as AxiosError).response.data;
    }
    throw error;
  }
}

export interface IUpdateAutomationDetailRequest {
  automationId: string;
  automationName?: string;
  emailAddress?: string;
  dashboardLink?: string;
  isWindowsApplicationAutomation?: boolean;
  executionEnvironmentDetails?: {
    resourceType: string;
    memory: number;
    storage: number;
    resourceId: string;
    region: string;
    supportedConcurrentExecutions: number;
  };
}

export interface IUpdateAutomationDetailResponse {
  message: string;
}

export async function updateAutomationDetailApi(automationDetails: IUpdateAutomationDetailRequest) {
  try {
    const response = await axios.post<IUpdateAutomationDetailResponse>(
      `${automationRegistryApiEndpoint}/automn-reg/update-automation-detail`,
      {
        ...automationDetails,
        devTeamId: store.getState().authState.selectedAuthProfile.ClientId,
      },
    );

    return response.data;
  } catch (error) {
    if ((error as AxiosError)?.response?.data) {
      throw (error as AxiosError).response.data;
    }
    throw error;
  }
}

export interface IUpdateAutomationExecutableRequest {
  automationId: string;
  executable: {
    environment: ArtifactEnvironment;
    platform: ArtifactPlatform;
    version: string;
  };
}

export async function updateAutomationExecutableApi(request: IUpdateAutomationExecutableRequest) {
  try {
    const response = await axios.post<VoidFunction>(
      `${automationRegistryApiEndpoint}/automn-reg/update-automation-executable`,
      {
        ...request,
        devTeamId: store.getState().authState.selectedAuthProfile.ClientId,
      },
    );

    return response.data;
  } catch (error) {
    if ((error as AxiosError)?.response?.data) {
      throw (error as AxiosError).response.data;
    }
    throw error;
  }
}

export interface IUploadAutomationExecutableRequest {
  automationId: string;
  artifactEnvironment: ArtifactEnvironment;
}

export interface IUploadAutomationExecutableResponse {
  executableList: Array<{
    platform: ArtifactPlatform;
    version: string;
    uiInputPreSignedURL: string;
  }>;
}

export async function uploadAutomationExecutableApi(request: IUploadAutomationExecutableRequest) {
  try {
    const response = await axios.post<IUploadAutomationExecutableResponse>(
      `${automationRegistryApiEndpoint}/automn-reg/upload-automation-executable`,
      {
        ...request,
        devTeamId: store.getState().authState.selectedAuthProfile.ClientId,
      },
    );

    return response.data;
  } catch (error) {
    if ((error as AxiosError)?.response?.data) {
      throw (error as AxiosError).response.data;
    }
    throw error;
  }
}
