import axios from 'axios';

import { sdkSearchApiEndpoint } from '../config/endpoints';
import { store } from '../store/store';

interface ISearchWithIntentRequest {
  searchText: string;
}

export interface ISearchWithIntentResponse {
  matches: Array<{ similarityScore: number; result: SearchResultsResponse }>;
}

interface SearchResultsResponse {
  description: string;
  documentationWebsiteUrl: string;
  inputs: Array<{
    paramName: string;
    type: string;
    description: string;
  }>;
  example: string;
  name: string;
  output: {
    type: string;
    description: string;
  };
  parent: string;
}

export async function searchWithIntent(request: ISearchWithIntentRequest) {
  const response = await axios.post<ISearchWithIntentResponse>(`${sdkSearchApiEndpoint}/search-sdk-with-intent`, {
    ...request,
    devTeamId: store.getState().authState.selectedAuthProfile.ClientId,
  });
  return response.data;
}
