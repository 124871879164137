import { ColumnLayout, Container, Header } from '@cloudscape-design/components';
import React from 'react';
import { useSelector } from 'react-redux';

import { ArtifactEnvironment, ArtifactPlatform, AutomationType } from '../../../../common/types/enums';
import { AutomationExecutableDetail } from '../../../../common/types/interfaces';
import BoxElement from '../../../../components/polaris/BoxElement';
import { selectGetAutomationDetailResponse } from '../../../../slices/AutomationDetailSlice';
import { PLATFORM_DISPLAY_MAP } from '../constants/page-constants';

function findExecutableVersionForArtifactEnvironment(
  automationExecutableDetailMap: { [key in ArtifactPlatform]?: AutomationExecutableDetail },
  automationType: AutomationType,
) {
  const automationTypePlatformList = PLATFORM_DISPLAY_MAP[automationType] || [];

  let minVersion = Number.MAX_VALUE;
  for (const automationTypePlatformInfo of automationTypePlatformList) {
    const automationExecutableDetail = automationExecutableDetailMap[automationTypePlatformInfo.artifactPlatform];
    if (automationExecutableDetail) {
      minVersion = Math.min(minVersion, Number(automationExecutableDetail.version));
    } else {
      return 0;
    }
  }
  return minVersion;
}

export default function AutomationVersionInfoContainer() {
  const currentAutomation = useSelector(selectGetAutomationDetailResponse);
  if (!currentAutomation?.data) {
    return;
  }

  const { automationDetail, executableList } = currentAutomation.data;

  const automationExecutableInfoMap = executableList.reduce<{
    [key in ArtifactEnvironment]?: { [key in ArtifactPlatform]?: AutomationExecutableDetail };
  }>((automationExecutableInfoMap, automationExecutableInfo) => {
    if (!automationExecutableInfoMap[automationExecutableInfo.artifactEnvironment]) {
      automationExecutableInfoMap[automationExecutableInfo.artifactEnvironment] = {};
    }
    automationExecutableInfoMap[automationExecutableInfo.artifactEnvironment][automationExecutableInfo.platform] =
      automationExecutableInfo;
    return automationExecutableInfoMap;
  }, {});

  const uatVersion = findExecutableVersionForArtifactEnvironment(
    automationExecutableInfoMap.BETA || {},
    automationDetail.automationType,
  );
  const prodVersion = findExecutableVersionForArtifactEnvironment(
    automationExecutableInfoMap.PROD || {},
    automationDetail.automationType,
  );

  return (
    <Container header={<Header variant="h2">Version</Header>}>
      <ColumnLayout borders="vertical" columns={2}>
        <BoxElement headerText="UAT version" description={uatVersion || '-'} />
        <BoxElement headerText="Prod version" description={prodVersion || '-'} />
      </ColumnLayout>
    </Container>
  );
}
