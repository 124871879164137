import { Button, ColumnLayout, Container, Header } from '@cloudscape-design/components';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { BLOCKS, CREATE_AUTOMATION } from '../../../common/constants/route-paths';
import { HeadBoxElement } from '../../../components/polaris/BoxElement';
import { devDocumentationUrl, slackChannelLink } from '../../../config/endpoints';
import { setGetStartedContainerDismissed } from '../../../slices/SitePreferencesSlice';
import { AppDispatch } from '../../../store/store';

export default function GetStartedContainer() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  return (
    <Container
      header={
        <Header
          variant="h2"
          actions={
            <Button iconName="close" onClick={() => dispatch(setGetStartedContainerDismissed(true))}>
              Dismiss
            </Button>
          }
        >
          Get started
        </Header>
      }
    >
      <ColumnLayout borders="vertical" columns={4}>
        <HeadBoxElement headerText="Learn the basics" description="Learn how to create and code your automations">
          <Button
            ariaLabel="View tutorials (opens new tab)"
            href={devDocumentationUrl}
            iconAlign="right"
            iconName="external"
            target="_blank"
          >
            View tutorials
          </Button>
        </HeadBoxElement>
        <HeadBoxElement headerText="Join the community" description="Learn, share and grow">
          <Button
            ariaLabel="Open Slack Channel"
            href={slackChannelLink}
            iconAlign="right"
            iconName="external"
            target="_blank"
          >
            Open Slack Channel
          </Button>
        </HeadBoxElement>
        <HeadBoxElement
          headerText="Explore Blocks Marketplace"
          description="Build automations faster with reusable Blocks"
        >
          <Button onClick={() => navigate(BLOCKS)}>Open Blocks</Button>
        </HeadBoxElement>
        <HeadBoxElement headerText="Build your first automation" description="Create an end-to-end automation">
          <Button onClick={() => navigate(CREATE_AUTOMATION)}>Create automation</Button>
        </HeadBoxElement>
      </ColumnLayout>
    </Container>
  );
}
