import { Box, Button, Header, SpaceBetween, Table, Textarea } from '@cloudscape-design/components';

import { SDKDocSearchResult } from '../../../../common/types/interfaces';
import CopyText from '../../../../components/CopyText';

interface ISDKDocComponentsProps {
  sdkDocItem: SDKDocSearchResult;
}

export function SDKDocResultHeader({ sdkDocItem }: ISDKDocComponentsProps) {
  return (
    <Header
      description={sdkDocItem.parent}
      actions={
        <Button iconAlign="right" iconName="external" target="_blank" href={sdkDocItem.documentationWebsiteUrl}>
          View in documentation
        </Button>
      }
    >
      {sdkDocItem.name}
    </Header>
  );
}

export function SDKInputArgs({ sdkDocItem }: ISDKDocComponentsProps) {
  if (sdkDocItem.inputs) {
    return (
      <SpaceBetween size="s">
        <Box fontSize="heading-xs" variant="awsui-key-label">
          Args
        </Box>
        <Table
          variant="embedded"
          items={sdkDocItem.inputs}
          columnDefinitions={[
            { id: 'paramName', header: 'Name', cell: (item) => item.paramName },
            {
              id: 'type',
              header: 'Type',
              cell: (item) => (
                <Box variant="code" display="inline">
                  {item.type}
                </Box>
              ),
            },
            { id: 'description', header: 'Description', cell: (item) => item.description },
          ]}
        />
      </SpaceBetween>
    );
  } else {
    return <SDKDocElement header="Args" value="None" />;
  }
}

export function SDKDocElement(props: { header: string; value: string }) {
  return (
    <>
      <Box fontSize="heading-xs" variant="awsui-key-label">
        {props.header}
      </Box>
      <pre className="tw-word-wrap tw-ma0">
        <Box variant="p">{props.value}</Box>
      </pre>
    </>
  );
}

export function SDKOutput({ sdkDocItem }: ISDKDocComponentsProps) {
  return (
    <>
      <Box fontSize="heading-xs" variant="awsui-key-label">
        Returns
      </Box>
      <Box variant="p">
        <Box variant={sdkDocItem.output ? 'code' : 'p'} display="inline">
          {sdkDocItem.output?.type || 'NA'}
        </Box>
        {sdkDocItem.output?.description ? ' - ' + sdkDocItem.output.description : ''}
      </Box>
    </>
  );
}

export function SDKExample({ sdkDocItem }: ISDKDocComponentsProps) {
  if (!sdkDocItem.example) {
    return null;
  }
  return (
    <>
      <Box fontSize="heading-xs" variant="awsui-key-label">
        Example
      </Box>
      <div className="tw-flex">
        <Box variant="code" className="tw-flex-grow tw-mr2">
          <Textarea className="tw-font-inherit" value={sdkDocItem.example} rows={5} />
        </Box>
        <CopyText
          copyText={sdkDocItem.example}
          errorText="Error while copying"
          successText="Example copied"
          hideCopyContent={true}
          copyButtonLabel="Copy example"
          buttonVariant="normal"
        />
      </div>
    </>
  );
}
