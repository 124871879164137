import { Alert } from '@cloudscape-design/components';
import React from 'react';
import { useSelector } from 'react-redux';

import { BlockStatus } from '../../../../common/types/enums';
import { selectGetBlockResponse } from '../../../../slices/BlockDetailSlice';

export default function MyBlockPublishAlert() {
  const currentBlock = useSelector(selectGetBlockResponse);

  return (
    currentBlock.data?.blockStatus === BlockStatus.REGISTERED && (
      <Alert type={'warning'}>
        <span>
          You haven’t published the block yet. To publish the block, make sure there is at least one executable for the
          block.
        </span>
      </Alert>
    )
  );
}
