import { Button, Header, SpaceBetween } from '@cloudscape-design/components';

import { sdkDocumentationEndpoint } from '../../../config/endpoints';
import SearchBar from '../../Automation/SDKSearchPage/components/SearchBar';

export default function HomePageContentHeader() {
  return (
    <Header
      variant="h1"
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <SearchBar />
          <Button
            ariaLabel="Documentation (opens new tab)"
            href={sdkDocumentationEndpoint}
            iconAlign="right"
            iconName="external"
            target="_blank"
          >
            Documentation
          </Button>
        </SpaceBetween>
      }
    >
      Home
    </Header>
  );
}
