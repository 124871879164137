import { AppLayout, ContentLayout, Header, Spinner } from '@cloudscape-design/components';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { BLOCKS, LIBRARY_BLOCKS } from '../../../common/constants/route-paths';
import { getBlockDetailPagePath } from '../../../common/page-route-path';
import { BlockDetailTabId, LoadingStatus, PageViewType } from '../../../common/types/enums';
import GlobalHelpPanel from '../../../components/GlobalHelpPanel';
import GlobalNavigationPanel from '../../../components/GlobalNavigationPanel';
import BreadcrumbLinkGroup from '../../../components/polaris/BreadcrumbLinkGroup';
import { AuthProfileSelector } from '../../../slices/AuthStateSlice';
import { getCapability, selectGetCapabilityResponse } from '../../../slices/CapabilityDetailSlice';
import { selectIsNavigationPanelOpen, setNavigationPanelOpen } from '../../../slices/SitePreferencesSlice';
import { AppDispatch } from '../../../store/store';
import BlockFunctionViewPageContainer from './component/BlockFunctionViewPageContainer';

function BlockFunctionViewPageContent() {
  const getCapabilityData = useSelector(selectGetCapabilityResponse);

  return getCapabilityData.data ? (
    <ContentLayout header={<Header variant="h1">{getCapabilityData?.data?.capabilityDetail?.capabilityName}</Header>}>
      <BlockFunctionViewPageContainer />
    </ContentLayout>
  ) : (
    getCapabilityData.status === LoadingStatus.PENDING && (
      <div className="tw-page-loading-spinner">
        <Spinner size="large" />
      </div>
    )
  );
}

export default function BlockFunctionViewPage() {
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const isNavigationPanelOpen = useSelector(selectIsNavigationPanelOpen);
  const selectedAuthProfile = useSelector(AuthProfileSelector);
  const getCapabilityData = useSelector(selectGetCapabilityResponse);

  const navigationChangeHandler = () => {
    dispatch(setNavigationPanelOpen(!isNavigationPanelOpen));
  };

  useEffect(() => {
    void dispatch(
      getCapability({
        blockId: params.blockId,
        capabilityId: params.capabilityId,
        capabilityVersion: 'LATEST',
        pageViewType: PageViewType.NORMAL_VIEW,
      }),
    );
  }, [selectedAuthProfile]);

  return (
    <AppLayout
      headerSelector="#tw-header"
      stickyNotifications={false}
      content={<BlockFunctionViewPageContent />}
      navigation={<GlobalNavigationPanel />}
      navigationOpen={isNavigationPanelOpen}
      onNavigationChange={navigationChangeHandler}
      tools={<GlobalHelpPanel />}
      breadcrumbs={
        <BreadcrumbLinkGroup
          links={
            getCapabilityData?.data
              ? [
                  {
                    text: 'Blocks',
                    href: BLOCKS,
                  },
                  {
                    text: 'Libraries',
                    href: LIBRARY_BLOCKS,
                  },
                  {
                    text: getCapabilityData.data.blockDetail.blockName,
                    href: getBlockDetailPagePath(params.blockId, BlockDetailTabId.FUNCTIONS),
                  },
                  {
                    text: getCapabilityData.data.capabilityDetail.capabilityName,
                    href: '#',
                  },
                ]
              : [
                  {
                    text: 'Blocks',
                    href: BLOCKS,
                  },
                  {
                    text: 'Libraries',
                    href: LIBRARY_BLOCKS,
                  },
                  {
                    text: '',
                    href: '#',
                  },
                ]
          }
        />
      }
    />
  );
}
