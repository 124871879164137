const local = {
  Auth: {
    mandatorySignIn: true,
    region: process.env.REACT_APP_COGNITO_REGION_LOCAL,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID_LOCAL,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID_LOCAL,
    oauth: {
      domain: process.env.REACT_APP_COGNITO_DOMAIN_LOCAL,
      scope: ['openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT_SIGNIN_URI_LOCAL,
      redirectSignOut: process.env.REACT_APP_COGNITO_REDIRECT_SIGNOUT_URI_LOCAL,
      responseType: 'code',
    },
  },
};
const beta = {
  Auth: {
    mandatorySignIn: true,
    region: process.env.REACT_APP_COGNITO_REGION_BETA,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID_BETA,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID_BETA,
    oauth: {
      domain: process.env.REACT_APP_COGNITO_DOMAIN_BETA,
      scope: ['openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT_SIGNIN_URI_BETA,
      redirectSignOut: process.env.REACT_APP_COGNITO_REDIRECT_SIGNOUT_URI_BETA,
      responseType: 'code',
    },
  },
};

const prod = {
  Auth: {
    mandatorySignIn: true,
    region: process.env.REACT_APP_COGNITO_REGION_PROD,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID_PROD,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID_PROD,
    oauth: {
      domain: process.env.REACT_APP_COGNITO_DOMAIN_PROD,
      scope: ['openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT_SIGNIN_URI_PROD,
      redirectSignOut: process.env.REACT_APP_COGNITO_REDIRECT_SIGNOUT_URI_PROD,
      responseType: 'code',
    },
  },
};

let awsConfig = {};

if (process.env.REACT_APP_ENV === 'beta') {
  awsConfig = beta;
} else if (process.env.REACT_APP_ENV === 'prod') {
  awsConfig = prod;
} else {
  awsConfig = local;
}

export default awsConfig;
