import { ColumnLayout, Container, Header, Popover, SpaceBetween, StatusIndicator } from '@cloudscape-design/components';
import React from 'react';
import { useSelector } from 'react-redux';

import {
  AutomationTypeDisplayNameMap,
  ExecutionEnvironmentDisplayNameMap,
} from '../../../../common/constants/automation-constants';
import {
  AUTO_DEV_AUTOMATION_PREVIEW_TICKET_TEMPLATE_URL,
  CLOUD_AUTOMATION_ONBOARDING_TICKET_TEMPLATE_URL,
} from '../../../../common/constants/string-constants';
import { getProcessDetailPagePath } from '../../../../common/page-route-path';
import { AutomationStatus, AutomationType } from '../../../../common/types/enums';
import { convertDateToISOWithTimeZone } from '../../../../common/utils';
import CopyText from '../../../../components/CopyText';
import BoxElement from '../../../../components/polaris/BoxElement';
import TALink from '../../../../components/polaris/TALink';
import { selectGetAutomationDetailResponse } from '../../../../slices/AutomationDetailSlice';

function getAutomationTypeInfoPopover(automationType: AutomationType) {
  switch (automationType) {
    case AutomationType.AUTO_DEV:
      return (
        <Popover
          header="AutoDev is in preview"
          triggerType={'custom'}
          content={
            <>
              AutoDev is our new low-code automation development product. If you’d like to participate in the preview,
              please fill out{' '}
              <TALink external={true} href={AUTO_DEV_AUTOMATION_PREVIEW_TICKET_TEMPLATE_URL}>
                this
              </TALink>{' '}
              form
            </>
          }
        >
          <StatusIndicator type="info" />
        </Popover>
      );
    case AutomationType.CLOUD:
      return (
        <Popover
          header="Cloud automation"
          triggerType={'custom'}
          content={
            <>
              As of now we support only registration of cloud automations, please reach out to product team for
              registering other attributes by creating a ticket{' '}
              <TALink external={true} href={CLOUD_AUTOMATION_ONBOARDING_TICKET_TEMPLATE_URL}>
                here
              </TALink>
            </>
          }
        >
          <StatusIndicator type="info" />
        </Popover>
      );
    default:
      return <></>;
  }
}

export default function AutomationBasicInfoContainer() {
  const currentAutomation = useSelector(selectGetAutomationDetailResponse);
  if (!currentAutomation?.data) {
    return;
  }

  const { automationDetail } = currentAutomation.data;

  return (
    <Container header={<Header variant="h2">Basic information</Header>}>
      <ColumnLayout borders="vertical" columns={3}>
        <BoxElement
          headerText="Automation ID"
          description={
            <CopyText
              copyText={automationDetail.automationId}
              copyButtonLabel="Automation ID"
              successText="Automation ID copied"
              errorText="Failed to copy"
            />
          }
        />
        <BoxElement
          headerText="Process ID"
          description={
            <TALink
              external
              externalIconAriaLabel="Opens in new tab"
              href={getProcessDetailPagePath(automationDetail.processId, automationDetail.processClientId)}
            >
              {automationDetail.processId}
            </TALink>
          }
        />
        <BoxElement headerText="Process client ID" description={automationDetail.processClientId} />
        <BoxElement
          headerText="Executable type"
          description={
            <SpaceBetween size={'xs'} direction={'horizontal'}>
              {AutomationTypeDisplayNameMap[automationDetail.automationType]}
              {automationDetail.automationStatus === AutomationStatus.DEVELOPMENT
                ? getAutomationTypeInfoPopover(automationDetail.automationType)
                : ''}
            </SpaceBetween>
          }
        />
        <BoxElement
          headerText="Execution environment"
          description={ExecutionEnvironmentDisplayNameMap[automationDetail.executionEnvironment]}
        />
        <BoxElement headerText="Email address" description={automationDetail.emailAddress || '-'} />
        <BoxElement headerText="Created by" description={automationDetail.createdBy || '-'} />
        <BoxElement
          headerText="Created on"
          description={convertDateToISOWithTimeZone(new Date(automationDetail.createdOn))}
        />
        <BoxElement headerText="Updated by" description={automationDetail.updatedBy || '-'} />
      </ColumnLayout>
    </Container>
  );
}
