import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IRegisterTeamRequest, IRegisterTeamResponse, registerTeamApi } from '../api/team-registry-api';
import { LoadingStatus } from '../common/types/enums';
import { RootState } from '../store/store';

interface ITeamDetailState {
  registerTeamResponse: {
    status: LoadingStatus;
    teamId?: string;
    error?: string;
  };
}

const initialState: ITeamDetailState = {
  registerTeamResponse: {
    status: LoadingStatus.IDLE,
  },
};

export const registerTeam = createAsyncThunk(
  'teamDetail/registerTeam',
  async (request: IRegisterTeamRequest): Promise<IRegisterTeamResponse> => {
    return await registerTeamApi(request);
  },
);

const teamDetailSlice = createSlice({
  name: 'teamDetail',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(registerTeam.pending, (state) => {
      state.registerTeamResponse.status = LoadingStatus.PENDING;
      state.registerTeamResponse.error = '';
    });
    builder.addCase(registerTeam.fulfilled, (state, action) => {
      state.registerTeamResponse.error = '';
      state.registerTeamResponse.status = LoadingStatus.SUCCESS;
      state.registerTeamResponse.teamId = action.payload.teamId;
    });
    builder.addCase(registerTeam.rejected, (state, action) => {
      state.registerTeamResponse.status = LoadingStatus.FAILED;
      state.registerTeamResponse.error = action?.error?.message || 'Something went wrong. Please try again later';
    });
  },
});

export const selectRegisterTeamResponse = (state: RootState) => state.teamDetail.registerTeamResponse;

export default teamDetailSlice.reducer;
