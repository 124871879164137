import { FormField, NonCancelableCustomEvent, Textarea } from '@cloudscape-design/components';
import { FormFieldProps } from '@cloudscape-design/components/form-field';
import { TextareaProps } from '@cloudscape-design/components/textarea';
import React from 'react';
import { Controller, FieldValues, get, UseControllerProps, useFormContext } from 'react-hook-form';

type ControlledTextareaProps<T extends FieldValues> = UseControllerProps<T> &
  Omit<TextareaProps, 'name' | 'onChange' | 'value'>;

interface ITextareaFormFieldProps extends Partial<TextareaProps>, FormFieldProps, ControlledTextareaProps<FieldValues> {
  name: string;
}

export default function TextareaFormField(props: ITextareaFormFieldProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <FormField {...props} errorText={get(errors, `${props.name}.message`)}>
      <ControlledTextarea {...props} name={props.name} control={control} />
    </FormField>
  );
}

function ControlledTextarea<T extends FieldValues>(props: ControlledTextareaProps<T>) {
  return (
    <Controller
      {...props}
      render={({ field: { onChange, value, ref } }) => (
        <Textarea
          {...props}
          onChange={(event: NonCancelableCustomEvent<TextareaProps.ChangeDetail>) => {
            const inputValue = event.detail.value;
            // for blank input (represented as an empty string),
            //  whitespace is trimmed from the string before null validation occurs.
            //  this behavior disallows the user from entering whitespace as the first character of a textarea entry
            const formValue = !inputValue.trim() ? null : inputValue;
            onChange(formValue);
          }}
          value={value}
          ref={ref}
          name={props.name}
        />
      )}
    />
  );
}
