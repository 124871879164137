import { Button, Link, SpaceBetween, Spinner } from '@cloudscape-design/components';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { getMyBlockCreatePipelinePagePath } from '../../../../common/page-route-path';
import { LoadingStatus, PipelineStatus } from '../../../../common/types/enums';
import { AuthProfileSelector } from '../../../../slices/AuthStateSlice';
import { getBlockPipeline, selectGetBlockPipelineResponse } from '../../../../slices/BlockDetailSlice';
import { AppDispatch } from '../../../../store/store';
import PipelineBasicInfoContainer from './PipelineBasicInfoContainer';
import PipelineBuildInfoContainer from './PipelineBuildInfoContainer';

export default function MyBlockPipelineDetailTab() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const selectedAuthProfile = useSelector(AuthProfileSelector);
  const getBlockPipelineData = useSelector(selectGetBlockPipelineResponse);

  useEffect(() => {
    void dispatch(getBlockPipeline(params.blockId));
  }, [selectedAuthProfile]);

  if (getBlockPipelineData.status === LoadingStatus.PENDING) {
    return (
      <div className="tw-mt5 tw-flex tw-justify-center tw-light-text">
        <SpaceBetween direction="horizontal" size="xxs">
          <Spinner size="normal" />
          Loading pipeline details
        </SpaceBetween>
      </div>
    );
  }

  if (!getBlockPipelineData.data?.pipelineInfo) {
    return (
      <SpaceBetween size="l" alignItems={'center'}>
        <div className="tw-mt5 tw-light-text">You haven’t created the pipeline yet.</div>
        <Button variant={'primary'} onClick={() => navigate(getMyBlockCreatePipelinePagePath(params.blockId))}>
          Create pipeline
        </Button>
      </SpaceBetween>
    );
  } else if (getBlockPipelineData.data?.pipelineInfo.pipelineStatus === PipelineStatus.SUCCESS) {
    return (
      <SpaceBetween size="xl">
        <PipelineBasicInfoContainer />
        <PipelineBuildInfoContainer />
      </SpaceBetween>
    );
  } else if (getBlockPipelineData.data?.pipelineInfo.pipelineStatus === PipelineStatus.INITIATED) {
    return (
      <div className="tw-mt5 tw-flex tw-justify-center tw-light-text">
        Pipeline creation is still in progress. Try refreshing the page after few minutes.
      </div>
    );
  } else {
    return (
      <SpaceBetween size="l" alignItems={'center'}>
        <div className="tw-mt5 tw-light-text">
          Pipeline creation failed, retry creating pipeline, if still facing issues then{' '}
          <Link external href="https://t.corp.amazon.com/create/templates/5740d047-54c2-4b60-866f-f616d351586b">
            reach out to the platform team
          </Link>
        </div>
        <Button variant="primary" onClick={() => navigate(getMyBlockCreatePipelinePagePath(params.blockId))}>
          Re-create pipeline
        </Button>
      </SpaceBetween>
    );
  }
}
