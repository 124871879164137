export const processNameColId = 'processName';
export const requestedDateColId = 'createdOn';
export const requestedByColId = 'createdBy';
export const requestIdColId = 'requestId';
export const automationRequestStatusColId = 'status';
export const automationIdColId = 'automationId';
export const requestOwnerClientIdColId = 'requestOwnerClientId';

export const colIdToNameMap = {
    [processNameColId]: 'Process name',
    [automationRequestStatusColId]: 'Phase',
    [requestedDateColId]: 'Request date',
    [requestedByColId]: 'Requested by',
    [requestIdColId]: 'Request detail',
    [automationIdColId]: 'Automation',
    [requestOwnerClientIdColId]: 'Requester team',
};
