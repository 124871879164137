import React from 'react';
import { Route, Routes } from 'react-router-dom';

import {
  AUTOMATION_DETAIL,
  AUTOMATION_INSTANT_UI_PREVIEW,
  AUTOMATION_REQUESTS,
  BLOCK_FUNCTION_DETAIL,
  BLOCKS,
  CREATE_AUTOMATION,
  CREATE_AUTOMATION_PIPELINE,
  EDIT_AUTOMATION_DETAIL,
  HOME,
  LIBRARY_BLOCK_DETAIL,
  LIBRARY_BLOCKS,
  LIBRARY_BLOCKS_CATEGORY,
  MY_BLOCK_CREATE,
  MY_BLOCK_CREATE_PIPELINE,
  MY_BLOCK_FUNCTION_DETAIL,
  MY_BLOCK_FUNCTION_EDIT,
  MY_BLOCK_FUNCTION_REGISTER,
  MY_BLOCK_LIBRARY_DETAIL,
  MY_BLOCKS,
  SDK_SEARCH_PAGE,
} from '../common/constants/route-paths';
import AutomationDetailPage from '../pages/Automation/AutomationDetailPage/AutomationDetailPage';
import CreateAutomationPage from '../pages/Automation/CreateAutomationPage/CreateAutomationPage';
import CreateAutomationPipelinePage from '../pages/Automation/CreateAutomationPipelinePage/CreateAutomationPipelinePage';
import EditAutomationDetailPage from '../pages/Automation/EditAutomationDetailPage/EditAutomationDetailPage';
import InstantUIPreviewPage from '../pages/Automation/InstantUIPreviewPage/InstantUIPreviewPage';
import SDKSearchPage from '../pages/Automation/SDKSearchPage/SDKSearchPage';
import BlockDetailPage from '../pages/Blocks/BlockDetailPage/BlockDetailPage';
import BlockFunctionViewPage from '../pages/Blocks/BlockFunctionViewPage/BlockFunctionViewPage';
import BlocksHomePage from '../pages/Blocks/BlocksHomePage/BlocksHomePage';
import BlocksPage from '../pages/Blocks/BlocksPage/BlocksPage';
import HomePage from '../pages/HomePage/HomePage';
import CreateBlockPage from '../pages/MyBlocks/CreateBlockPage/CreateBlockPage';
import CreateBlockPipelinePage from '../pages/MyBlocks/CreateBlockPipelinePage/CreateBlockPipelinePage';
import MyBlockDetailPage from '../pages/MyBlocks/MyBlockDetailPage/MyBlockDetailPage';
import MyBlockFunctionEditPage from '../pages/MyBlocks/MyBlockFunctionEditPage/MyBlockFunctionEditPage';
import MyBlockFunctionRegisterPage from '../pages/MyBlocks/MyBlockFunctionRegisterPage/MyBlockFunctionRegisterPage';
import MyBlockFunctionViewPage from '../pages/MyBlocks/MyBlockFunctionViewPage/MyBlockFunctionViewPage';
import MyBlocksPage from '../pages/MyBlocks/MyBlocksPage/MyBlocksPage';
import AutomationRequestsPage from '../pages/RequestMgmt/AutomationRequestsPage/AutomationRequestsPage';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path={HOME} element={<HomePage />} />
      <Route path={SDK_SEARCH_PAGE} element={<SDKSearchPage />} />
      <Route path={AUTOMATION_REQUESTS} element={<AutomationRequestsPage />} />
      <Route path={CREATE_AUTOMATION} element={<CreateAutomationPage />} />
      <Route path={AUTOMATION_DETAIL} element={<AutomationDetailPage />} />
      <Route path={CREATE_AUTOMATION_PIPELINE} element={<CreateAutomationPipelinePage />} />
      <Route path={EDIT_AUTOMATION_DETAIL} element={<EditAutomationDetailPage />} />
      <Route path={AUTOMATION_INSTANT_UI_PREVIEW} element={<InstantUIPreviewPage />} />
      <Route path={BLOCKS} element={<BlocksHomePage />} />
      <Route path={LIBRARY_BLOCKS} element={<BlocksPage />} />
      <Route path={LIBRARY_BLOCKS_CATEGORY} element={<BlocksPage />} />
      <Route path={LIBRARY_BLOCK_DETAIL} element={<BlockDetailPage />} />
      <Route path={BLOCK_FUNCTION_DETAIL} element={<BlockFunctionViewPage />} />
      <Route path={MY_BLOCKS} element={<MyBlocksPage />} />
      <Route path={MY_BLOCK_CREATE} element={<CreateBlockPage />} />
      <Route path={MY_BLOCK_LIBRARY_DETAIL} element={<MyBlockDetailPage />} />
      <Route path={MY_BLOCK_CREATE_PIPELINE} element={<CreateBlockPipelinePage />} />
      <Route path={MY_BLOCK_FUNCTION_REGISTER} element={<MyBlockFunctionRegisterPage />} />
      <Route path={MY_BLOCK_FUNCTION_DETAIL} element={<MyBlockFunctionViewPage />} />
      <Route path={MY_BLOCK_FUNCTION_EDIT} element={<MyBlockFunctionEditPage />} />
    </Routes>
  );
};

export default AppRoutes;
