import { InferType, object, string } from 'yup';
import {LDAP_GROUP_NAME, TEAM_NAME} from '../constants/form-constants';
import { UseFormProps } from 'react-hook-form/dist/types';
import { yupResolver } from '@hookform/resolvers/yup';

export const TeamDetailsSchema = object({
    [TEAM_NAME]: string().required().matches(RegExp('^[A-Za-z0-9_-]+$'), {
        message: 'Only letters, numbers, underscore and dash are allowed',
    }).max(50).label('Team name'),
    [LDAP_GROUP_NAME]: string().required().matches(RegExp('^[A-Za-z0-9-]+$'), {
        message: 'Only letters, numbers and dash are allowed',
    }).max(50).label('Team LDAP name'),
});

export type TeamDetailsSchemaType = InferType<typeof TeamDetailsSchema>;

export const TeamDetailsFormProps: UseFormProps<TeamDetailsSchemaType, any> = {
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(TeamDetailsSchema),
};
