import { Button, Header, SpaceBetween } from '@cloudscape-design/components';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { LoadingStatus, PageViewType } from '../../../../common/types/enums';
import {
  getBlockArtifactAsset,
  selectGetBlockArtifactAssetResponse,
  selectGetBlockResponse,
  selectListBlockArtifactsResponse,
} from '../../../../slices/BlockDetailSlice';
import { AppDispatch } from '../../../../store/store';

export default function BlockDetailPageContentHeader() {
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const currentBlock = useSelector(selectGetBlockResponse);
  const listBlockArtifactsData = useSelector(selectListBlockArtifactsResponse);
  const getBlockArtifactAssetData = useSelector(selectGetBlockArtifactAssetResponse);

  useEffect(() => {
    if (getBlockArtifactAssetData.status === LoadingStatus.SUCCESS) {
      window.location.href = getBlockArtifactAssetData.artifactAssetUrl;
    }
  }, [getBlockArtifactAssetData.status]);

  return (
    currentBlock.data && (
      <Header
        variant="h1"
        actions={
          <SpaceBetween direction="horizontal" size="l">
            <Button
              download
              onClick={() => {
                void dispatch(
                  getBlockArtifactAsset({
                    blockId: params.blockId,
                    version: 'LATEST',
                    pageViewType: PageViewType.NORMAL_VIEW,
                  }),
                );
              }}
              disabled={!listBlockArtifactsData.data?.length}
              loading={getBlockArtifactAssetData.status === LoadingStatus.PENDING}
            >
              Download
            </Button>
          </SpaceBetween>
        }
      >
        <SpaceBetween direction="vertical" size="s">
          {currentBlock.data.blockName}
        </SpaceBetween>
      </Header>
    )
  );
}
