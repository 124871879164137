import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import AuthProfileData from '../common/interfaces/AuthProfileData';
import { AuthStates } from '../common/types/enums';
import { RootState } from '../store/store';

const initialState = {
  currentAuthState: AuthStates.SIGNED_OUT,
  selectedAuthProfile: {
    ClientId: '',
    Role: '',
  },
};

const authStateSlice = createSlice({
  name: 'authState',
  initialState,
  reducers: {
    reset: (state) => initialState,
    setAuthState: (state, action) => {
      state.currentAuthState = action.payload;
    },
    setAuthProfile: (state, action: PayloadAction<AuthProfileData>) => {
      state.selectedAuthProfile = action.payload;
    },
  },
});

export const selectAuthState = (state: RootState) => state.authState.currentAuthState;
export const AuthProfileSelector = (state: RootState) => state.authState.selectedAuthProfile;
export const { setAuthState, setAuthProfile } = authStateSlice.actions;
export default authStateSlice.reducer;
