import { Box, Button, Modal, SpaceBetween, StatusIndicator } from '@cloudscape-design/components';
import { FlashbarProps } from '@cloudscape-design/components/flashbar';
import React, { SetStateAction, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { updateAutomationDetailApi } from '../../../../api/automation-registry-api';
import { listAutomationRequestsForDevTeam } from '../../../../api/request-manager-api';
import { IAutomationStatusConfig } from '../../../../common/interfaces/AutomationData';
import {
  getCompleteAutomationDetail,
  selectGetAutomationDetailResponse,
} from '../../../../slices/AutomationDetailSlice';
import { AppDispatch } from '../../../../store/store';

interface UpdateAutomationStatusModalProps {
  visible: boolean;
  onDismiss: () => void;
  automationStatusConfig: IAutomationStatusConfig;
  setNotifications: React.Dispatch<SetStateAction<FlashbarProps.MessageDefinition[]>>;
  removeNotification: (notificationId: string) => void;
}

export default function UpdateAutomationStatusModal(props: UpdateAutomationStatusModalProps) {
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const [updateAutomationStatusError, setUpdateAutomationStatusError] = useState('');
  const currentAutomation = useSelector(selectGetAutomationDetailResponse);

  async function handleConfirmButton() {
    try {
      setSubmitButtonLoading(true);
      setUpdateAutomationStatusError('');

      const automationRequestsResponse = await listAutomationRequestsForDevTeam({});
      const [requestDetail] = automationRequestsResponse.requestList.filter(
        (requestDetail) => requestDetail.processId === currentAutomation.data?.automationDetail.processId,
      );

      const updateAutomationRequest = {
        automationId: params.automationId,
        automationStatus: props.automationStatusConfig.nextAutomationStatus,
        requestId: requestDetail?.requestId,
      };

      await updateAutomationDetailApi(updateAutomationRequest);
      void dispatch(getCompleteAutomationDetail(params.automationId));
      props.onDismiss();
      props.setNotifications((prevState) => [
        ...prevState,
        {
          header: currentAutomation.data?.automationDetail.automationName + ' successfully updated.',
          type: 'success',
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => props.removeNotification('api-success'),
          id: 'api-success',
        },
      ]);
    } catch (error: any) {
      setUpdateAutomationStatusError(error?.message);
    } finally {
      setSubmitButtonLoading(false);
    }
  }

  return (
    <Modal
      visible={props.visible}
      onDismiss={() => props.onDismiss()}
      header={props.automationStatusConfig.updateAutomationStatusUpdateButtonText}
      closeAriaLabel="close-modal"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={() => props.onDismiss()}>
              Cancel
            </Button>
            <Button variant="primary" loading={submitButtonLoading} onClick={handleConfirmButton}>
              Confirm
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween direction="vertical" size="l">
        {props.automationStatusConfig.updateAutomationStatusUpdateButtonModalContent}
        {updateAutomationStatusError && (
          <StatusIndicator type="error" iconAriaLabel="error-label">
            {updateAutomationStatusError}
          </StatusIndicator>
        )}
      </SpaceBetween>
    </Modal>
  );
}
