import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  createAutomationApi,
  generateAutomationIdApi,
  getAutomationDetailApi,
  getAutomationExecutableApi,
  IAutomationExecutableResponse,
  ICreateAutomationRequest,
  ICreateAutomationResponse,
  IGenerateAutomationIdRequest,
  IGenerateAutomationIdResponse,
  IGetAutomationDetailResponse,
  IUpdateAutomationDetailRequest,
  IUpdateAutomationDetailResponse,
  listAutomationPipelineApi,
  updateAutomationDetailApi,
} from '../api/automation-registry-api';
import { createAutomationPipelineApi } from '../api/infra-manager-api';
import {
  ArtifactEnvironment,
  ArtifactPlatform,
  AutomationStatus,
  AutomationType,
  ExecutionEnvironment,
  LoadingStatus,
  PipelineBuildStatus,
  WindowsEC2SourceType,
} from '../common/types/enums';
import { AutomationDetail, IListAutomationsData } from '../common/types/interfaces';
import { getValidUsername } from '../common/utils';
import { RootState } from '../store/store';

interface IAutomationDetailState {
  listAutomationsResponse: {
    data: IListAutomationsData[];
    status: LoadingStatus;
    error?: string;
  };
  generateAutomationIdResponse: {
    status: LoadingStatus;
    automationId?: string;
    error?: string;
  };
  createAutomationResponse: {
    status: LoadingStatus;
    automationId?: string;
    error?: string;
  };
  getAutomationDetailResponse: {
    status: LoadingStatus;
    data?: AutomationDetail;
    error?: string;
  };
  getCompleteAutomationDetailResponse: {
    status: LoadingStatus;
    data?: AutomationDetail;
    error?: string;
  };
  createAutomationPipelineResponse: {
    status: LoadingStatus;
    automationId?: string;
    error?: string;
  };
  updateAutomationDetailResponse: {
    status: LoadingStatus;
    error?: string;
  };
}

const initialState: IAutomationDetailState = {
  listAutomationsResponse: { data: [], status: LoadingStatus.IDLE },
  generateAutomationIdResponse: {
    status: LoadingStatus.IDLE,
  },
  createAutomationResponse: {
    status: LoadingStatus.IDLE,
  },
  getAutomationDetailResponse: {
    status: LoadingStatus.IDLE,
  },
  getCompleteAutomationDetailResponse: {
    status: LoadingStatus.IDLE,
  },
  createAutomationPipelineResponse: {
    status: LoadingStatus.IDLE,
  },
  updateAutomationDetailResponse: { status: LoadingStatus.IDLE },
};

function transformApiResponseToAutomationDetail(
  automationDetailResponse: IGetAutomationDetailResponse,
  automationExecutableList?: IAutomationExecutableResponse,
) {
  const currentAutomationData: AutomationDetail = {
    automationDetail: {
      automationId: automationDetailResponse.automationDetail.automationId,
      automationName: automationDetailResponse.automationDetail.automationName,
      automationType: automationDetailResponse.automationDetail.automationType as AutomationType,
      processId: automationDetailResponse.automationDetail.processId,
      processClientId: automationDetailResponse.automationDetail.processClientId,
      sdkAccessRoleArn: automationDetailResponse.automationDetail.sdkAccessRoleArn,
      automationStatus: automationDetailResponse.automationDetail.automationStatus as AutomationStatus,
      emailAddress: automationDetailResponse.automationDetail.emailAddress,
      awsAccountId: automationDetailResponse.automationDetail.awsAccountId,
      createdBy: getValidUsername(automationDetailResponse.automationDetail.createdBy),
      updatedBy: getValidUsername(automationDetailResponse.automationDetail.updatedBy),
      createdOn: automationDetailResponse.automationDetail.createdTimestamp,
      executionEnvironment: automationDetailResponse.automationDetail.executionEnvironment as ExecutionEnvironment,
      isWindowsApplicationAutomation: automationDetailResponse.automationDetail.isWindowsApplicationAutomation,
      executionEnvironmentDetails: automationDetailResponse.automationDetail.executionEnvironmentDetails
        ? {
            resourceType: automationDetailResponse.automationDetail.executionEnvironmentDetails
              .resourceType as WindowsEC2SourceType,
            memory: automationDetailResponse.automationDetail.executionEnvironmentDetails.memory,
            storage: automationDetailResponse.automationDetail.executionEnvironmentDetails.storage,
            resourceId: automationDetailResponse.automationDetail.executionEnvironmentDetails.resourceId,
            awsAccountId: automationDetailResponse.automationDetail.executionEnvironmentDetails.awsAccountId,
            region: automationDetailResponse.automationDetail.executionEnvironmentDetails.region,
            supportedConcurrentExecutions:
              automationDetailResponse.automationDetail.executionEnvironmentDetails.supportedConcurrentExecutions,
          }
        : null,
    },
    pipelineDetail: {
      pipelineStatus: automationDetailResponse.pipelineDetail.pipelineStatus,
      codePipelineUrl: automationDetailResponse.pipelineDetail.codePipelineUrl,
      codeRepositoryUrl: automationDetailResponse.pipelineDetail.codeRepositoryUrl,
      dashboardLink: automationDetailResponse.pipelineDetail.dashboardLink,
    },
    artifactBuildInfoList: [],
    executableList: [],
  };

  if (automationDetailResponse.artifactBuildInfoList) {
    for (const artifactBuildInfo of automationDetailResponse.artifactBuildInfoList) {
      currentAutomationData.artifactBuildInfoList.push({
        platform: artifactBuildInfo.platform as ArtifactPlatform,
        buildStatus: artifactBuildInfo.buildStatus as PipelineBuildStatus,
        buildTimestamp: artifactBuildInfo.buildTimestamp,
      });
    }
  }

  if (automationExecutableList?.executableList) {
    for (const automationExecutable of automationExecutableList.executableList) {
      currentAutomationData.executableList.push({
        platform: automationExecutable.platform as ArtifactPlatform,
        version: automationExecutable.version,
        artifactEnvironment: automationExecutable.artifactEnvironment as ArtifactEnvironment,
        createdTimestamp: automationExecutable.createdTimestamp,
        uiInputPreSignedURL: automationExecutable.uiInputPreSignedURL,
      });
    }
  }

  return currentAutomationData;
}

export const listAutomations = createAsyncThunk(
  'automationDetail/listAutomations',
  async (): Promise<IListAutomationsData[]> => {
    const listAutomationPipelineResponse = await listAutomationPipelineApi({});
    return listAutomationPipelineResponse.pipelineList.map((automation) => ({
      automationId: automation.automationId,
      automationName: automation.automationName,
      processClientId: automation.processClientId,
      awsAccountId: automation.awsAccountId,
      codePipeline: automation.codePipelineUrl,
      codeRepository: automation.codeRepositoryUrl,
      createdOn: automation.createdTimestamp,
      lastBuildStatus: automation.buildStatus as PipelineBuildStatus,
      automationStatus: automation.automationStatus as AutomationStatus,
      createdBy: getValidUsername(automation.createdBy),
      updatedBy: getValidUsername(automation.updatedBy),
    }));
  },
);

export const generateAutomationId = createAsyncThunk(
  'automationDetail/generateAutomationId',
  async (request: IGenerateAutomationIdRequest): Promise<IGenerateAutomationIdResponse> => {
    return await generateAutomationIdApi(request);
  },
);

export const createAutomation = createAsyncThunk(
  'automationDetail/createAutomation',
  async (request: ICreateAutomationRequest): Promise<ICreateAutomationResponse> => {
    return await createAutomationApi(request);
  },
);

export const getAutomationDetail = createAsyncThunk(
  'automationDetail/getAutomationDetail',
  async (automationId: string): Promise<AutomationDetail> => {
    return transformApiResponseToAutomationDetail(await getAutomationDetailApi({ automationId }));
  },
);

export const getCompleteAutomationDetail = createAsyncThunk(
  'automationDetail/getCompleteAutomationDetail',
  async (automationId: string): Promise<AutomationDetail> => {
    const [automationDetailResponse, automationExecutableList] = await Promise.all([
      getAutomationDetailApi({ automationId }),
      getAutomationExecutableApi({ automationId }),
    ]);

    return transformApiResponseToAutomationDetail(automationDetailResponse, automationExecutableList);
  },
);

export const createAutomationPipeline = createAsyncThunk(
  'automationDetail/createAutomationPipeline',
  async (fieldValues: Record<string, string>) => {
    return await createAutomationPipelineApi(fieldValues);
  },
);

export const updateAutomationDetail = createAsyncThunk(
  'automationDetail/updateAutomationDetail',
  async (request: IUpdateAutomationDetailRequest): Promise<IUpdateAutomationDetailResponse> => {
    return await updateAutomationDetailApi(request);
  },
);

const automationDetailSlice = createSlice({
  name: 'automationDetail',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(listAutomations.pending, (state) => {
      state.listAutomationsResponse.status = LoadingStatus.PENDING;
      state.listAutomationsResponse.error = '';
    });
    builder.addCase(listAutomations.fulfilled, (state, action) => {
      state.listAutomationsResponse.data = action.payload;
      state.listAutomationsResponse.status = LoadingStatus.SUCCESS;
    });
    builder.addCase(listAutomations.rejected, (state, action) => {
      state.listAutomationsResponse.status = LoadingStatus.FAILED;
      state.listAutomationsResponse.error = 'Something went wrong';
    });
    builder.addCase(generateAutomationId.pending, (state) => {
      state.generateAutomationIdResponse.status = LoadingStatus.PENDING;
      state.generateAutomationIdResponse.error = '';
    });
    builder.addCase(generateAutomationId.fulfilled, (state, action) => {
      state.generateAutomationIdResponse.error = '';
      state.generateAutomationIdResponse.status = LoadingStatus.SUCCESS;
      state.generateAutomationIdResponse.automationId = action.payload.automationId;
    });
    builder.addCase(generateAutomationId.rejected, (state, action) => {
      state.generateAutomationIdResponse.status = LoadingStatus.FAILED;
      state.generateAutomationIdResponse.error =
        action?.error?.message || 'Something went wrong. Please try again later';
    });
    builder.addCase(createAutomation.pending, (state) => {
      state.createAutomationResponse.status = LoadingStatus.PENDING;
      state.createAutomationResponse.error = '';
    });
    builder.addCase(createAutomation.fulfilled, (state, action) => {
      state.createAutomationResponse.error = '';
      state.createAutomationResponse.status = LoadingStatus.SUCCESS;
      state.createAutomationResponse.automationId = action.payload.automationId;
    });
    builder.addCase(createAutomation.rejected, (state, action) => {
      state.createAutomationResponse.status = LoadingStatus.FAILED;
      state.createAutomationResponse.error = action?.error?.message || 'Something went wrong. Please try again later';
    });
    builder.addCase(getAutomationDetail.pending, (state) => {
      state.getAutomationDetailResponse.status = LoadingStatus.PENDING;
      state.getAutomationDetailResponse.error = '';
    });
    builder.addCase(getAutomationDetail.fulfilled, (state, action) => {
      state.getAutomationDetailResponse.error = '';
      state.getAutomationDetailResponse.status = LoadingStatus.SUCCESS;
      state.getAutomationDetailResponse.data = action.payload;
    });
    builder.addCase(getAutomationDetail.rejected, (state, action) => {
      state.getAutomationDetailResponse.status = LoadingStatus.FAILED;
      state.getAutomationDetailResponse.error =
        action?.error?.message || 'Something went wrong. Please try again later';
    });
    builder.addCase(getCompleteAutomationDetail.pending, (state) => {
      state.getAutomationDetailResponse.status = LoadingStatus.PENDING;
      state.getAutomationDetailResponse.error = '';
    });
    builder.addCase(getCompleteAutomationDetail.fulfilled, (state, action) => {
      state.getAutomationDetailResponse.error = '';
      state.getAutomationDetailResponse.status = LoadingStatus.SUCCESS;
      state.getAutomationDetailResponse.data = action.payload;
    });
    builder.addCase(getCompleteAutomationDetail.rejected, (state, action) => {
      state.getAutomationDetailResponse.status = LoadingStatus.FAILED;
      state.getAutomationDetailResponse.error =
        action?.error?.message || 'Something went wrong. Please try again later';
    });
    builder.addCase(createAutomationPipeline.pending, (state) => {
      state.createAutomationPipelineResponse.status = LoadingStatus.PENDING;
      state.createAutomationPipelineResponse.error = '';
    });
    builder.addCase(createAutomationPipeline.fulfilled, (state, action) => {
      state.createAutomationPipelineResponse.error = '';
      state.createAutomationPipelineResponse.status = LoadingStatus.SUCCESS;
      state.createAutomationPipelineResponse.automationId = action.payload.automationId;
    });
    builder.addCase(createAutomationPipeline.rejected, (state, action) => {
      state.createAutomationPipelineResponse.status = LoadingStatus.FAILED;
      state.createAutomationPipelineResponse.error =
        action?.error?.message || 'Something went wrong. Please try again later';
    });
    builder.addCase(updateAutomationDetail.pending, (state) => {
      state.updateAutomationDetailResponse.status = LoadingStatus.PENDING;
      state.updateAutomationDetailResponse.error = '';
    });
    builder.addCase(updateAutomationDetail.fulfilled, (state, action) => {
      state.updateAutomationDetailResponse.error = '';
      state.updateAutomationDetailResponse.status = LoadingStatus.SUCCESS;
    });
    builder.addCase(updateAutomationDetail.rejected, (state, action) => {
      state.updateAutomationDetailResponse.status = LoadingStatus.FAILED;
      state.updateAutomationDetailResponse.error =
        action?.error?.message || 'Something went wrong. Please try again later';
    });
  },
});

export const selectListAutomationsResponse = (state: RootState) => state.automationDetail.listAutomationsResponse;
export const selectGenerateAutomationIdResponse = (state: RootState) =>
  state.automationDetail.generateAutomationIdResponse;
export const selectCreateAutomationResponse = (state: RootState) => state.automationDetail.createAutomationResponse;
export const selectGetAutomationDetailResponse = (state: RootState) =>
  state.automationDetail.getAutomationDetailResponse;
export const selectCreateAutomationPipelineResponse = (state: RootState) =>
  state.automationDetail.createAutomationPipelineResponse;
export const selectUpdateAutomationDetailResponse = (state: RootState) =>
  state.automationDetail.updateAutomationDetailResponse;

export default automationDetailSlice.reducer;
