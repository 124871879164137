import { BlockType } from '../types/enums';

export const TAGS_LIST = [
  'Excel',
  'Word',
  'Outlook',
  'Desktop Automation',
  'Browser Automation',
  'AI',
  'Powerpoint',
  'Search',
  'PDF',
  'Selenium',
  'Chrome',
  'Firefox',
  'JSON',
  'DataTables',
  'Sales',
  'HR',
  'Procurement',
  'Finance',
  'Payments',
  'Utility',
  'Legal',
  'Marketing',
  'Customer Support',
  'Accounting',
  'Salesforce',
  'API',
  'AWS',
  'Security',
];

interface IBlockCategoryMapProps {
  name: string;
  iconUrl: string;
}

export const BLOCK_CATEGORY_MAP: Record<string, IBlockCategoryMapProps> = {
  WEB: {
    name: 'Web',
    iconUrl: 'https://m.media-amazon.com/images/G/01/taskless/workplace/blocks/Web.png',
  },
  AUTHENTICATION_AND_AUTHORIZATION: {
    name: 'Authentication and Authorization',
    iconUrl: 'https://m.media-amazon.com/images/G/01/taskless/workplace/blocks/Authentication_And_Authorization.png',
  },
  CLOUD: {
    name: 'Cloud',
    iconUrl: 'https://m.media-amazon.com/images/G/01/taskless/workplace/blocks/Cloud.png',
  },
  EMAIL: {
    name: 'Email',
    iconUrl: 'https://m.media-amazon.com/images/G/01/taskless/workplace/blocks/Email.png',
  },
  MACHINE_LEARNING: {
    name: 'Machine Learning',
    iconUrl: 'https://m.media-amazon.com/images/G/01/taskless/workplace/blocks/Machine_Learning.png',
  },
  DATA_MANIPULATION: {
    name: 'Data Manipulation',
    iconUrl: 'https://m.media-amazon.com/images/G/01/taskless/workplace/blocks/Data_Manipulation.png',
  },
  IMAGE_MANIPULATION: {
    name: 'Image Manipulation',
    iconUrl: 'https://m.media-amazon.com/images/G/01/taskless/workplace/blocks/Image_Manipulation.png',
  },
  INTELLIGENT_DOCUMENT_PROCESSING: {
    name: 'Intelligent Document Processing',
    iconUrl: 'https://m.media-amazon.com/images/G/01/taskless/workplace/blocks/Document_Processing.png',
  },
  OTHER: {
    name: 'Other',
    iconUrl: 'https://m.media-amazon.com/images/G/01/taskless/workplace/blocks/Other.png',
  },
};

interface IBlockTypeMapProps {
  name: string;
  pluralName: string;
  pascalPluralName: string;
}

export const BLOCK_TYPE_MAP: Record<BlockType, IBlockTypeMapProps> = {
  [BlockType.LIBRARY]: {
    name: 'library',
    pluralName: 'libraries',
    pascalPluralName: 'Libraries',
  },
  [BlockType.SERVICE]: {
    name: 'service',
    pluralName: 'services',
    pascalPluralName: 'Services',
  },
};
