import { Button, Form, SpaceBetween } from '@cloudscape-design/components';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { MY_BLOCKS } from '../../../../common/constants/route-paths';
import { BlockType, Framework, LoadingStatus } from '../../../../common/types/enums';
import { createBlockForClient, selectCreateBlockForClient } from '../../../../slices/BlockDetailSlice';
import { AppDispatch } from '../../../../store/store';
import { BlockDetailSchemaType, BlockDetailsFormProps } from '../config/block-form.config';
import CreateBlockContainer from './CreateBlockContainer';

interface ICreateBlockFormProps {
  blockType: BlockType;
}

export default function CreateBlockForm({ blockType }: ICreateBlockFormProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const blockDetailFormMethods = useForm<BlockDetailSchemaType>(BlockDetailsFormProps);
  const createBlockForClientResult = useSelector(selectCreateBlockForClient);

  function handleSaveChangeButton() {
    return async () => {
      const formFieldValues = {
        ...blockDetailFormMethods.getValues(),
        blockType,
        allowedClientIds: ['CLID#PUBLIC'],
        framework: Framework.DOTNET_FRAMEWORK_472,
      };

      await blockDetailFormMethods.handleSubmit(() => dispatch(createBlockForClient(formFieldValues)))();
    };
  }

  return (
    <Form
      actions={
        <SpaceBetween direction="horizontal" size="xl">
          <Button formAction="none" variant="link" onClick={() => navigate(MY_BLOCKS)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleSaveChangeButton()}
            loading={createBlockForClientResult.status === LoadingStatus.PENDING}
            disabled={createBlockForClientResult.status === LoadingStatus.PENDING}
          >
            Create
          </Button>
        </SpaceBetween>
      }
      errorText={createBlockForClientResult.error}
      variant="embedded"
    >
      <FormProvider {...blockDetailFormMethods}>
        <CreateBlockContainer />
      </FormProvider>
    </Form>
  );
}
