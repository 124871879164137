import { Auth } from 'aws-amplify';
import axios from 'axios';

import { AuthConstants } from '../common/constants/string-constants';
import {
  automationRegistryApiEndpoint,
  blockRegistryApiEndpoint,
  infraManagerApiEndpoint,
  requestManagerAPIEndpoint,
  sdkSearchApiEndpoint,
  workplaceKwPortalEndpoint,
} from '../config/endpoints';
import { store } from '../store/store';

/**
 * Intercept async request/response, record metrics & handle network errors
 */
axios.interceptors.request.use(
  async (config) => {
    // Adding the end time when the response is sent
    const newConfig = {
      metadata: { startTime: new Date() },
      ...config,
    };

    const isAuthorizationHeaderRequired = [
      workplaceKwPortalEndpoint,
      automationRegistryApiEndpoint,
      infraManagerApiEndpoint,
      sdkSearchApiEndpoint,
      blockRegistryApiEndpoint,
      requestManagerAPIEndpoint,
    ].find((endpoint) => newConfig.url.includes(endpoint));

    if (isAuthorizationHeaderRequired) {
      const currentAuthProfile = store.getState().authState.selectedAuthProfile;
      const selectedClientID = currentAuthProfile.ClientId;
      const selectedRole = currentAuthProfile.Role;
      const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();

      newConfig.headers.Authorization = idToken;
      if (selectedClientID && selectedRole) {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        newConfig.headers.Authorization = `${idToken}${AuthConstants.AuthorizationAndAuthProfileSeparationDelimiter}${selectedClientID}${AuthConstants.AuthorizationAndAuthProfileSeparationDelimiter}${selectedRole}`;
      }
    }
    return newConfig;
  },
  async (error) => {
    return await Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Adding the end time when the response is sent
    return { ...response, metadata: { endTime: new Date() } };
  },
  async (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    const newError = { ...error };
    newError.config.metadata.endTime = new Date();
    // Capture response error, if needed
    return await Promise.reject(newError);
  },
);
