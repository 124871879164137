import { Box, Button, Link } from '@cloudscape-design/components';
import React from 'react';

import { HOME } from '../common/constants/route-paths';
import AuthProfile from './auth-profile/AuthProfile';

interface GlobalTopNavBarProps {
  onSignOutClick: () => void;
}

export default function GlobalTopNavBar({ onSignOutClick }: GlobalTopNavBarProps) {
  return (
    <div id="tw-header">
      <Link href={HOME} className={'tw-mt2 tw-mb1'}>
        <img alt="Taskless Workplace Logo" src="https://m.media-amazon.com/images/G/01/taskless/workplace/logo-w.png" />
      </Link>
      <Box fontSize="heading-s" className="tw-white tw-ml1 tw-fw-500">
        <Link href={HOME} fontSize={'heading-s'} className="tw-white tw-fw-500 tw-link">
          Taskless Workplace Developer Console
        </Link>
        {process.env.REACT_APP_ENV && process.env.REACT_APP_ENV.substring(0, 4).toUpperCase() !== 'PROD' ? (
          <Box fontSize="body-m" className="tw-white tw-ml1" variant="span" fontWeight="normal">
            {process.env.REACT_APP_ENV[0].toUpperCase() + process.env.REACT_APP_ENV.substring(1).toLowerCase()}
          </Box>
        ) : null}
      </Box>

      <div className="tw-ml-auto">
        <AuthProfile />
        <Button className="sign-out-button" variant="link" onClick={onSignOutClick}>
          Sign out
        </Button>
      </div>
    </div>
  );
}
