import { useCollection } from '@cloudscape-design/collection-hooks';
import { Box, Header, Pagination, SpaceBetween, Table, TextFilter } from '@cloudscape-design/components';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { LoadingStatus, PageViewType } from '../../../../common/types/enums';
import { IListCapabilitiesForBlockData } from '../../../../common/types/interfaces';
import { getMatchesCountText } from '../../../../common/utils';
import { NoMatchState } from '../../../../components/polaris/TableElement';
import { AuthProfileSelector } from '../../../../slices/AuthStateSlice';
import {
  listCapabilitiesForBlock,
  selectListCapabilitiesForBlockResponse,
} from '../../../../slices/CapabilityDetailSlice';
import { AppDispatch } from '../../../../store/store';
import {
  COLUMN_DEFINITIONS,
  DEFAULT_PREFERENCES,
  PAGINATION_LABELS,
  Preferences,
} from '../config/functions-table.config';

function EmptyState() {
  return (
    <Box textAlign="center" color="inherit">
      <SpaceBetween size="s">
        <b>No functions</b>
      </SpaceBetween>
    </Box>
  );
}

export default function BlockFunctionsTab() {
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const selectedAuthProfile = useSelector(AuthProfileSelector);
  const listCapabilitiesForBlockData = useSelector(selectListCapabilitiesForBlockResponse);
  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);

  useEffect(() => {
    void dispatch(
      listCapabilitiesForBlock({
        blockId: params.blockId,
        pageViewType: PageViewType.NORMAL_VIEW,
      }),
    );
  }, [selectedAuthProfile]);

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } =
    useCollection<IListCapabilitiesForBlockData>(listCapabilitiesForBlockData.data, {
      filtering: {
        empty: <EmptyState />,
        noMatch: (
          <NoMatchState
            clearFilterAction={() => {
              actions.setFiltering('');
            }}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {
        defaultState: {
          sortingColumn: COLUMN_DEFINITIONS[COLUMN_DEFINITIONS.length - 1],
          isDescending: true,
        },
      },
    });

  return (
    <Table
      columnDefinitions={COLUMN_DEFINITIONS}
      {...collectionProps}
      items={items}
      loading={listCapabilitiesForBlockData.status === LoadingStatus.PENDING}
      loadingText="Loading functions"
      resizableColumns
      header={<Header counter={`(${listCapabilitiesForBlockData.data.length})`}>Functions</Header>}
      filter={
        <TextFilter
          {...filterProps}
          countText={getMatchesCountText(filteredItemsCount)}
          filteringAriaLabel="Filter functions"
          filteringPlaceholder="Find functions"
        />
      }
      visibleColumns={preferences.visibleContent}
      pagination={<Pagination {...paginationProps} ariaLabels={PAGINATION_LABELS} />}
      preferences={<Preferences preferences={preferences} setPreferences={setPreferences} />}
    />
  );
}
