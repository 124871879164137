export const AUTOMATION_NAME = 'automationName';
export const EXECUTION_ENVIRONMENT_RESOURCE_TYPE = 'resourceType';
export const EXECUTION_ENVIRONMENT_MEMORY = 'memory';
export const EXECUTION_ENVIRONMENT_STORAGE = 'storage';
export const EXECUTION_AWS_ACCOUNT_ID = 'awsAccountId';
export const EXECUTION_ENVIRONMENT_RESOURCE_ID = 'resourceId';
export const EXECUTION_AWS_REGION = 'region';
export const SUPPORTED_CONCURRENT_EXECUTIONS = 'supportedConcurrentExecutions';
export const EMAIL_ADDRESS = 'emailAddress';
export const IS_WINDOWS_APPLICATION_AUTOMATION = 'isWindowsApplicationAutomation';

export const DASHBOARD_LINK = 'dashboardLink';
