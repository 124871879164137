export const automationIdColId = 'automationId';
export const automationNameColId = 'automationName';
export const processClientIdColId = 'processClientId';
export const codePipelineColId = 'codePipeline';
export const codeRepositoryColId = 'codeRepository';
export const awsAccountIdColId = 'awsAccountId';
export const automationStatusColId = 'automationStatus';
export const lastBuildStatusColId = 'lastBuildStatus';
export const createdOnColId = 'createdOn';
export const createdByColId = 'createdBy';
export const lastUpdatedByColId = 'updatedBy';

// Column Header Names and IDs
export const colIdToNameMap = {
  [automationIdColId]: 'Automation ID',
  [automationNameColId]: 'Automation name',
  [processClientIdColId]: 'Process client ID',
  [codePipelineColId]: 'Code pipeline',
  [codeRepositoryColId]: 'Code repository',
  [awsAccountIdColId]: 'AWS account ID',
  [automationStatusColId]: 'State',
  [lastBuildStatusColId]: 'Last build status',
  [createdOnColId]: 'Created on',
  [createdByColId]: 'Created by',
  [lastUpdatedByColId]: 'Last updated by',
};
