import { ColumnLayout, Container, Header, StatusIndicator } from '@cloudscape-design/components';
import React from 'react';
import { useSelector } from 'react-redux';

import { PipelineBuildStatus } from '../../../../common/types/enums';
import { convertDateToISOWithTimeZone } from '../../../../common/utils';
import BoxElement from '../../../../components/polaris/BoxElement';
import { selectGetBlockPipelineResponse } from '../../../../slices/BlockDetailSlice';

export default function PipelineBuildInfoContainer() {
  const getBlockPipelineData = useSelector(selectGetBlockPipelineResponse);
  const { artifactBuildInfo } = getBlockPipelineData.data;

  return (
    artifactBuildInfo && (
      <Container header={<Header variant="h2">Builds</Header>}>
        <ColumnLayout borders="vertical" columns={2}>
          <BoxElement
            headerText={'Last build status '}
            description={
              artifactBuildInfo.buildStatus ? (
                <StatusIndicator
                  iconAriaLabel=""
                  type={artifactBuildInfo.buildStatus === PipelineBuildStatus.SUCCESS ? 'success' : 'error'}
                >
                  {artifactBuildInfo.buildStatus === PipelineBuildStatus.SUCCESS ? 'Pass' : 'Fail'}
                </StatusIndicator>
              ) : (
                '-'
              )
            }
          />
          <BoxElement
            headerText="Last build on"
            description={
              artifactBuildInfo.buildTimestamp
                ? convertDateToISOWithTimeZone(new Date(artifactBuildInfo.buildTimestamp))
                : '-'
            }
          />
        </ColumnLayout>
      </Container>
    )
  );
}
