import {Badge, CollectionPreferences, CollectionPreferencesProps, TableProps} from "@cloudscape-design/components";
import IAutomationRequestDetails from "../../../../common/interfaces/AutomationRequestDetails";
import {
    automationIdColId,
    automationRequestStatusColId,
    colIdToNameMap,
    processNameColId,
    requestedByColId,
    requestIdColId,
    requestOwnerClientIdColId
} from "./ongoing-request-column-constants";
import TALink from "../../../../components/polaris/TALink";
import {
    getAutomationDetailPagePath,
    getAutomationRequestDetailPagePath,
    getProcessDetailPagePath
} from "../../../../common/page-route-path";
import {convertDateToISOWithTimeZone, getValidUsername, removePrefix} from "../../../../common/utils";
import React from "react";
import {requestedDateColId} from "./queued-request-column-constants";
import {AutomationRequestStatusConfig} from "../../../../common/constants/automation-request-constants";

export const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<IAutomationRequestDetails>[] = [
    {
        id: processNameColId,
        header: colIdToNameMap[processNameColId],
        cell: (item) => {
            return (
                <TALink
                    external
                    externalIconAriaLabel="Opens in new tab"
                    href={getProcessDetailPagePath(item.processId, item.clientId)}
                >
                    {item.title}
                </TALink>
            )
        },
        sortingField: processNameColId,
    },
    {
        id: automationRequestStatusColId,
        header: colIdToNameMap[automationRequestStatusColId],
        cell: (item) => {
            return (
                <Badge {...AutomationRequestStatusConfig[item.status].badgeProps}>
                    {AutomationRequestStatusConfig[item.status].statusDisplayText}
                </Badge>
            )
        },
        sortingField: automationRequestStatusColId,
    },
    {
        id: requestedDateColId,
        header: colIdToNameMap[requestedDateColId],
        cell: (item) => convertDateToISOWithTimeZone(new Date(item.createdOn)),
        sortingField: requestedDateColId,
    },
    {
        id: requestOwnerClientIdColId,
        header: colIdToNameMap[requestOwnerClientIdColId],
        cell: (item) => removePrefix(item.clientId, "Workplace_"),
        sortingField: requestOwnerClientIdColId,
    },
    {
        id: requestedByColId,
        header: colIdToNameMap[requestedByColId],
        cell: (item) => getValidUsername(item.createdBy),
        sortingField: requestedByColId,
    },
    {
        id: requestIdColId,
        header: colIdToNameMap[requestIdColId],
        cell: (item) => {
            return (<TALink
                external
                externalIconAriaLabel="Opens in new tab"
                href={getAutomationRequestDetailPagePath(item.requestId, item.clientId)}
            >
                View request
            </TALink>)
        },
        sortingField: requestIdColId,
    },
    {
        id: automationIdColId,
        header: colIdToNameMap[automationIdColId],
        cell: (item) => {
            return (<TALink
                href={getAutomationDetailPagePath(item.automationId)}
            >
                {item.automationId}
            </TALink>)
        },
        sortingField: automationIdColId,
    },
];

export const DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences = {
    visibleContent: [
        processNameColId,
        automationRequestStatusColId,
        requestedDateColId,
        requestOwnerClientIdColId,
        requestedByColId,
        requestIdColId,
        automationIdColId,
    ],
    pageSize: 10,
};

export const VISIBLE_CONTENT_OPTIONS = [
    {
        label: 'Properties',
        options: [
            { id: requestOwnerClientIdColId, label: colIdToNameMap[requestOwnerClientIdColId] },
            { id: requestedByColId, label: colIdToNameMap[requestedByColId] },
        ],
    },
];

export const PAGE_SIZE_OPTIONS = [
    { value: 10, label: '10 requests' },
    { value: 30, label: '30 requests' },
    { value: 50, label: '50 requests' },
];

export const paginationLabels = {
    nextPageLabel: 'Next page',
    pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
    previousPageLabel: 'Previous page',
};

interface IPreferencesProps {
    preferences: typeof DEFAULT_PREFERENCES;
    setPreferences: (preferences: typeof DEFAULT_PREFERENCES) => void;
    visibleContentOptions?: typeof VISIBLE_CONTENT_OPTIONS;
    pageSizeOptions?: typeof PAGE_SIZE_OPTIONS;
}

export const Preferences = ({
                                preferences,
                                setPreferences,
                                visibleContentOptions = VISIBLE_CONTENT_OPTIONS,
                                pageSizeOptions = PAGE_SIZE_OPTIONS,
                            }: IPreferencesProps) => (
    <CollectionPreferences
        title="Preferences"
        confirmLabel="Confirm"
        cancelLabel="Cancel"
        preferences={preferences}
        onConfirm={({ detail }) => setPreferences(detail)}
        visibleContentPreference={{
            title: 'Select visible columns',
            options: visibleContentOptions,
        }}
        pageSizePreference={{
            title: 'Page size',
            options: pageSizeOptions,
        }}
    />
);
