import axios from 'axios';

import { requestManagerAPIEndpoint } from '../config/endpoints';
import { store } from '../store/store';

interface IListAutomationRequestsForDevTeamRequest {
  pageSize?: number;
  lastEvaluatedKey?: string;
}

export interface IListAutomationRequestsForDevTeamResponse {
  requestList: Array<{
    requestId: string;
    clientId: string;
    processId: string;
    title: string;
    type: string;
    status: string;
    metricsMetadata?: {
      expectedUsersCount: number;
      expectedAnnualSavings: number;
      expectedComplianceSavings?: number;
      desiredGoLiveDate: number;
      businessPriority: string;
      automationFrequency?: {
        times: number;
        period: string;
      };
    };
    automationId?: string;
    automationVersion?: string;
    createdBy: string;
    createdOn: number;
    lastUpdatedBy: string;
    lastUpdatedOn: number;
  }>;
  lastEvaluatedKey?: string;
}

export async function listAutomationRequestsForDevTeam(request: IListAutomationRequestsForDevTeamRequest) {
  const response = await axios.post<IListAutomationRequestsForDevTeamResponse>(
    `${requestManagerAPIEndpoint}/list-automation-requests-for-dev-team`,
    { devTeamClientId: store.getState().authState.selectedAuthProfile.ClientId, ...request },
  );
  return response.data;
}
