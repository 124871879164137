export enum BusinessPriority {
  NONE = 'None',
  LOW = 'Low',
  MEDIUM = 'Medium',
  HIGH = 'High',
}

export enum AutomationFrequencyPeriod {
  /** The automation is running on daily basis */
  DAILY = 'daily',
  /** The automation is running on weekly basis */
  WEEKLY = 'weekly',
  /** The automation is running on monthly basis */
  MONTHLY = 'monthly',
}

export enum TimeUnit {
  MINUTES = '60000',
  HOURS = '3600000',
}

export enum AutomationRequestStatus {
  /** The status of the request when it is created. */
  CREATED = 'CREATED',
  /** The status of the request when it is requested to any of the dev team. */
  REQUESTED = 'REQUESTED',
  /** The status of the request when it is accepted from the dev team. */
  UNDER_DEV = 'UNDER_DEV',
  /** The status of the request when the automation for this request is moved to UAT. */
  UAT = 'UAT',
  /** The status of the request when the automation for this request is moved to PROD. */
  PROD = 'PROD',
}

export enum AutomationRequestType {
  /** New automation request */
  NEW_AUTOMATION = 'NEW_AUTOMATION',
  /** Maintenance request for an existing automation */
  MAINTENANCE = 'MAINTENANCE',
}

export enum DevTeamRequestStatus {
  /** The status of the request when it is pending from the dev team. */
  PENDING = 'PENDING',
  /** The status of the request when it is accepted from the dev team. */
  ACCEPTED = 'ACCEPTED',
  /** The status of the request when it is rejected from the dev team. */
  REJECTED = 'REJECTED',
}
