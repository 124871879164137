import { Container, Header, SpaceBetween } from '@cloudscape-design/components';
import React from 'react';

import { getBlockCategoryListForSelection, getBlockTagsListForSelection } from '../../../../common/utils';
import InputFormField from '../../../../components/polaris/InputFormField';
import MultiselectFormField from '../../../../components/polaris/MultiselectFormField';
import SelectFormField from '../../../../components/polaris/SelectFormField';
import TextareaFormField from '../../../../components/polaris/TextAreaFormField';
import { BLOCK_CATEGORY, BLOCK_NAME, BLOCK_SUMMARY, EMAIL_ADDRESS, TAGS } from '../constants/form-constants';

export default function CreateBlockContainer() {
  return (
    <SpaceBetween direction="vertical" size="xl">
      <Container header={<Header variant="h2">Description</Header>}>
        <SpaceBetween direction="vertical" size="l">
          <InputFormField
            name={BLOCK_NAME}
            label="Name"
            description="Provide a descriptive name for the block."
            ariaRequired={true}
            constraintText="Name must begin with an alphabet and may contain alphabets, numbers, and dot. Avoid consecutive dots. The Name should conclude with an alphabet or number."
          />
          <SelectFormField
            name={BLOCK_CATEGORY}
            label="Category"
            ariaRequired={true}
            placeholder="Select a category"
            options={getBlockCategoryListForSelection()}
            control={null}
          />
          <MultiselectFormField
            name={TAGS}
            label={
              <span>
                Tags <i>- optional</i>{' '}
              </span>
            }
            description="Add tags to help others find the block more easily."
            placeholder="Choose a tag"
            keepOpen={true}
            options={getBlockTagsListForSelection()}
          />
          <TextareaFormField
            name={BLOCK_SUMMARY}
            label={
              <span>
                Summary <i>- optional</i>{' '}
              </span>
            }
            description="Add a brief summary for the block."
            constraintText="Maximum 200 characters"
          />
        </SpaceBetween>
      </Container>
      <Container header={<Header variant="h2">Email notification</Header>}>
        <SpaceBetween direction="vertical" size="l">
          <InputFormField
            name={EMAIL_ADDRESS}
            label="Email address"
            description="The email address to receive notifications regarding updating and publishing of the block."
            ariaRequired={true}
          />
        </SpaceBetween>
      </Container>
    </SpaceBetween>
  );
}
