import { AppLayout, ContentLayout, Flashbar, Spinner } from '@cloudscape-design/components';
import { FlashbarProps } from '@cloudscape-design/components/flashbar';
import React, { SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { MY_BLOCKS } from '../../../common/constants/route-paths';
import { getMyBlockLibraryDetailPagePath } from '../../../common/page-route-path';
import { BlockDetailTabId, LoadingStatus, PageViewType } from '../../../common/types/enums';
import GlobalHelpPanel from '../../../components/GlobalHelpPanel';
import GlobalNavigationPanel from '../../../components/GlobalNavigationPanel';
import BreadcrumbLinkGroup from '../../../components/polaris/BreadcrumbLinkGroup';
import { AuthProfileSelector } from '../../../slices/AuthStateSlice';
import { getCapability, selectGetCapabilityResponse } from '../../../slices/CapabilityDetailSlice';
import { selectIsNavigationPanelOpen, setNavigationPanelOpen } from '../../../slices/SitePreferencesSlice';
import { AppDispatch } from '../../../store/store';
import MyBlockFunctionViewPageContainer from './components/MyBlockFunctionViewPageContainer';
import MyBlockFunctionViewPageContentHeader from './components/MyBlockFunctionViewPageContentHeader';

interface MyBlockFunctionViewPageContentProps {
  setNotifications: React.Dispatch<SetStateAction<FlashbarProps.MessageDefinition[]>>;
  removeNotification: (notificationId: string) => void;
}

function MyBlockFunctionViewPageContent(props: MyBlockFunctionViewPageContentProps) {
  const getCapabilityData = useSelector(selectGetCapabilityResponse);

  return getCapabilityData.data ? (
    <ContentLayout header={<MyBlockFunctionViewPageContentHeader {...props} />}>
      <MyBlockFunctionViewPageContainer />
    </ContentLayout>
  ) : (
    getCapabilityData.status === LoadingStatus.PENDING && (
      <div className="tw-page-loading-spinner">
        <Spinner size="large" />
      </div>
    )
  );
}

export default function MyBlockFunctionViewPage() {
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const isNavigationPanelOpen = useSelector(selectIsNavigationPanelOpen);
  const [notifications, setNotifications] = useState<FlashbarProps.MessageDefinition[]>([]);
  const selectedAuthProfile = useSelector(AuthProfileSelector);
  const currentCapability = useSelector(selectGetCapabilityResponse);

  const removeNotification = (notificationId: string) => {
    const filteredNotification = notifications.filter((notification) => notification.id !== notificationId);
    setNotifications(filteredNotification);
  };

  const navigationChangeHandler = () => {
    dispatch(setNavigationPanelOpen(!isNavigationPanelOpen));
  };

  useEffect(() => {
    void dispatch(
      getCapability({
        blockId: params.blockId,
        capabilityId: params.capabilityId,
        capabilityVersion: 'LATEST',
        clientId: selectedAuthProfile.ClientId,
        pageViewType: PageViewType.SELF_VIEW,
      }),
    );
  }, [selectedAuthProfile]);
  useEffect(() => {
    if (currentCapability.error) {
      setNotifications((prevState) => [
        ...prevState,
        {
          header: 'Failed to load function detail',
          type: 'error',
          content: currentCapability.error,
          dismissible: true,
          dismissLabel: 'Dismiss error',
          onDismiss: () => removeNotification('api-error'),
          id: 'api-error',
        },
      ]);
    }
  }, [currentCapability.error]);

  return (
    <AppLayout
      headerSelector="#tw-header"
      notifications={<Flashbar items={notifications} />}
      stickyNotifications={false}
      content={
        <MyBlockFunctionViewPageContent removeNotification={removeNotification} setNotifications={setNotifications} />
      }
      navigation={<GlobalNavigationPanel />}
      navigationOpen={isNavigationPanelOpen}
      onNavigationChange={navigationChangeHandler}
      tools={<GlobalHelpPanel />}
      breadcrumbs={
        <BreadcrumbLinkGroup
          links={
            currentCapability?.data
              ? [
                  {
                    text: 'My blocks',
                    href: MY_BLOCKS,
                  },
                  {
                    text: currentCapability.data.blockDetail.blockName,
                    href: getMyBlockLibraryDetailPagePath(params.blockId, BlockDetailTabId.FUNCTIONS),
                  },
                  {
                    text: currentCapability.data.capabilityDetail.capabilityName,
                    href: '#',
                  },
                ]
              : [
                  {
                    text: 'My blocks',
                    href: MY_BLOCKS,
                  },
                  {
                    text: '',
                    href: '#',
                  },
                ]
          }
        />
      }
    />
  );
}
