import { Input } from '@cloudscape-design/components';
import React, { FormEventHandler } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { SDK_SEARCH_PAGE } from '../../../../common/constants/route-paths';
import { QUERY_PARAMS } from '../../../../common/constants/string-constants';
import { SEARCH_QUERY_PARAM_KEY } from '../sdk-search.contants';

export default function SearchBar() {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [value, setValue] = React.useState(searchParams.get(SEARCH_QUERY_PARAM_KEY) || '');

  const handleSubmit: FormEventHandler = (event) => {
    event.preventDefault();
    if (value) {
      // Do not change search param if value is empty

      const searchParams = new URLSearchParams(location.search);
      searchParams.set(QUERY_PARAMS.q, value);
      navigate(`${SDK_SEARCH_PAGE}?${searchParams.toString()}`);
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <Input
        ariaRequired={false}
        className="tw-min-vw-20"
        onChange={({ detail }) => setValue(detail.value)}
        value={value}
        placeholder="Search Documentation"
        type="search"
        ariaLabel="Search Documentation"
        inputMode="search"
      />
    </form>
  );
}
