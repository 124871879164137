import { Box, Button, Popover, StatusIndicator } from '@cloudscape-design/components';
import { ButtonProps } from '@cloudscape-design/components/button';
import { StatusIndicatorProps } from '@cloudscape-design/components/status-indicator';
import React, { useState } from 'react';

import { copyToClipboard } from '../common/utils';

const SUCCESS_STATUS = 'success';
const ERROR_STATUS = 'error';

interface ICopyTextProps {
  copyText: string;
  copyButtonLabel: string;
  successText: string;
  errorText: string;
  hideCopyContent?: boolean;
  buttonVariant?: ButtonProps.Variant;
}

export default function CopyText({
  copyText,
  copyButtonLabel,
  successText,
  errorText,
  hideCopyContent,
  buttonVariant,
}: ICopyTextProps) {
  const [status, setStatus] = useState<StatusIndicatorProps.Type>(SUCCESS_STATUS);
  const [message, setMessage] = useState(successText);
  const variant: ButtonProps.Variant = buttonVariant ?? 'inline-icon';
  const copyTextHandler = () => {
    copyToClipboard(copyText).then(
      () => {
        setStatus(SUCCESS_STATUS);
        setMessage(successText);
      },
      () => {
        setStatus(ERROR_STATUS);
        setMessage(errorText);
      },
    );
  };

  return (
    <>
      <Box margin={{ right: 'xxs' }} display="inline-block">
        <Popover
          size="small"
          position="top"
          dismissButton={false}
          triggerType="custom"
          content={
            <StatusIndicator iconAriaLabel={status} type={status}>
              {message}
            </StatusIndicator>
          }
        >
          <Button variant={variant} iconName="copy" ariaLabel={copyButtonLabel} onClick={copyTextHandler} />
        </Popover>
      </Box>
      {hideCopyContent || copyText}
    </>
  );
}
