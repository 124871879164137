import { Button, Container, Grid, SpaceBetween, TextContent } from '@cloudscape-design/components';
import React from 'react';
import { useParams } from 'react-router-dom';

import { getAutomationInstantUIPreviewPath } from '../../../../../common/page-route-path';

export default function AutomationInstantUIPreview() {
  const params = useParams();

  return (
    <Container>
      <Grid gridDefinition={[{ colspan: 4 }, { colspan: 7, offset: 1 }]}>
        <img
          alt="Instant UI Preview"
          src="https://m.media-amazon.com/images/G/01/taskless/workplace/instant-ui-preview-icon.png"
        />
        <TextContent>
          <h3>Instant UI Preview</h3>
          <SpaceBetween size="xs">
            <small>Configure the automation user interface with JSON editor</small>
            <Button href={getAutomationInstantUIPreviewPath(params.automationId)}>Open</Button>
          </SpaceBetween>
        </TextContent>
      </Grid>
    </Container>
  );
}
