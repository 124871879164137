import './AuthProfile.scss';

import { ButtonDropdown } from '@cloudscape-design/components';
import { ButtonDropdownProps } from '@cloudscape-design/components/button-dropdown';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { AuthConstants, QUERY_PARAMS } from '../../common/constants/string-constants';
import AuthProfileData from '../../common/interfaces/AuthProfileData';
import { createAuthProfileDataObject, removePrefix } from '../../common/utils';
import { AuthProfileSelector, setAuthProfile } from '../../slices/AuthStateSlice';

const AuthProfile = () => {
  const selectedAuthProfile: AuthProfileData = useSelector(AuthProfileSelector);
  const location = useLocation();
  const [authProfileItems, setAuthProfileItems] = useState<ButtonDropdownProps.ItemOrGroup[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    void createAuthProfileDataObject().then((authProfileDataObject) => {
      if (authProfileDataObject === null) {
        return;
      }

      const authProfileItems: ButtonDropdownProps.ItemOrGroup[] = authProfileDataObject.map(
        (pairObj: { ClientId: string; Role: string }) => {
          const item: ButtonDropdownProps.ItemOrGroup = {
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            id: pairObj.ClientId + AuthConstants.ClientIdAndRoleSeparationUIDelimiter + pairObj.Role,
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            text: removePrefix(
              pairObj.ClientId + AuthConstants.ClientIdAndRoleSeparationUIDelimiter + pairObj.Role,
              AuthConstants.WorkplaceClientIdPrefix,
            ),
          };
          return item;
        },
      );

      // if query searchParams are present
      const searchParams = new URLSearchParams(location.search);
      const paramsClientId = searchParams.get(QUERY_PARAMS.clientId);
      const paramsRole =
        searchParams.get(QUERY_PARAMS.role) ||
        // If search params does not contain role then obtain the role of the first matching auth object having same
        // client id.
        authProfileDataObject.find((pairObj) => pairObj.ClientId === paramsClientId)?.Role;
      if (
        paramsClientId &&
        paramsRole &&
        authProfileDataObject.find(
          (pairObj: { ClientId: string; Role: string }) =>
            pairObj.ClientId === paramsClientId && pairObj.Role === paramsRole,
        )
      ) {
        const authProfile: AuthProfileData = {
          ClientId: paramsClientId,
          Role: paramsRole,
        };
        dispatch(setAuthProfile(authProfile));
      } else if (selectedAuthProfile.ClientId === '' && selectedAuthProfile.Role === '') {
        // select first available client and role pair
        const authProfile: AuthProfileData = {
          ClientId: authProfileDataObject[0].ClientId,
          Role: authProfileDataObject[0].Role,
        };
        dispatch(setAuthProfile(authProfile));
      }

      setAuthProfileItems(authProfileItems);
    });
  }, []);

  const onValueChange = (itemId: string) => {
    const clientIdAndRolePair = itemId.split(AuthConstants.ClientIdAndRoleSeparationUIDelimiter);
    const authProfile: AuthProfileData = {
      ClientId: clientIdAndRolePair[0],
      Role: clientIdAndRolePair[1],
    };
    dispatch(setAuthProfile(authProfile));
  };

  if (authProfileItems.length === 0) {
    return null;
  }

  return (
    <ButtonDropdown
      className={'tw-auth-profile-dropdown'}
      items={authProfileItems}
      onItemClick={({ detail }) => onValueChange(detail.id)}
    >
      {`${removePrefix(selectedAuthProfile.ClientId, AuthConstants.WorkplaceClientIdPrefix)}${
        AuthConstants.ClientIdAndRoleSeparationUIDelimiter
      }${selectedAuthProfile.Role}`}
    </ButtonDropdown>
  );
};

export default AuthProfile;
