import { SideNavigation } from '@cloudscape-design/components';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

import { AUTOMATION_REQUESTS, BLOCKS, HOME, MY_BLOCKS } from '../common/constants/route-paths';
import { devDocumentationUrl, sdkDocumentationEndpoint, slackChannelLink } from '../config/endpoints';

function GlobalNavigationPanel() {
  const location = useLocation();
  return (
    <SideNavigation
      header={{ text: 'Developer Console', href: HOME }}
      items={[
        { type: 'link', text: 'Home', href: HOME },
        { type: 'link', text: 'Request management', href: AUTOMATION_REQUESTS },
        { type: 'link', text: 'Tutorials', href: devDocumentationUrl, external: true },
        { type: 'link', text: 'Slack Channel', href: slackChannelLink, external: true },
        { type: 'link', text: 'Documentation', href: sdkDocumentationEndpoint, external: true },
        { type: 'divider' },
        { type: 'link', text: 'Blocks', href: BLOCKS },
        { type: 'link', text: 'My blocks', href: MY_BLOCKS },
      ]}
      activeHref={location.pathname}
    />
  );
}

export default GlobalNavigationPanel;
