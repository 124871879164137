import { ColumnLayout, Container, Header } from '@cloudscape-design/components';
import { useSelector } from 'react-redux';

import { WindowsEC2SourceTypeDisplayNameMap } from '../../../../common/constants/automation-constants';
import BoxElement from '../../../../components/polaris/BoxElement';
import { selectGetAutomationDetailResponse } from '../../../../slices/AutomationDetailSlice';

export default function AutomationExecutionEnvironmentInfoContainer() {
  const currentAutomation = useSelector(selectGetAutomationDetailResponse);
  if (!currentAutomation?.data) {
    return;
  }
  const { executionEnvironmentDetails } = currentAutomation.data.automationDetail;

  return (
    <Container header={<Header variant="h2">Execution environment information</Header>}>
      <ColumnLayout borders="vertical" columns={3}>
        <BoxElement
          headerText="Resource type"
          description={
            executionEnvironmentDetails?.resourceType
              ? WindowsEC2SourceTypeDisplayNameMap[executionEnvironmentDetails.resourceType]
              : '-'
          }
        />
        <BoxElement headerText="AWS account ID" description={executionEnvironmentDetails?.awsAccountId ?? '-'} />
        <BoxElement
          headerText={
            executionEnvironmentDetails?.resourceType
              ? `${String(WindowsEC2SourceTypeDisplayNameMap[executionEnvironmentDetails.resourceType])} ID`
              : 'Resource ID'
          }
          description={executionEnvironmentDetails?.resourceId ?? '-'}
        />
        <BoxElement headerText="Region" description={executionEnvironmentDetails?.region ?? '-'} />
        <BoxElement headerText="Required memory (in GB)" description={executionEnvironmentDetails?.memory ?? '-'} />
        <BoxElement headerText="Required storage (in GB)" description={executionEnvironmentDetails?.storage ?? '-'} />
        <BoxElement
          headerText="Supported concurrent executions"
          description={executionEnvironmentDetails?.supportedConcurrentExecutions ?? '-'}
        />
      </ColumnLayout>
    </Container>
  );
}
