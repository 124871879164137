import {CollectionPreferences, CollectionPreferencesProps, TableProps} from '@cloudscape-design/components';

import TALink from '../../../../components/polaris/TALink';
import {getMyBlockLibraryDetailPagePath, getMyBlockServiceDetailPagePath} from '../../../../common/page-route-path';
import CopyText from '../../../../components/CopyText';
import React from 'react';
import {
  blockCategoryColId,
  blockIdColId,
  blockNameColId,
  blockStatusColId,
  colIdToNameMap,
  createdByColId,
  createdOnColId,
  lastUpdatedByColId,
} from './block-column-constants';
import {IListBlocksForClientData} from '../../../../common/types/interfaces';
import {BlockType} from "../../../../common/types/enums";

export const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<IListBlocksForClientData>[] = [
  {
    id: blockNameColId,
    header: colIdToNameMap[blockNameColId],
    cell: (item) => {
      return item.blockType === BlockType.LIBRARY ? (
          <TALink href={getMyBlockLibraryDetailPagePath(item.blockId)}>{item.blockName}</TALink>
      ) : (
          <TALink href={getMyBlockServiceDetailPagePath(item.blockId)}>{item.blockName}</TALink>
      )
    },
    sortingField: blockNameColId,
  },
  {
    id: blockIdColId,
    header: colIdToNameMap[blockIdColId],
    cell: (item) => (
      <CopyText
        copyText={item.blockId}
        copyButtonLabel="Copy block ID"
        successText="Block ID copied"
        errorText="Failed to copy"
      />
    ),
  },
  {
    id: blockCategoryColId,
    header: colIdToNameMap[blockCategoryColId],
    cell: (item) => item.blockCategory,
    sortingField: blockCategoryColId,
  },
  {
    id: blockStatusColId,
    header: colIdToNameMap[blockStatusColId],
    cell: (item) => item.blockStatus,
    sortingField: blockStatusColId,
  },
  {
    id: createdByColId,
    header: colIdToNameMap[createdByColId],
    cell: (item) => item.createdBy,
    sortingField: createdByColId,
  },
  {
    id: lastUpdatedByColId,
    header: colIdToNameMap[lastUpdatedByColId],
    cell: (item) => item.updatedBy,
    sortingField: lastUpdatedByColId,
  },
  {
    id: createdOnColId,
    header: colIdToNameMap[createdOnColId],
    cell: (item) => new Date(item.createdAt).toISOString().split('T')[0],
    sortingField: createdOnColId,
  },
];

export const DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences = {
  visibleContent: [
    blockIdColId,
    blockNameColId,
    blockCategoryColId,
    blockStatusColId,
    createdOnColId,
    createdByColId,
    lastUpdatedByColId,
  ],
  pageSize: 10,
};

export const VISIBLE_CONTENT_OPTIONS = [
  {
    label: 'Properties',
    options: [
      { id: blockCategoryColId, label: colIdToNameMap[blockCategoryColId] },
      { id: blockStatusColId, label: colIdToNameMap[blockStatusColId] },
      { id: createdByColId, label: colIdToNameMap[createdByColId] },
      { id: lastUpdatedByColId, label: colIdToNameMap[lastUpdatedByColId] },
      { id: createdOnColId, label: colIdToNameMap[createdOnColId] },
    ],
  },
];

export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: '10 blocks' },
  { value: 30, label: '30 blocks' },
  { value: 50, label: '50 blocks' },
];

export const paginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page',
};

interface IPreferencesProps {
  preferences: typeof DEFAULT_PREFERENCES;
  setPreferences: (preferences: typeof DEFAULT_PREFERENCES) => void;
  visibleContentOptions?: typeof VISIBLE_CONTENT_OPTIONS;
  pageSizeOptions?: typeof PAGE_SIZE_OPTIONS;
}

export const Preferences = ({
  preferences,
  setPreferences,
  visibleContentOptions = VISIBLE_CONTENT_OPTIONS,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
}: IPreferencesProps) => (
  <CollectionPreferences
    title="Preferences"
    confirmLabel="Confirm"
    cancelLabel="Cancel"
    preferences={preferences}
    onConfirm={({ detail }) => setPreferences(detail)}
    visibleContentPreference={{
      title: 'Select visible columns',
      options: visibleContentOptions,
    }}
    pageSizePreference={{
      title: 'Page size',
      options: pageSizeOptions,
    }}
  />
);
