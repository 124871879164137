import { ColumnLayout, Container, Header } from '@cloudscape-design/components';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { convertDateToISOWithTimeZone, getCodePipelineURL } from '../../../../common/utils';
import CopyText from '../../../../components/CopyText';
import BoxElement from '../../../../components/polaris/BoxElement';
import TALink from '../../../../components/polaris/TALink';
import { selectGetBlockPipelineResponse } from '../../../../slices/BlockDetailSlice';

export default function PipelineBasicInfoContainer() {
  const params = useParams();
  const getBlockPipelineData = useSelector(selectGetBlockPipelineResponse);
  const { pipelineInfo } = getBlockPipelineData.data;

  return (
    <Container header={<Header variant="h2">Basic Information</Header>}>
      <ColumnLayout borders="vertical" columns={3}>
        <BoxElement
          headerText="Block ID"
          description={
            params.blockId ? (
              <CopyText
                copyText={params.blockId}
                copyButtonLabel="Block ID"
                successText="Block ID copied"
                errorText="Failed to copy"
              />
            ) : (
              '-'
            )
          }
        />
        <BoxElement
          headerText="Code pipeline"
          description={
            pipelineInfo?.codePipelineName ? (
              <TALink
                external
                externalIconAriaLabel="Opens in new tab"
                href={getCodePipelineURL(pipelineInfo.codePipelineName, pipelineInfo.region)}
              >
                {pipelineInfo.codePipelineName}
              </TALink>
            ) : (
              '-'
            )
          }
        />
        <BoxElement
          headerText="Code repository"
          description={
            pipelineInfo.codeRepositoryName ? (
              <CopyText
                copyText={pipelineInfo.codeRepositoryName}
                copyButtonLabel="Copy repository name"
                successText="Repository name copied"
                errorText="Failed to copy"
              />
            ) : (
              '-'
            )
          }
        />
        <BoxElement
          headerText="SDK assume role ARN"
          description={
            pipelineInfo.sdkAccessRoleArn ? (
              <CopyText
                copyText={pipelineInfo.sdkAccessRoleArn}
                copyButtonLabel="SDK assume role ARN"
                successText="SDK assume role ARN copied"
                errorText="Failed to copy"
              />
            ) : (
              '-'
            )
          }
        />
        <BoxElement
          headerText="AWS account ID"
          description={
            pipelineInfo.awsAccountId ? (
              <CopyText
                copyText={pipelineInfo.awsAccountId}
                copyButtonLabel="AWS account ID"
                successText="AWS account ID copied"
                errorText="Failed to copy"
              />
            ) : (
              '-'
            )
          }
        />
        <BoxElement headerText="Created by" description={pipelineInfo.createdBy} />
        <BoxElement
          headerText="Created on"
          description={convertDateToISOWithTimeZone(new Date(pipelineInfo.createdAt))}
        />
        <BoxElement headerText="Updated by" description={pipelineInfo.updatedBy} />
      </ColumnLayout>
    </Container>
  );
}
