import { CardsProps, ExpandableSection } from '@cloudscape-design/components';
import { SDKDocSearchResult } from '../../../../common/types/interfaces';
import { SpaceBetween } from '@cloudscape-design/components';
import { SDKDocElement, SDKDocResultHeader, SDKExample, SDKInputArgs, SDKOutput } from '../components/SDKDocComponents';

export const CARD_DEFINITIONS: CardsProps.CardDefinition<SDKDocSearchResult> = {
  header: (item) => (
    <ExpandableSection
      defaultExpanded={item.isTopResult}
      variant="container"
      header={<SDKDocResultHeader sdkDocItem={item} />}
    >
      <SpaceBetween size="m">
        <SDKDocElement header="Description" value={item.description} />
        <SDKInputArgs sdkDocItem={item} />
        <SDKOutput sdkDocItem={item} />
        <SDKExample sdkDocItem={item} />
      </SpaceBetween>
    </ExpandableSection>
  ),
};
