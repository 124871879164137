import { Badge, Box, Cards, SpaceBetween } from '@cloudscape-design/components';
import React from 'react';

import { getBlockDetailPagePath } from '../../../../common/page-route-path';
import { BlockDetailTabId, LoadingStatus } from '../../../../common/types/enums';
import { IListBlocksData } from '../../../../common/types/interfaces';
import BoxElement from '../../../../components/polaris/BoxElement';
import TALink from '../../../../components/polaris/TALink';

export interface IBlocksPageCardsProps {
  blockList: IListBlocksData[];
  listBlocksLoadingStatus: LoadingStatus;
  headerName: string;
}

export default function BlocksPageCards(props: IBlocksPageCardsProps) {
  return (
    <Cards
      cardDefinition={{
        header: (item) => {
          return (
            <TALink href={getBlockDetailPagePath(item.blockId, BlockDetailTabId.EXECUTABLES)}>{item.blockName}</TALink>
          );
        },
        sections: [
          {
            header: '',
            content: (item) => (
              <SpaceBetween direction="vertical" size="xxs">
                <b>{item.blockCategory}</b>
                <BoxElement headerText="Published by" description={item.clientId} />
                <Box variant="p">{item.blockSummary}</Box>
                <SpaceBetween direction="horizontal" size="xxs">
                  {/* NOTE: slice the tags to 3 as we have to show max 3 tags and remaining we will be showing the count of remaining tags */}
                  {item.tags.slice(0, 3).map((tag) => (
                    <Badge key={tag}>{tag}</Badge>
                  ))}
                  {item.tags.length > 3 ? <Box variant="awsui-key-label">and {item.tags.length - 3} more</Box> : ''}
                </SpaceBetween>
              </SpaceBetween>
            ),
          },
        ],
      }}
      cardsPerRow={[{ cards: 3 }]}
      items={props.blockList}
      loadingText="Loading blocks"
      loading={props.listBlocksLoadingStatus === LoadingStatus.PENDING}
      empty={'No blocks exists.'}
    />
  );
}
