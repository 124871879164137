import { BreadcrumbGroup } from '@cloudscape-design/components';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function BreadcrumbLinkGroup({ links }: { links: Array<{ text: string; href: string }> }) {
  const navigate = useNavigate();

  function handleClick(event: any) {
    // if the link is not starting with the / then do the normal flow as this are external link
    // otherwise handle the links with react router dom.
    if (event.detail.href.startsWith('/')) {
      event.preventDefault();
      navigate(event.detail.href);
    }
  }

  return <BreadcrumbGroup onFollow={handleClick} items={links} ariaLabel="Breadcrumbs" />;
}
