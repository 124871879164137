const sdkDocumentationEndpoint = 'https://sdk-taskless-rpa.tax.amazon.dev/latest/api/index.html'; // We do not have beta endpoint for this
export const devDocumentationUrl = 'https://sdk-taskless-rpa.tax.amazon.dev/latest/index.html';
export const onboardingAccountDocUrl =
  'https://sdk-taskless-rpa.tax.amazon.dev/latest/articles/getting-started/onboarding-aws-account.html';
export const slackChannelLink =
  'https://amzn-fgbs.slack.com/archives/C05FF8V6V5W';
let automationRegistryApiEndpoint: string;
let infraManagerApiEndpoint: string;
let teamRegistryApiEndpoint: string;
let sdkSearchApiEndpoint: string;
let blockRegistryApiEndpoint: string;
let requestManagerAPIEndpoint: string
let workplaceKwPortalEndpoint: string;

if (process.env.REACT_APP_ENV === 'beta') {
  automationRegistryApiEndpoint = process.env.REACT_APP_AUTOMATION_REGISTRY_API_BETA_ENDPOINT;
  infraManagerApiEndpoint = process.env.REACT_APP_INFRA_MANAGER_API_BETA_ENDPOINT;
  teamRegistryApiEndpoint = process.env.REACT_APP_TEAM_REGISTRY_API_BETA_ENDPOINT;
  sdkSearchApiEndpoint = process.env.REACT_APP_SDK_SEARCH_API_BETA_ENDPOINT;
  blockRegistryApiEndpoint = process.env.REACT_APP_BLOCK_REGISTRY_API_BETA_ENDPOINT;
  requestManagerAPIEndpoint = process.env.REACT_APP_REQUEST_MANAGER_API_BETA_ENDPOINT;
  workplaceKwPortalEndpoint = process.env.REACT_APP_WORKPLACE_KW_MFE_WEBSITE_BETA_ENDPOINT;
} else if (process.env.REACT_APP_ENV === 'prod') {
  automationRegistryApiEndpoint = process.env.REACT_APP_AUTOMATION_REGISTRY_API_PROD_ENDPOINT;
  infraManagerApiEndpoint = process.env.REACT_APP_INFRA_MANAGER_API_PROD_ENDPOINT;
  teamRegistryApiEndpoint = process.env.REACT_APP_TEAM_REGISTRY_API_PROD_ENDPOINT;
  sdkSearchApiEndpoint = process.env.REACT_APP_SDK_SEARCH_API_PROD_ENDPOINT;
  blockRegistryApiEndpoint = process.env.REACT_APP_BLOCK_REGISTRY_API_PROD_ENDPOINT;
  requestManagerAPIEndpoint = process.env.REACT_APP_REQUEST_MANAGER_API_PROD_ENDPOINT;
  workplaceKwPortalEndpoint = process.env.REACT_APP_WORKPLACE_KW_MFE_WEBSITE_PROD_ENDPOINT;
} else {
  automationRegistryApiEndpoint = process.env.REACT_APP_AUTOMATION_REGISTRY_API_BETA_ENDPOINT;
  infraManagerApiEndpoint = process.env.REACT_APP_INFRA_MANAGER_API_BETA_ENDPOINT;
  teamRegistryApiEndpoint = process.env.REACT_APP_TEAM_REGISTRY_API_BETA_ENDPOINT;
  sdkSearchApiEndpoint = process.env.REACT_APP_SDK_SEARCH_API_BETA_ENDPOINT;
  blockRegistryApiEndpoint = process.env.REACT_APP_BLOCK_REGISTRY_API_BETA_ENDPOINT;
  requestManagerAPIEndpoint = process.env.REACT_APP_REQUEST_MANAGER_API_BETA_ENDPOINT;
  workplaceKwPortalEndpoint = process.env.REACT_APP_WORKPLACE_KW_MFE_WEBSITE_BETA_ENDPOINT;
}

export {
  sdkDocumentationEndpoint,
  infraManagerApiEndpoint,
  automationRegistryApiEndpoint,
  teamRegistryApiEndpoint,
  sdkSearchApiEndpoint,
  blockRegistryApiEndpoint,
  requestManagerAPIEndpoint,
  workplaceKwPortalEndpoint,
};
