import { Container, Header, Icon, SpaceBetween } from '@cloudscape-design/components';
import { DropdownStatusProps } from '@cloudscape-design/components/internal/components/dropdown-status/interfaces';
import { SelectProps } from '@cloudscape-design/components/select';
import React, { ReactElement, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import {
  AutomationTypeDisplayNameMap,
  AutomationTypeToExecutionEnvironmentMap,
  ExecutionEnvironmentDisplayNameMap,
} from '../../../../common/constants/automation-constants';
import {
  AUTO_DEV_AUTOMATION_PREVIEW_TICKET_TEMPLATE_URL,
  CLOUD_AUTOMATION_ONBOARDING_TICKET_TEMPLATE_URL,
} from '../../../../common/constants/string-constants';
import { AutomationRequestStatus, AutomationRequestType } from '../../../../common/enums/automation-request-enums';
import IAutomationRequestDetails from '../../../../common/interfaces/AutomationRequestDetails';
import { getCreateProcessPagePath } from '../../../../common/page-route-path';
import { AutomationType, ExecutionEnvironment, LoadingStatus } from '../../../../common/types/enums';
import { CheckboxFormField } from '../../../../components/polaris/CheckboxFormField';
import InputFormField from '../../../../components/polaris/InputFormField';
import SelectFormField from '../../../../components/polaris/SelectFormField';
import TALink from '../../../../components/polaris/TALink';
import { selectListAutomationRequestsResponse } from '../../../../slices/AutomationRequestSlice';
import { AutomationDetailSchemaType } from '../config/automation-form.config';
import {
  AUTOMATION_NAME,
  AUTOMATION_REQUEST,
  AUTOMATION_TYPE,
  EMAIL_ADDRESS,
  EXECUTION_ENVIRONMENT,
  IS_WINDOWS_APPLICATION_AUTOMATION,
} from '../constants/form-constants';

function getNewAutomationRequestOptions(requestList: IAutomationRequestDetails[]): SelectProps.Options {
  return requestList
    .filter(
      (requestDetail) =>
        requestDetail.type === AutomationRequestType.NEW_AUTOMATION &&
        requestDetail.status === AutomationRequestStatus.REQUESTED,
    )
    .map((requestDetail): SelectProps.Option => {
      return {
        label: requestDetail.title,
        value: requestDetail.requestId,
      };
    });
}

function getAutomationTypeOptions(): SelectProps.Option[] {
  return Object.values(AutomationType).map((automationType) => ({
    label: AutomationTypeDisplayNameMap[automationType],
    value: automationType,
  }));
}

function getExecutionEnvironmentOptions(selectedAutomationType: AutomationType): SelectProps.Option[] {
  return Object.values(AutomationTypeToExecutionEnvironmentMap[selectedAutomationType]).map((executionEnvironment) => ({
    label: ExecutionEnvironmentDisplayNameMap[executionEnvironment],
    value: executionEnvironment,
  }));
}

function getAutomationTypeConstraintText(automationType: AutomationType): ReactElement {
  switch (automationType) {
    case AutomationType.AUTO_DEV:
      return (
        <>
          <Icon name="status-warning" variant="warning" /> AutoDev is our new low-code automation development product.
          If you’d like to participate in the preview, please fill out{' '}
          <TALink external={true} href={AUTO_DEV_AUTOMATION_PREVIEW_TICKET_TEMPLATE_URL}>
            this
          </TALink>{' '}
          form
        </>
      );
    case AutomationType.CLOUD:
      return (
        <>
          <Icon name="status-info" variant="warning" /> As of now we support only registration of cloud automations,
          after creating the automation please reach out to product team for registering other attributes by creating a
          ticket{' '}
          <TALink external={true} href={CLOUD_AUTOMATION_ONBOARDING_TICKET_TEMPLATE_URL}>
            here
          </TALink>
        </>
      );
    default:
      return <></>;
  }
}

export default function CreateAutomationContainer() {
  const listAutomationRequestsData = useSelector(selectListAutomationRequestsResponse);
  const { setValue } = useFormContext<AutomationDetailSchemaType>();
  const [selectedAutomationType, setSelectedAutomationType] = useState<SelectProps.Option>({
    label: AutomationTypeDisplayNameMap[AutomationType.WINDOWS_ASSISTANT],
    value: AutomationType.WINDOWS_ASSISTANT,
  });
  const [selectedAutomationTypeConstraintText, setSelectedAutomationTypeConstraintText] = useState<ReactElement>();
  const [selectedExecutionEnvironment, setSelectedExecutionEnvironment] = useState<SelectProps.Option>({
    label: ExecutionEnvironmentDisplayNameMap[ExecutionEnvironment.WINDOWS_DESKTOP],
    value: ExecutionEnvironment.WINDOWS_DESKTOP,
  });

  function getAutomationRequestDropdownStatusType(): DropdownStatusProps.StatusType {
    if (listAutomationRequestsData.status === LoadingStatus.PENDING) {
      return 'loading';
    } else if (listAutomationRequestsData.status === LoadingStatus.SUCCESS) {
      return 'finished';
    }
    return 'error';
  }

  return (
    <SpaceBetween direction="vertical" size="xl">
      <Container header={<Header variant="h2">Basic details</Header>}>
        <SpaceBetween direction="vertical" size="l">
          <SelectFormField
            name={AUTOMATION_REQUEST}
            label="Automation request"
            description={
              <>
                The request you’re creating the automation for. If you don’t have an existing request, create one
                through{' '}
                <TALink external={true} href={getCreateProcessPagePath()}>
                  discover process
                </TALink>
              </>
            }
            control={null}
            ariaRequired={true}
            placeholder="Select a automation request"
            options={getNewAutomationRequestOptions(listAutomationRequestsData.data)}
            loadingText="Loading automation requests"
            statusType={getAutomationRequestDropdownStatusType()}
            recoveryText="Unable to fetch automation requests. Please try again."
            empty="No automation requests"
            errorText="Unable to fetch automation requests. Please try again."
          />
          <InputFormField
            name={AUTOMATION_NAME}
            label="Automation name"
            description="A descriptive name which will be visible to your team members"
            ariaRequired={true}
          />
          <SelectFormField
            name={AUTOMATION_TYPE}
            label="Executable type"
            description="Type of executable you want to create for automation"
            control={null}
            ariaRequired={true}
            placeholder="Select an executable type"
            options={getAutomationTypeOptions()}
            defaultValue={AutomationType.WINDOWS_ASSISTANT}
            selectedOption={selectedAutomationType}
            onChange={(event) => {
              const automationType = event.detail.selectedOption.value as AutomationType;
              setValue(AUTOMATION_TYPE, automationType);
              setSelectedAutomationType(event.detail.selectedOption);
              setSelectedAutomationTypeConstraintText(getAutomationTypeConstraintText(automationType));
              setSelectedExecutionEnvironment(getExecutionEnvironmentOptions(automationType)[0]);
            }}
            constraintText={selectedAutomationTypeConstraintText}
            empty="No automation types available"
          />
          <SelectFormField
            name={EXECUTION_ENVIRONMENT}
            label="Execution environment"
            description="Environment where the automation will execute"
            control={null}
            ariaRequired={true}
            placeholder="Select an execution environment"
            options={getExecutionEnvironmentOptions(selectedAutomationType.value as AutomationType)}
            defaultValue={selectedExecutionEnvironment.value}
            selectedOption={selectedExecutionEnvironment}
            onChange={(event) => {
              const executionEnvironment = event.detail.selectedOption.value as ExecutionEnvironment;
              setValue(EXECUTION_ENVIRONMENT, executionEnvironment);
              setSelectedExecutionEnvironment(event.detail.selectedOption);
            }}
            empty="No execution environment available"
          />
          {selectedExecutionEnvironment.value === ExecutionEnvironment.WINDOWS_EC2 && (
            <CheckboxFormField
              name={IS_WINDOWS_APPLICATION_AUTOMATION}
              label="Is windows application automation"
              control={null}
              ariaRequired={true}
              checkboxDescription="Flag to signify if the automation includes windows desktop applications?"
            />
          )}
        </SpaceBetween>
      </Container>
      <Container header={<Header variant="h2">Notification</Header>}>
        <SpaceBetween direction="vertical" size="l">
          <InputFormField
            name={EMAIL_ADDRESS}
            label="Email address"
            description="The email address (use team email address instead of individual email address) to receive notifications regarding the automation, including pipeline readiness and build statuses."
            ariaRequired={true}
          />
        </SpaceBetween>
      </Container>
    </SpaceBetween>
  );
}
