import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ISearchWithIntentResponse, searchWithIntent } from '../api/sdk-search-api';
import { LoadingStatus } from '../common/types/enums';
import { SDKDocSearchResult } from '../common/types/interfaces';
import { RootState } from '../store/store';

interface ISDKSearchState {
  searchSDKDocWithIntentResults: {
    data: SDKDocSearchResult[];
    status: LoadingStatus;
    error?: string;
  };
}

const initialState: ISDKSearchState = {
  searchSDKDocWithIntentResults: { data: [], status: LoadingStatus.IDLE },
};

function transformApiResponseToSDKDocSearchResult(apiResponse: ISearchWithIntentResponse) {
  // Sort by similarity score in descending order
  apiResponse.matches.sort((a, b) => b.similarityScore - a.similarityScore);
  const convertedResponse = apiResponse.matches.map((matchResult) => matchResult.result as SDKDocSearchResult);
  if (convertedResponse[0]) {
    convertedResponse[0].isTopResult = true;
  }
  return convertedResponse;
}

export const searchSDKDocWithIntent = createAsyncThunk(
  'sdkSearch/searchWithIntent',
  async (searchText: string): Promise<SDKDocSearchResult[]> => {
    const sdkSearchResponse = await searchWithIntent({ searchText });
    return transformApiResponseToSDKDocSearchResult(sdkSearchResponse);
  },
);
const SDKSearchSlice = createSlice({
  name: 'sdkSearch',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(searchSDKDocWithIntent.pending, (state) => {
      state.searchSDKDocWithIntentResults.status = LoadingStatus.PENDING;
      state.searchSDKDocWithIntentResults.error = '';
      state.searchSDKDocWithIntentResults.data = [];
    });
    builder.addCase(searchSDKDocWithIntent.fulfilled, (state, action) => {
      state.searchSDKDocWithIntentResults.status = LoadingStatus.SUCCESS;
      state.searchSDKDocWithIntentResults.error = '';
      state.searchSDKDocWithIntentResults.data = action.payload;
    });
    builder.addCase(searchSDKDocWithIntent.rejected, (state) => {
      state.searchSDKDocWithIntentResults.status = LoadingStatus.FAILED;
      state.searchSDKDocWithIntentResults.error = 'Something went wrong while performing the search.';
      state.searchSDKDocWithIntentResults.data = [];
    });
  },
});

export const selectSearchSDKDocWithIntentResults = (state: RootState) => state.sdkSearch.searchSDKDocWithIntentResults;
export default SDKSearchSlice.reducer;
