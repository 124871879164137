import { Cards, Header } from '@cloudscape-design/components';
import { useSelector } from 'react-redux';

import { LoadingStatus } from '../../../../common/types/enums';
import { selectSearchSDKDocWithIntentResults } from '../../../../slices/SDKSearchSlice';
import { CARD_DEFINITIONS } from '../config/search-results-cards.config';

export default function SDKSearchResults() {
  const sdkSearchResults = useSelector(selectSearchSDKDocWithIntentResults);
  if (sdkSearchResults.status !== LoadingStatus.IDLE) {
    return (
      <Cards
        className="tw-zero-padded-cards"
        items={sdkSearchResults.data}
        cardDefinition={CARD_DEFINITIONS}
        cardsPerRow={[{ cards: 1 }]}
        loading={sdkSearchResults.status === LoadingStatus.PENDING}
        loadingText="Fetching search results"
        header={<Header counter={`(${sdkSearchResults.data.length})`}>Results</Header>}
      />
    );
  } else {
    return null;
  }
}
