import { AppLayout, ContentLayout, Flashbar, Grid, SpaceBetween, Spinner } from '@cloudscape-design/components';
import { FlashbarProps } from '@cloudscape-design/components/flashbar';
import React, { SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { MY_BLOCKS } from '../../../common/constants/route-paths';
import { LoadingStatus, PageViewType } from '../../../common/types/enums';
import { checkIfClientIdAndRoleMissing } from '../../../common/utils';
import GlobalHelpPanel from '../../../components/GlobalHelpPanel';
import GlobalNavigationPanel from '../../../components/GlobalNavigationPanel';
import BreadcrumbLinkGroup from '../../../components/polaris/BreadcrumbLinkGroup';
import { AuthProfileSelector } from '../../../slices/AuthStateSlice';
import {
  getBlock,
  selectCreateBlockForClient,
  selectCreateBlockPipelineResponse,
  selectGetBlockResponse,
} from '../../../slices/BlockDetailSlice';
import { listCapabilitiesForBlock, selectUpdateCapabilityResponse } from '../../../slices/CapabilityDetailSlice';
import { selectIsNavigationPanelOpen, setNavigationPanelOpen } from '../../../slices/SitePreferencesSlice';
import { AppDispatch } from '../../../store/store';
import MyBlockAddnInfoContainer from './components/MyBlockAddnInfoContainer';
import MyBlockDetailContainer from './components/MyBlockDetailContainer';
import MyBlockDetailPageContentHeader from './components/MyBlockDetailContentHeader';
import MyBlockPublishAlert from './components/MyBlockPublishAlert';

interface MyBlockDetailPageContentProps {
  setNotifications: React.Dispatch<SetStateAction<FlashbarProps.MessageDefinition[]>>;
  removeNotification: (notificationId: string) => void;
}

function MyBlockDetailPageContent(props: MyBlockDetailPageContentProps) {
  const currentBlock = useSelector(selectGetBlockResponse);

  return currentBlock.data ? (
    <ContentLayout header={<MyBlockDetailPageContentHeader {...props} />}>
      <SpaceBetween size="xl">
        <MyBlockPublishAlert />
        <Grid gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}>
          <MyBlockDetailContainer />
          <MyBlockAddnInfoContainer />
        </Grid>
      </SpaceBetween>
    </ContentLayout>
  ) : (
    currentBlock.status === LoadingStatus.PENDING && (
      <div className="tw-page-loading-spinner">
        <Spinner size="large" />
      </div>
    )
  );
}

export default function MyBlockDetailPage() {
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const isNavigationPanelOpen = useSelector(selectIsNavigationPanelOpen);
  const [notifications, setNotifications] = useState<FlashbarProps.MessageDefinition[]>([]);
  const selectedAuthProfile = useSelector(AuthProfileSelector);
  const currentBlock = useSelector(selectGetBlockResponse);
  const createBlockForClientResult = useSelector(selectCreateBlockForClient);
  const createBlockPipelineResult = useSelector(selectCreateBlockPipelineResponse);
  const updateCapabilityResult = useSelector(selectUpdateCapabilityResponse);

  const removeNotification = (notificationId: string) => {
    const filteredNotification = notifications.filter((notification) => notification.id !== notificationId);
    setNotifications(filteredNotification);
  };

  const navigationChangeHandler = () => {
    dispatch(setNavigationPanelOpen(!isNavigationPanelOpen));
  };

  useEffect(() => {
    if (checkIfClientIdAndRoleMissing(selectedAuthProfile)) {
      return;
    }

    void dispatch(
      getBlock({
        blockId: params.blockId,
        clientId: selectedAuthProfile.ClientId,
        pageViewType: PageViewType.SELF_VIEW,
      }),
    );
  }, [selectedAuthProfile]);

  useEffect(() => {
    if (createBlockForClientResult.status === LoadingStatus.SUCCESS && currentBlock.data) {
      setNotifications((prevState) => [
        ...prevState,
        {
          header: 'You’ve successfully created ' + currentBlock.data.blockName + ' block.',
          type: 'success',
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => removeNotification('api-success'),
          id: 'api-success',
        },
      ]);
    }
  }, [createBlockForClientResult.status, currentBlock.data]);

  useEffect(() => {
    if (createBlockPipelineResult.status === LoadingStatus.SUCCESS && currentBlock.data) {
      setNotifications((prevState) => [
        ...prevState,
        {
          header: 'You’ve successfully created pipeline for ' + currentBlock.data.blockName + ' block.',
          content: 'Information of code pipeline and code repository will be available within 5-10 minutes.',
          type: 'success',
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => removeNotification('api-success'),
          id: 'api-success',
        },
      ]);
    }
  }, [createBlockPipelineResult.status, currentBlock.data]);

  useEffect(() => {
    if (currentBlock.error) {
      setNotifications((prevState) => [
        ...prevState,
        {
          header: 'Failed to load block detail',
          type: 'error',
          content: currentBlock.error,
          dismissible: true,
          dismissLabel: 'Dismiss error',
          onDismiss: () => removeNotification('api-error'),
          id: 'api-error',
        },
      ]);
    }
  }, [currentBlock.error]);

  useEffect(() => {
    if (updateCapabilityResult.status === LoadingStatus.SUCCESS) {
      setNotifications((prevState) => [
        ...prevState,
        {
          header: `You have successfully published ${updateCapabilityResult?.data?.capabilityName}.`,
          type: 'success',
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => removeNotification('api-success'),
          id: 'api-success',
        },
      ]);
      void dispatch(
        listCapabilitiesForBlock({
          blockId: params.blockId,
          clientId: selectedAuthProfile.ClientId,
          pageViewType: PageViewType.SELF_VIEW,
        }),
      );
    } else if (updateCapabilityResult.status === LoadingStatus.FAILED) {
      setNotifications((prevState) => [
        ...prevState,
        {
          header: updateCapabilityResult?.error || 'Unable to publish block. Please try again.',
          type: 'error',
          dismissible: true,
          dismissLabel: 'Dismiss error',
          onDismiss: () => removeNotification('api-error'),
          id: 'api-error',
        },
      ]);
    }
  }, [updateCapabilityResult?.status]);

  return (
    <AppLayout
      headerSelector="#tw-header"
      notifications={<Flashbar items={notifications} />}
      stickyNotifications={false}
      content={<MyBlockDetailPageContent setNotifications={setNotifications} removeNotification={removeNotification} />}
      navigation={<GlobalNavigationPanel />}
      navigationOpen={isNavigationPanelOpen}
      onNavigationChange={navigationChangeHandler}
      tools={<GlobalHelpPanel />}
      breadcrumbs={
        <BreadcrumbLinkGroup
          links={[
            {
              text: 'My blocks',
              href: MY_BLOCKS,
            },
            {
              text: `${currentBlock?.data?.blockName || ''}`,
              href: '',
            },
          ]}
        />
      }
    />
  );
}
