import axios, { AxiosError } from 'axios';

import { PageViewType } from '../common/types/enums';
import { blockRegistryApiEndpoint } from '../config/endpoints';
import { store } from '../store/store';

export interface IListLatestCapabilitiesForBlockRequest {
  blockId: string;
  clientId?: string;
  pageViewType: PageViewType;
}

export interface IListLatestCapabilitiesForBlockResponse {
  capabilityList: Array<{
    capabilityId: string;
    capabilityName: string;
    capabilityStatus: string;
    capabilityType: string;
    capabilityVersion: string;
    createdAt: number;
    createdBy: string;
    updatedBy: string;
  }>;
}

export async function listLatestCapabilitiesForBlockApi(request: IListLatestCapabilitiesForBlockRequest) {
  try {
    const response = await axios.post<IListLatestCapabilitiesForBlockResponse>(
      `${blockRegistryApiEndpoint}/block-registry/list-latest-capabilities-for-block`,
      {
        ...request,
      },
    );

    return response.data;
  } catch (error) {
    if ((error as AxiosError)?.response?.data) {
      throw (error as AxiosError).response.data;
    }
    throw error;
  }
}

export interface ICreateCapabilityRequest {
  blockId: string;
  capabilityName: string;
  capabilityType: string;
  summary: string;
  tags: string[];
  allowedClientIds: string[];
  capabilityConfigMap: object;
}

export async function createCapabilityApi(request: ICreateCapabilityRequest) {
  try {
    const response = await axios.post<VoidFunction>(`${blockRegistryApiEndpoint}/block-registry/create-capability`, {
      ...request,
      clientId: store.getState().authState.selectedAuthProfile.ClientId,
    });

    return response.data;
  } catch (error) {
    if ((error as AxiosError)?.response?.data) {
      throw (error as AxiosError).response.data;
    }
    throw error;
  }
}

export interface IGetCapabilityRequest {
  blockId: string;
  capabilityId: string;
  capabilityVersion: string;
  clientId?: string;
  pageViewType: PageViewType;
}

interface IGetCapabilityResponse {
  capabilityDetail: {
    capabilityId: string;
    capabilityName: string;
    capabilityType: string;
    capabilityStatus: string;
    capabilityVersion: string;
    summary: string;
    tags: string[];
    minArtifactVersion: string;
    maxArtifactVersion: string;
    capabilityConfigPresignedUrl: string;
    createdAt: number;
    updatedAt: number;
    createdBy: string;
    updatedBy: string;
  };
  blockDetail: {
    blockName: string;
  };
}

export async function getCapabilityApi(request: IGetCapabilityRequest) {
  try {
    const response = await axios.post<IGetCapabilityResponse>(
      `${blockRegistryApiEndpoint}/block-registry/get-capability`,
      {
        ...request,
      },
    );

    return response.data;
  } catch (error) {
    if ((error as AxiosError)?.response?.data) {
      throw (error as AxiosError).response.data;
    }
    throw error;
  }
}

export interface IUpdateCapabilityRequest {
  blockId: string;
  capabilityId: string;
  capabilityName?: string;
  capabilityStatus?: string;
  summary?: string;
  tags?: string[];
  allowedClientIds?: string[];
  capabilityConfigMap?: object;
}

interface IUpdateCapabilityResponse {
  capabilityId: string;
  capabilityName: string;
}

export async function updateCapabilityApi(request: IUpdateCapabilityRequest) {
  try {
    const response = await axios.post<IUpdateCapabilityResponse>(
      `${blockRegistryApiEndpoint}/block-registry/update-capability`,
      {
        ...request,
        clientId: store.getState().authState.selectedAuthProfile.ClientId,
      },
    );

    return response.data;
  } catch (error) {
    if ((error as AxiosError)?.response?.data) {
      throw (error as AxiosError).response.data;
    }
    throw error;
  }
}
