import { ControlledSelect, ControlledSelectProps } from '@amzn/react-hook-form-cloudscape';
import { FormField } from '@cloudscape-design/components';
import { FormFieldProps } from '@cloudscape-design/components/form-field';
import { SelectProps } from '@cloudscape-design/components/select';
import { FieldValues, get, useFormContext } from 'react-hook-form';

interface ISelectFormFieldProps extends Partial<SelectProps>, FormFieldProps, ControlledSelectProps<FieldValues> {
  name: string;
  errorText?: string;
}

export default function SelectFormField(props: ISelectFormFieldProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <FormField {...props} errorText={get(errors, `${props.name}.message`)}>
      <ControlledSelect {...props} name={props.name} control={control} />
    </FormField>
  );
}
