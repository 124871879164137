import { AppLayout, ContentLayout, Flashbar, Header, SpaceBetween } from '@cloudscape-design/components';
import { FlashbarProps } from '@cloudscape-design/components/flashbar';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Params, useParams } from 'react-router-dom';

import { BLOCK_CATEGORY_MAP } from '../../../common/constants/block-constants';
import { BLOCKS, LIBRARY_BLOCKS } from '../../../common/constants/route-paths';
import { LoadingStatus } from '../../../common/types/enums';
import { IListBlocksData } from '../../../common/types/interfaces';
import { checkIfClientIdAndRoleMissing, toSentenceCase } from '../../../common/utils';
import GlobalHelpPanel from '../../../components/GlobalHelpPanel';
import GlobalNavigationPanel from '../../../components/GlobalNavigationPanel';
import BreadcrumbLinkGroup from '../../../components/polaris/BreadcrumbLinkGroup';
import { AuthProfileSelector } from '../../../slices/AuthStateSlice';
import { listBlocks, selectListBlocksResponse } from '../../../slices/BlockDetailSlice';
import { selectIsNavigationPanelOpen, setNavigationPanelOpen } from '../../../slices/SitePreferencesSlice';
import { AppDispatch } from '../../../store/store';
import BlocksPageCards from './components/BlocksPageCards';

export interface IBlocksPageContentProps {
  blockList: IListBlocksData[];
  listBlocksLoadingStatus: LoadingStatus;
  headerName: string;
}

function getListBlocksData(blockList: IListBlocksData[], blockCategory: string) {
  if (!blockCategory) {
    return blockList;
  }

  return blockList.filter((blockData) => blockData.blockCategory === blockCategory);
}

function BlocksPageContent() {
  const params = useParams();
  const listBlocksData = useSelector(selectListBlocksResponse);

  let headerName = 'Libraries';
  let blockCategoryName = params.blockCategory;
  if (params.blockCategory && BLOCK_CATEGORY_MAP[params.blockCategory.toUpperCase()]?.name) {
    headerName = BLOCK_CATEGORY_MAP[params.blockCategory.toUpperCase()]?.name;
    blockCategoryName = BLOCK_CATEGORY_MAP[params.blockCategory.toUpperCase()]?.name;
  } else {
    headerName = toSentenceCase(blockCategoryName);
  }

  const props: IBlocksPageContentProps = {
    blockList: getListBlocksData(listBlocksData.blockList, blockCategoryName),
    listBlocksLoadingStatus: listBlocksData.status,
    headerName,
  };

  return (
    <ContentLayout header={<Header variant="h1"> {headerName}</Header>}>
      <SpaceBetween size="xl">
        <BlocksPageCards {...props} />
      </SpaceBetween>
    </ContentLayout>
  );
}

function getBreadcrumbLinkGroup(params: Readonly<Params<string>>) {
  const breadcrumbLinks = [
    {
      text: 'Blocks',
      href: BLOCKS,
    },
    {
      text: 'Libraries',
      href: LIBRARY_BLOCKS,
    },
  ];

  if (params.blockCategory && BLOCK_CATEGORY_MAP[params.blockCategory.toUpperCase()]?.name) {
    breadcrumbLinks.push({
      text: BLOCK_CATEGORY_MAP[params.blockCategory.toUpperCase()]?.name,
      href: params.blockCategory,
    });
  }

  return breadcrumbLinks;
}

export default function BlocksPage() {
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const isNavigationPanelOpen = useSelector(selectIsNavigationPanelOpen);
  const [notifications, setNotifications] = useState<FlashbarProps.MessageDefinition[]>([]);
  const selectedAuthProfile = useSelector(AuthProfileSelector);
  const listBlocksData = useSelector(selectListBlocksResponse);

  const removeNotification = (notificationId: string) => {
    const filteredNotification = notifications.filter((notification) => notification.id !== notificationId);
    setNotifications(filteredNotification);
  };

  const navigationChangeHandler = () => {
    dispatch(setNavigationPanelOpen(!isNavigationPanelOpen));
  };

  useEffect(() => {
    removeNotification('api-error');
    if (listBlocksData.error) {
      setNotifications((prevState) => [
        ...prevState,
        {
          header: 'Failed to load blocks.',
          type: 'error',
          content: listBlocksData.error,
          dismissible: true,
          dismissLabel: 'Dismiss error',
          onDismiss: () => removeNotification('api-error'),
          id: 'api-error',
        },
      ]);
    }
  }, [listBlocksData.error]);
  useEffect(() => {
    if (checkIfClientIdAndRoleMissing(selectedAuthProfile)) {
      return;
    }

    void dispatch(listBlocks());
  }, [selectedAuthProfile]);

  return (
    <AppLayout
      headerSelector="#tw-header"
      notifications={<Flashbar items={notifications} />}
      stickyNotifications={false}
      content={<BlocksPageContent />}
      navigation={<GlobalNavigationPanel />}
      navigationOpen={isNavigationPanelOpen}
      onNavigationChange={navigationChangeHandler}
      tools={<GlobalHelpPanel />}
      breadcrumbs={<BreadcrumbLinkGroup links={getBreadcrumbLinkGroup(params)} />}
    />
  );
}
