import { Alert, Container, Header, SpaceBetween } from '@cloudscape-design/components';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { AutomationStatus, AutomationType } from '../../../../common/types/enums';
import { selectGetAutomationDetailResponse } from '../../../../slices/AutomationDetailSlice';
import AutomationBlockBuilder from './builder-tools/AutomationBlockBuilder';
import AutomationInstantUIPreview from './builder-tools/AutomationInstantUIPreview';

export default function AutomationBuilderToolsSection() {
  const [showProcessDiscoveryReviewAlert, setShowProcessDiscoveryReviewAlert] = useState<boolean>(true);

  const currentAutomation = useSelector(selectGetAutomationDetailResponse);
  if (!currentAutomation?.data) {
    return;
  }

  const { automationDetail, executableList } = currentAutomation.data;

  return (
    <SpaceBetween size="xl">
      <Container disableContentPaddings={true} className="tw-pa2 tw-pl4">
        <Header variant="h2">Builder&apos;s tools</Header>
      </Container>
      {automationDetail.automationType === AutomationType.AUTO_DEV && (
        <>
          {automationDetail.automationStatus === AutomationStatus.DEVELOPMENT &&
            executableList.length === 0 &&
            showProcessDiscoveryReviewAlert && (
              <Alert type="info" dismissible={true} onDismiss={() => setShowProcessDiscoveryReviewAlert(false)}>
                We recommend reviewing and finalizing the process in Discovery before starting the development.
              </Alert>
            )}
          <AutomationBlockBuilder />
        </>
      )}
      <AutomationInstantUIPreview />
    </SpaceBetween>
  );
}
