import { workplaceKwPortalEndpoint } from '../config/endpoints';
import {
  AUTOMATION_DETAIL,
  AUTOMATION_INSTANT_UI_PREVIEW,
  BLOCK_FUNCTION_DETAIL,
  CREATE_AUTOMATION_PIPELINE,
  EDIT_AUTOMATION_DETAIL,
  LIBRARY_BLOCK_DETAIL,
  LIBRARY_BLOCKS_CATEGORY,
  MY_BLOCK_CREATE,
  MY_BLOCK_CREATE_PIPELINE,
  MY_BLOCK_FUNCTION_DETAIL,
  MY_BLOCK_FUNCTION_EDIT,
  MY_BLOCK_FUNCTION_REGISTER,
  MY_BLOCK_LIBRARY_DETAIL,
  MY_BLOCK_SERVICE_DETAIL,
} from './constants/route-paths';
import { BlockDetailTabId, BlockType } from './types/enums';

export function getAutomationDetailPagePath(automationId: string) {
  return AUTOMATION_DETAIL.replace(':automationId', automationId);
}

export function getCreateAutomationPipelinePagePath(automationId: string) {
  return CREATE_AUTOMATION_PIPELINE.replace(':automationId', automationId);
}

export function getEditAutomationDetailPagePath(automationId: string) {
  return EDIT_AUTOMATION_DETAIL.replace(':automationId', automationId);
}

export function getAutomationInstantUIPreviewPath(automationId: string) {
  return AUTOMATION_INSTANT_UI_PREVIEW.replace(':automationId', automationId);
}

export function getProcessDetailPagePath(processId: string, clientId: string) {
  return `${workplaceKwPortalEndpoint}/discovery/processes/${processId}?clientID=${clientId}`;
}

export function getProcessBlockBuilderPagePath(processId: string, clientId: string, automationId: string) {
  return `${workplaceKwPortalEndpoint}/discovery/processes/${processId}/add-blocks?clientID=${clientId}&automationId=${automationId}`;
}

export function getCreateProcessPagePath() {
  return `${workplaceKwPortalEndpoint}/discovery/create-process`;
}

export function getMyBlockCreatePagePath(blockType: BlockType = BlockType.LIBRARY) {
  return MY_BLOCK_CREATE.replace(':blockType', blockType.toLowerCase());
}

export function getMyBlockLibraryDetailPagePath(blockId: string, tabId = BlockDetailTabId.EXECUTABLES) {
  return MY_BLOCK_LIBRARY_DETAIL.replace(':blockId', blockId).concat('?tabId=', tabId);
}

export function getMyBlockServiceDetailPagePath(blockId: string) {
  return MY_BLOCK_SERVICE_DETAIL.replace(':blockId', blockId);
}

export function getMyBlockCreatePipelinePagePath(blockId: string) {
  return MY_BLOCK_CREATE_PIPELINE.replace(':blockId', blockId);
}

export function getBlockDetailPagePath(blockId: string, tabId = BlockDetailTabId.EXECUTABLES) {
  return LIBRARY_BLOCK_DETAIL.replace(':blockId', blockId).concat('?tabId=', tabId);
}

export function getLibraryBlocksCategoryPagePath(blockCategory: string) {
  return LIBRARY_BLOCKS_CATEGORY.replace(':blockCategory', blockCategory);
}

export function getMyBlockFunctionDetailPagePath(blockId: string, capabilityId: string) {
  return MY_BLOCK_FUNCTION_DETAIL.replace(':blockId', blockId).replace(':capabilityId', capabilityId);
}

export function getMyBlockFunctionRegisterPagePath(blockId: string) {
  return MY_BLOCK_FUNCTION_REGISTER.replace(':blockId', blockId);
}

export function getMyBlockFunctionEditPagePath(blockId: string, capabilityId: string) {
  return MY_BLOCK_FUNCTION_EDIT.replace(':blockId', blockId).replace(':capabilityId', capabilityId);
}

export function getBlockFunctionDetailPagePath(blockId: string, capabilityId: string) {
  return BLOCK_FUNCTION_DETAIL.replace(':blockId', blockId).replace(':capabilityId', capabilityId);
}

export function getAutomationRequestDetailPagePath(requestId: string, clientId: string) {
  return `${workplaceKwPortalEndpoint}/automation/automation-requests/${requestId}?clientID=${clientId}`;
}
