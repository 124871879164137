import { Checkbox, CheckboxProps, FormField, NonCancelableCustomEvent } from '@cloudscape-design/components';
import { FormFieldProps } from '@cloudscape-design/components/form-field';
import React from 'react';
import { Controller, FieldValues, get, UseControllerProps, useFormContext } from 'react-hook-form';

type ControlledCheckboxProps<T extends FieldValues> = UseControllerProps<T> &
  Omit<CheckboxProps, 'name' | 'onChange' | 'value' | 'checked'>;

interface ICheckboxFormFieldProps extends Partial<CheckboxProps>, FormFieldProps, ControlledCheckboxProps<FieldValues> {
  name: string;
  checkboxDescription?: string;
}

export function CheckboxFormField(props: ICheckboxFormFieldProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <FormField {...props} errorText={get(errors, `${props.name}.message`)}>
      <ControlledCheckbox {...props} name={props.name} control={control} description={props.checkboxDescription} />
    </FormField>
  );
}

function ControlledCheckbox<T extends FieldValues>(props: ControlledCheckboxProps<T>) {
  return (
    <Controller
      {...props}
      render={({ field: { onChange, value, ref } }) => (
        <Checkbox
          onChange={(event: NonCancelableCustomEvent<CheckboxProps.ChangeDetail>) => {
            onChange(event.detail.checked);
          }}
          checked={value}
          ref={ref}
          name={props.name}
        >
          {props.description}
        </Checkbox>
      )}
    />
  );
}
