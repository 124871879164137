import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  createBlockForClientApi,
  getBlockApi,
  getBlockArtifactAssetApi,
  getBlockPipelineApi,
  ICreateBlockDetailResponse,
  IGetBlockArtifactAssetRequest,
  IGetBlockArtifactAssetResponse,
  IGetBlockRequest,
  IListBlockArtifactsRequest,
  listBlockArtifactsApi,
  listBlocksApi,
  listBlocksForClientApi,
} from '../api/block-registry-api';
import { createBlockPipelineApi, ICreateBlockPipelineRequest } from '../api/infra-manager-api';
import { BLOCK_CATEGORY_MAP } from '../common/constants/block-constants';
import {
  ArtifactStatus,
  BlockStatus,
  BlockType,
  LoadingStatus,
  PipelineBuildStatus,
  PipelineStatus,
} from '../common/types/enums';
import {
  IGetBlockData,
  IGetBlockPipelineData,
  IListBlockArtifactsData,
  IListBlocksData,
  IListBlocksForClientData,
} from '../common/types/interfaces';
import { getValidUsername } from '../common/utils';
import { RootState } from '../store/store';

interface IBlockDetailState {
  createBlockForClientResponse: {
    status: LoadingStatus;
    blockId?: string;
    error?: string;
  };
  listBlocksForClientResponse: {
    blockList: IListBlocksForClientData[];
    lastEvaluatedKey?: string;
    status: LoadingStatus;
    error?: string;
  };
  listBlocksResponse: {
    blockList: IListBlocksData[];
    lastEvaluatedKey?: string;
    status: LoadingStatus;
    error?: string;
  };
  getBlockResponse: {
    data?: IGetBlockData;
    status: LoadingStatus;
    error?: string;
  };
  listBlockArtifactsResponse: {
    data: IListBlockArtifactsData[];
    status: LoadingStatus;
    error?: string;
  };
  getBlockPipelineResponse: {
    data?: IGetBlockPipelineData;
    status: LoadingStatus;
    error?: string;
  };
  createBlockPipelineResponse: {
    status: LoadingStatus;
    error?: string;
  };
  getBlockArtifactAssetResponse: {
    artifactAssetUrl?: string;
    status: LoadingStatus;
    error?: string;
  };
}

const initialState: IBlockDetailState = {
  createBlockForClientResponse: {
    status: LoadingStatus.IDLE,
  },
  listBlocksForClientResponse: {
    blockList: [],
    status: LoadingStatus.IDLE,
  },
  getBlockResponse: { status: LoadingStatus.IDLE },
  listBlockArtifactsResponse: { data: [], status: LoadingStatus.IDLE },
  getBlockPipelineResponse: { status: LoadingStatus.IDLE },
  createBlockPipelineResponse: { status: LoadingStatus.IDLE },
  listBlocksResponse: {
    blockList: [],
    status: LoadingStatus.IDLE,
  },
  getBlockArtifactAssetResponse: { status: LoadingStatus.IDLE },
};

export const createBlockForClient = createAsyncThunk(
  'blockDetail/createBlockForClient',
  async (fieldValues: Record<string, string | string[]>): Promise<ICreateBlockDetailResponse> => {
    return await createBlockForClientApi(fieldValues);
  },
);

export const listBlocksForClient = createAsyncThunk(
  'blockDetail/listBlocksForClient',
  async (): Promise<IListBlocksForClientData[]> => {
    const listBlocksResponse = await listBlocksForClientApi({});
    return listBlocksResponse.blockList.map((block) => ({
      blockId: block.blockId,
      blockName: block.blockName,
      blockCategory: BLOCK_CATEGORY_MAP[block.blockCategory]?.name,
      blockStatus: block.blockStatus as BlockStatus,
      blockType: block.blockType as BlockType,
      createdAt: block.createdAt,
      createdBy: getValidUsername(block.createdBy),
      updatedBy: getValidUsername(block.updatedBy),
    }));
  },
);

export const listBlocks = createAsyncThunk('blockDetail/listBlocks', async (): Promise<IListBlocksData[]> => {
  const listBlocksResponse = await listBlocksApi({});
  return listBlocksResponse.blockList.map((block) => ({
    blockId: block.blockId,
    blockName: block.blockName,
    blockCategory: BLOCK_CATEGORY_MAP[block.blockCategory]?.name,
    blockSummary: block.blockSummary,
    tags: block.tags || [],
    clientId: block.clientId,
  }));
});

export const getBlock = createAsyncThunk(
  'blockDetail/getBlock',
  async (getBlockRequest: IGetBlockRequest): Promise<IGetBlockData> => {
    const getBlockResponse = await getBlockApi(getBlockRequest);
    return {
      blockId: getBlockResponse.blockDetail.blockId,
      blockName: getBlockResponse.blockDetail.blockName,
      blockCategory: BLOCK_CATEGORY_MAP[getBlockResponse.blockDetail.blockCategory]?.name,
      blockStatus: getBlockResponse.blockDetail.blockStatus as BlockStatus,
      blockType: getBlockResponse.blockDetail.blockType as BlockType,
      summary: getBlockResponse.blockDetail.summary,
      emailAddress: getBlockResponse.blockDetail.emailAddress,
      tags: getBlockResponse.blockDetail.tags || [],
      ownerClientId: getBlockResponse.blockDetail.ownerClientId,
      createdAt: getBlockResponse.blockDetail.createdAt,
      createdBy: getValidUsername(getBlockResponse.blockDetail.createdBy),
      updatedBy: getValidUsername(getBlockResponse.blockDetail.updatedBy),
    };
  },
);

export const listBlockArtifacts = createAsyncThunk(
  'blockDetail/listBlockArtifacts',
  async (listBlockArtifactsRequest: IListBlockArtifactsRequest): Promise<IListBlockArtifactsData[]> => {
    const listBlockArtifactsResponse = await listBlockArtifactsApi(listBlockArtifactsRequest);
    return listBlockArtifactsResponse.artifactList.map((blockArtifact) => ({
      version: blockArtifact.version,
      artifactStatus: blockArtifact.artifactStatus as ArtifactStatus,
      updatedAt: blockArtifact.updatedAt,
      updatedBy: getValidUsername(blockArtifact.updatedBy),
    }));
  },
);

export const getBlockPipeline = createAsyncThunk(
  'blockDetail/getBlockPipeline',
  async (blockId: string): Promise<IGetBlockPipelineData> => {
    const getBlockPipelineResponse = await getBlockPipelineApi({ blockId });
    const currentBlockPipelineData: IGetBlockPipelineData = {
      pipelineInfo: {
        pipelineStatus: getBlockPipelineResponse.pipeline.pipelineStatus as PipelineStatus,
        awsAccountId: getBlockPipelineResponse.pipeline.awsAccountId,
        codePipelineName: getBlockPipelineResponse.pipeline.codePipelineName,
        codeRepositoryName: getBlockPipelineResponse.pipeline.codeRepositoryName,
        sdkAccessRoleArn: getBlockPipelineResponse.pipeline.sdkAccessRoleArn,
        region: getBlockPipelineResponse.pipeline.region,
        createdAt: getBlockPipelineResponse.pipeline.createdAt,
        createdBy: getValidUsername(getBlockPipelineResponse.pipeline.createdBy),
        updatedBy: getValidUsername(getBlockPipelineResponse.pipeline.updatedBy),
      },
    };

    if (getBlockPipelineResponse.artifactBuildInfo) {
      currentBlockPipelineData.artifactBuildInfo = {
        buildStatus: getBlockPipelineResponse.artifactBuildInfo.buildStatus as PipelineBuildStatus,
        buildTimestamp: getBlockPipelineResponse.artifactBuildInfo.buildTimestamp,
      };
    }

    return currentBlockPipelineData;
  },
);

export const createBlockPipeline = createAsyncThunk(
  'blockDetail/createBlockPipeline',
  async (request: ICreateBlockPipelineRequest): Promise<VoidFunction> => {
    return await createBlockPipelineApi(request);
  },
);

export const getBlockArtifactAsset = createAsyncThunk(
  'blockDetail/getBlockArtifactAsset',
  async (request: IGetBlockArtifactAssetRequest): Promise<IGetBlockArtifactAssetResponse> => {
    return await getBlockArtifactAssetApi(request);
  },
);

const blockDetailSlice = createSlice({
  name: 'blockDetail',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createBlockForClient.pending, (state) => {
      state.createBlockForClientResponse.status = LoadingStatus.PENDING;
      state.createBlockForClientResponse.error = '';
    });
    builder.addCase(createBlockForClient.fulfilled, (state, action) => {
      state.createBlockForClientResponse.error = '';
      state.createBlockForClientResponse.status = LoadingStatus.SUCCESS;
      state.createBlockForClientResponse.blockId = action.payload.blockId;
    });
    builder.addCase(createBlockForClient.rejected, (state, action) => {
      state.createBlockForClientResponse.status = LoadingStatus.FAILED;
      state.createBlockForClientResponse.error =
        action?.error?.message || 'Something went wrong. Please try again later';
    });
    builder.addCase(listBlocksForClient.pending, (state) => {
      state.listBlocksForClientResponse.status = LoadingStatus.PENDING;
      state.listBlocksForClientResponse.error = '';
    });
    builder.addCase(listBlocksForClient.fulfilled, (state, action) => {
      state.listBlocksForClientResponse.error = '';
      state.listBlocksForClientResponse.status = LoadingStatus.SUCCESS;
      state.listBlocksForClientResponse.blockList = action.payload;
    });
    builder.addCase(listBlocksForClient.rejected, (state) => {
      state.listBlocksForClientResponse.status = LoadingStatus.FAILED;
      state.listBlocksForClientResponse.error = 'Something went wrong. Please try again later';
    });
    builder.addCase(listBlocks.pending, (state) => {
      state.listBlocksResponse.status = LoadingStatus.PENDING;
      state.listBlocksResponse.error = '';
    });
    builder.addCase(listBlocks.fulfilled, (state, action) => {
      state.listBlocksResponse.error = '';
      state.listBlocksResponse.status = LoadingStatus.SUCCESS;
      state.listBlocksResponse.blockList = action.payload;
    });
    builder.addCase(listBlocks.rejected, (state) => {
      state.listBlocksResponse.status = LoadingStatus.FAILED;
      state.listBlocksResponse.error = 'Something went wrong. Please try again later';
    });
    builder.addCase(getBlock.pending, (state) => {
      state.getBlockResponse.status = LoadingStatus.PENDING;
      state.getBlockResponse.error = '';
      state.getBlockResponse.data = null;
    });
    builder.addCase(getBlock.fulfilled, (state, action) => {
      state.getBlockResponse.error = '';
      state.getBlockResponse.status = LoadingStatus.SUCCESS;
      state.getBlockResponse.data = action.payload;
    });
    builder.addCase(getBlock.rejected, (state) => {
      state.getBlockResponse.status = LoadingStatus.FAILED;
      state.getBlockResponse.error = 'Something went wrong. Please try again later';
      state.getBlockResponse.data = null;
    });
    builder.addCase(listBlockArtifacts.pending, (state) => {
      state.listBlockArtifactsResponse.status = LoadingStatus.PENDING;
      state.listBlockArtifactsResponse.error = '';
      state.listBlockArtifactsResponse.data = [];
    });
    builder.addCase(listBlockArtifacts.fulfilled, (state, action) => {
      state.listBlockArtifactsResponse.error = '';
      state.listBlockArtifactsResponse.status = LoadingStatus.SUCCESS;
      state.listBlockArtifactsResponse.data = action.payload;
    });
    builder.addCase(listBlockArtifacts.rejected, (state) => {
      state.listBlockArtifactsResponse.status = LoadingStatus.FAILED;
      state.listBlockArtifactsResponse.error = 'Something went wrong. Please try again later';
      state.listBlockArtifactsResponse.data = [];
    });
    builder.addCase(getBlockPipeline.pending, (state) => {
      state.getBlockPipelineResponse.status = LoadingStatus.PENDING;
      state.getBlockPipelineResponse.error = '';
      state.getBlockPipelineResponse.data = null;
    });
    builder.addCase(getBlockPipeline.fulfilled, (state, action) => {
      state.getBlockPipelineResponse.error = '';
      state.getBlockPipelineResponse.status = LoadingStatus.SUCCESS;
      state.getBlockPipelineResponse.data = action.payload;
    });
    builder.addCase(getBlockPipeline.rejected, (state) => {
      state.getBlockPipelineResponse.status = LoadingStatus.FAILED;
      state.getBlockPipelineResponse.error = 'Something went wrong. Please try again later';
      state.getBlockPipelineResponse.data = null;
    });
    builder.addCase(createBlockPipeline.pending, (state) => {
      state.createBlockPipelineResponse.status = LoadingStatus.PENDING;
      state.createBlockPipelineResponse.error = '';
    });
    builder.addCase(createBlockPipeline.fulfilled, (state) => {
      state.createBlockPipelineResponse.error = '';
      state.createBlockPipelineResponse.status = LoadingStatus.SUCCESS;
    });
    builder.addCase(createBlockPipeline.rejected, (state, action) => {
      state.createBlockPipelineResponse.status = LoadingStatus.FAILED;
      state.createBlockPipelineResponse.error =
        action?.error?.message || 'Something went wrong. Please try again later';
    });
    builder.addCase(getBlockArtifactAsset.fulfilled, (state, action) => {
      state.getBlockArtifactAssetResponse.error = '';
      state.getBlockArtifactAssetResponse.status = LoadingStatus.SUCCESS;
      state.getBlockArtifactAssetResponse.artifactAssetUrl = action.payload.artifactAssetUrl;
    });
    builder.addCase(getBlockArtifactAsset.rejected, (state) => {
      state.getBlockArtifactAssetResponse.status = LoadingStatus.FAILED;
      state.getBlockArtifactAssetResponse.error = 'Something went wrong. Please try again later';
      state.getBlockArtifactAssetResponse.artifactAssetUrl = null;
    });
    builder.addCase(getBlockArtifactAsset.pending, (state) => {
      state.getBlockArtifactAssetResponse.status = LoadingStatus.PENDING;
      state.getBlockArtifactAssetResponse.error = '';
      state.getBlockArtifactAssetResponse.artifactAssetUrl = null;
    });
  },
});

export const selectCreateBlockForClient = (state: RootState) => state.blockDetail.createBlockForClientResponse;
export const selectListBlocksForClientResponse = (state: RootState) => state.blockDetail.listBlocksForClientResponse;
export const selectListBlocksResponse = (state: RootState) => state.blockDetail.listBlocksResponse;
export const selectGetBlockResponse = (state: RootState) => state.blockDetail.getBlockResponse;
export const selectListBlockArtifactsResponse = (state: RootState) => state.blockDetail.listBlockArtifactsResponse;
export const selectGetBlockPipelineResponse = (state: RootState) => state.blockDetail.getBlockPipelineResponse;
export const selectCreateBlockPipelineResponse = (state: RootState) => state.blockDetail.createBlockPipelineResponse;
export const selectGetBlockArtifactAssetResponse = (state: RootState) =>
  state.blockDetail.getBlockArtifactAssetResponse;

export default blockDetailSlice.reducer;
