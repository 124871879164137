import {
  Alert,
  Button,
  Container,
  FormField,
  Header,
  Input,
  Link,
  SpaceBetween,
  StatusIndicator,
} from '@cloudscape-design/components';
import React, { SetStateAction, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { SynchronizeResourceAccountExceptionErrorCode } from '../../../../api/infra-manager-api';
import { LoadingStatus, ResourceType } from '../../../../common/types/enums';
import InputFormField from '../../../../components/polaris/InputFormField';
import { onboardingAccountDocUrl } from '../../../../config/endpoints';
import {
  selectSynchronizeResourceAccountResponse,
  synchronizeResourceAccount,
} from '../../../../slices/InfraManagerSlice';
import { AppDispatch } from '../../../../store/store';
import { AutomationPipelineSchemaType } from '../config/automation-pipeline-form.config';
import { AWS_ACCOUNT_ID } from '../constants/form-constants';

function AwsResourceNameFields() {
  const params = useParams();

  return (
    <Container header={<Header variant="h2">Resource names</Header>}>
      <SpaceBetween size="l">
        <FormField label="Region" description="The AWS region for code deployment">
          <Input value="US West (Oregon)" readOnly={true} />
        </FormField>
        <FormField label="Code commit" description="The AWS resource name for code commit">
          <Input value={`tw-automation-${params.automationId}-cc-repo`} readOnly={true} />
        </FormField>
        <FormField label="Code build" description="The AWS resource name for code build">
          <Input value={`tw-automation-${params.automationId}-cb-project`} readOnly={true} />
        </FormField>
        <FormField label="Code pipeline" description="The AWS resource name for code pipeline">
          <Input value={`tw-automation-${params.automationId}-cp-pipeline`} readOnly={true} />
        </FormField>
        <FormField label="S3 artifact bucket" description="The AWS resource name for S3 artifact bucket">
          <Input value={`tw-automation-${params.automationId}-s3-bucket`} readOnly={true} />
        </FormField>
      </SpaceBetween>
    </Container>
  );
}

function AccountSynchronizationMessage() {
  const synchronizeResourceAccountResponse = useSelector(selectSynchronizeResourceAccountResponse);

  if (synchronizeResourceAccountResponse.status === LoadingStatus.SUCCESS && synchronizeResourceAccountResponse.data) {
    return synchronizeResourceAccountResponse.data.isSynchronized ? (
      <StatusIndicator type="success">Synchronized</StatusIndicator>
    ) : synchronizeResourceAccountResponse.data.isSynchronizationTriggered ? (
      <Alert type="warning">
        We have triggered a synchronization request in your account{' '}
        <Link external href={synchronizeResourceAccountResponse.data.onboardingTemplateUrl}>
          here
        </Link>{' '}
        , wait for that to complete, then you can continue with Automation pipeline creation. Follow{' '}
        <Link external href={onboardingAccountDocUrl}>
          detailed instructions
        </Link>{' '}
        if you need further guidance.
      </Alert>
    ) : (
      <Alert
        type="warning"
        action={
          <Button download href={synchronizeResourceAccountResponse.data.onboardingTemplateUrl}>
            Download template
          </Button>
        }
      >
        The account is not synchronized. To synchronize, use the template to create stack on CloudFormation in your AWS
        account in <b>US West (Oregon)</b> region. Follow{' '}
        <Link external href={onboardingAccountDocUrl}>
          detailed instructions
        </Link>{' '}
        if you need further guidance.
      </Alert>
    );
  } else if (synchronizeResourceAccountResponse.status === LoadingStatus.FAILED) {
    return synchronizeResourceAccountResponse.error ===
      SynchronizeResourceAccountExceptionErrorCode.ACCOUNT_ONBOARDING_STACK_DEPLOYED_INCORRECTLY ? (
      <Alert type="error">
        You might have deployed the account onboarding stack template in wrong AWS region, make sure to deploy the stack
        in <b>US West (Oregon)</b> region. Follow this{' '}
        <Link external href={onboardingAccountDocUrl}>
          {/* TODO: Update this troubleshooting guide link */}
          troubleshooting guide
        </Link>{' '}
        to deploy it correctly.
      </Alert>
    ) : (
      <StatusIndicator type="error">Something went wrong while synchronizing account.</StatusIndicator>
    );
  }
  return <></>;
}

interface ICreateAutomationPipelineContainerProps {
  setFormErrorText: React.Dispatch<SetStateAction<string>>;
}

export default function CreateAutomationPipelineContainer(props: ICreateAutomationPipelineContainerProps) {
  const dispatch = useDispatch<AppDispatch>();
  const synchronizeResourceAccountResponse = useSelector(selectSynchronizeResourceAccountResponse);
  const { watch, handleSubmit } = useFormContext<AutomationPipelineSchemaType>();
  const awsAccountId: string = watch(AWS_ACCOUNT_ID);

  useEffect(() => {
    if (synchronizeResourceAccountResponse.data?.isSynchronized) {
      props.setFormErrorText('');
    }
  }, [synchronizeResourceAccountResponse.data]);

  return (
    <SpaceBetween size="l">
      <Container header={<Header variant="h2">AWS account</Header>}>
        <SpaceBetween size="m">
          <InputFormField
            secondaryControl={
              <Button
                loading={synchronizeResourceAccountResponse.status === LoadingStatus.PENDING}
                onClick={async () =>
                  await handleSubmit(() =>
                    dispatch(
                      synchronizeResourceAccount({
                        awsAccountId,
                        resourceType: ResourceType.AUTOMATION,
                      }),
                    ),
                  )()
                }
              >
                Synchronize
              </Button>
            }
            name={AWS_ACCOUNT_ID}
            label="AWS account ID"
            description="A valid AWS account with enough capacity to deploy your automation"
            placeholder="Enter AWS account ID"
            constraintText="Must be a 12-digit number"
            ariaRequired={true}
          />
          <AccountSynchronizationMessage />

          {synchronizeResourceAccountResponse.data?.isSynchronized && (
            <FormField label="AWS IAM role" description="The IAM role for Workplace to access the AWS account">
              <Input
                value={`arn:aws:iam::${awsAccountId}:taskless-workplace-automation-resource-manager-service-role`}
                readOnly
              />
            </FormField>
          )}
        </SpaceBetween>
      </Container>
      <AwsResourceNameFields />
    </SpaceBetween>
  );
}
