export const blockIdColId = 'blockId';
export const blockNameColId = 'blockName';
export const blockCategoryColId = 'blockCategory';
export const blockStatusColId = 'blockStatus';
export const createdOnColId = 'createdAt';
export const createdByColId = 'createdBy';
export const lastUpdatedByColId = 'updatedBy';

export const colIdToNameMap = {
  [blockIdColId]: 'Block ID',
  [blockNameColId]: 'Block name',
  [blockCategoryColId]: 'Category',
  [blockStatusColId]: 'Block status',
  [createdOnColId]: 'Created on',
  [createdByColId]: 'Created by',
  [lastUpdatedByColId]: 'Last updated by',
};
