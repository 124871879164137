import { Box } from '@cloudscape-design/components';
import { BoxProps } from '@cloudscape-design/components/box';
import React from 'react';

export interface IBoxElementProps {
  headerText: string;
  headerProps?: BoxProps;
  description: React.ReactNode;
  children?: React.ReactNode;
}

export default function BoxElement(props: IBoxElementProps) {
  return (
    <>
      <Box variant="awsui-key-label" {...props.headerProps}>
        {props.headerText}
      </Box>
      <Box variant="p">{props.description}</Box>
      {props.children}
    </>
  );
}

export function HeadBoxElement(props: IBoxElementProps) {
  return <BoxElement {...props} headerProps={{ variant: 'h3' }} />;
}
