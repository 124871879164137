import { Link } from '@cloudscape-design/components';
import { LinkProps } from '@cloudscape-design/components/link';
import { useNavigate } from 'react-router-dom';

export default function TALink(props: LinkProps) {
  const navigate = useNavigate();

  function handleClick(event: any) {
    // if the link is starting with the https or http then do the normal follow as this are external link
    // otherwise handle the links with react router dom.
    if (!event.detail.href.startsWith('https://') && !event.detail.href.startsWith('http://')) {
      event.preventDefault();
      navigate(event.detail.href);
    }
  }

  return (
    <Link className={'tw-link'} {...props} onFollow={handleClick}>
      {props.children}
    </Link>
  );
}
