import { StatusIndicatorProps } from '@cloudscape-design/components/status-indicator/internal';

import { CapabilityStatus } from '../types/enums';

interface ICapabilityStatusConfigProps {
  statusText: string;
  statusIndicator: StatusIndicatorProps.Type;
  isEditable: boolean;
}

export const CAPABILITY_STATUS_CONFIG: Record<CapabilityStatus, ICapabilityStatusConfigProps> = {
  [CapabilityStatus.REGISTERED]: {
    statusText: 'Not published',
    statusIndicator: 'pending',
    isEditable: true,
  },
  [CapabilityStatus.PUBLISHED]: {
    statusText: 'Published',
    statusIndicator: 'success',
    isEditable: true,
  },
  [CapabilityStatus.DEPRECATED]: {
    statusText: 'Deprecated',
    statusIndicator: 'stopped',
    isEditable: false,
  },
};
