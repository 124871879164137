import { AppLayout, ContentLayout, Flashbar, Grid, SpaceBetween, Spinner } from '@cloudscape-design/components';
import { FlashbarProps } from '@cloudscape-design/components/flashbar';
import React, { SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { HOME } from '../../../common/constants/route-paths';
import { AutomationType, ExecutionEnvironment, LoadingStatus, PipelineStatus } from '../../../common/types/enums';
import { checkIfClientIdAndRoleMissing } from '../../../common/utils';
import GlobalHelpPanel from '../../../components/GlobalHelpPanel';
import GlobalNavigationPanel from '../../../components/GlobalNavigationPanel';
import BreadcrumbLinkGroup from '../../../components/polaris/BreadcrumbLinkGroup';
import { AuthProfileSelector } from '../../../slices/AuthStateSlice';
import {
  getCompleteAutomationDetail,
  selectCreateAutomationPipelineResponse,
  selectCreateAutomationResponse,
  selectGetAutomationDetailResponse,
  selectUpdateAutomationDetailResponse,
} from '../../../slices/AutomationDetailSlice';
import { selectIsNavigationPanelOpen, setNavigationPanelOpen } from '../../../slices/SitePreferencesSlice';
import { AppDispatch } from '../../../store/store';
import AutomationBasicInfoContainer from './components/AutomationBasicInfoContainer';
import AutomationBuilderToolsSection from './components/AutomationBuilderToolsSection';
import AutomationBuildInfoContainer from './components/AutomationBuildInfoContainer';
import AutomationDetailContentHeader from './components/AutomationDetailContentHeader';
import AutomationDevelopmentTimelineContainer from './components/AutomationDevelopmentTimelineContainer';
import AutomationExecutionEnvironmentInfoContainer from './components/AutomationExecutionEnvironmentInfoContainer';
import AutomationPipelineInfoContainer from './components/AutomationPipelineInfoContainer';
import AutomationVersionInfoContainer from './components/AutomationVersionInfoContainer';

interface AutomationDetailPageContentProps {
  toolsOpen: boolean;
  setToolsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setHelpPanelContent: React.Dispatch<React.SetStateAction<React.ReactNode>>;
  setNotifications: React.Dispatch<SetStateAction<FlashbarProps.MessageDefinition[]>>;
  removeNotification: (notificationId: string) => void;
}

function AutomationDetailPageContent(props: AutomationDetailPageContentProps) {
  const currentAutomation = useSelector(selectGetAutomationDetailResponse);

  return currentAutomation.data ? (
    <ContentLayout header={<AutomationDetailContentHeader {...props} />}>
      <Grid gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}>
        <SpaceBetween size="xl">
          <AutomationBasicInfoContainer />
          {currentAutomation.data.automationDetail.automationType === AutomationType.WINDOWS_ASSISTANT &&
            currentAutomation.data.automationDetail.executionEnvironment === ExecutionEnvironment.WINDOWS_EC2 && (
              <AutomationExecutionEnvironmentInfoContainer />
            )}
          <AutomationVersionInfoContainer />
          {currentAutomation.data.pipelineDetail.pipelineStatus === PipelineStatus.SUCCESS && (
            <>
              <AutomationPipelineInfoContainer />
              {currentAutomation.data.automationDetail.automationType !== AutomationType.CLOUD && (
                <AutomationBuildInfoContainer />
              )}
            </>
          )}
          <AutomationDevelopmentTimelineContainer {...props} />
        </SpaceBetween>
        <AutomationBuilderToolsSection />
      </Grid>
    </ContentLayout>
  ) : (
    <div className="tw-page-loading-spinner">
      <Spinner size="large" />
    </div>
  );
}

export default function AutomationDetailPage() {
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const isNavigationPanelOpen = useSelector(selectIsNavigationPanelOpen);
  const [notifications, setNotifications] = useState<FlashbarProps.MessageDefinition[]>([]);
  const selectedAuthProfile = useSelector(AuthProfileSelector);
  const [toolsOpen, setToolsOpen] = useState(false);
  const [helpPanelContent, setHelpPanelContent] = useState<React.ReactNode>();
  const currentAutomation = useSelector(selectGetAutomationDetailResponse);
  const createAutomationResponse = useSelector(selectCreateAutomationResponse);
  const createAutomationPipelineResponse = useSelector(selectCreateAutomationPipelineResponse);
  const updateAutomationDetailResponse = useSelector(selectUpdateAutomationDetailResponse);

  const removeNotification = (notificationId: string) => {
    const filteredNotification = notifications.filter((notification) => notification.id !== notificationId);
    setNotifications(filteredNotification);
  };

  const navigationChangeHandler = () => {
    dispatch(setNavigationPanelOpen(!isNavigationPanelOpen));
  };

  const toolsChangeHandler = () => {
    setToolsOpen(!toolsOpen);
    setHelpPanelContent(<GlobalHelpPanel />);
  };

  useEffect(() => {
    if (checkIfClientIdAndRoleMissing(selectedAuthProfile)) {
      return;
    }

    void dispatch(getCompleteAutomationDetail(params.automationId));
  }, [selectedAuthProfile]);
  useEffect(() => {
    if (currentAutomation.error) {
      setNotifications((prevState) => [
        ...prevState,
        {
          header: 'Failed to load automation detail',
          type: 'error',
          content: currentAutomation.error,
          dismissible: true,
          dismissLabel: 'Dismiss error',
          onDismiss: () => removeNotification('api-error'),
          id: 'api-error',
        },
      ]);
    }
  }, [currentAutomation.error]);
  useEffect(() => {
    if (createAutomationResponse.status === LoadingStatus.SUCCESS && currentAutomation.data) {
      setNotifications((prevState) => [
        ...prevState,
        {
          header:
            'You’ve successfully created ' + currentAutomation.data.automationDetail.automationName + ' automation.',
          type: 'success',
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => removeNotification('api-success'),
          id: 'api-success',
        },
      ]);
    }
  }, [createAutomationResponse.status, currentAutomation.data]);
  useEffect(() => {
    if (createAutomationPipelineResponse.status === LoadingStatus.SUCCESS && currentAutomation.data) {
      setNotifications((prevState) => [
        ...prevState,
        {
          header:
            'You’ve successfully created pipeline for ' +
            currentAutomation.data.automationDetail.automationName +
            ' automation.',
          content: 'Information of code pipeline and code repository will be available within 5-10 minutes.',
          type: 'success',
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => removeNotification('api-success'),
          id: 'api-success',
        },
      ]);
    }
  }, [createAutomationPipelineResponse.status, currentAutomation.data]);
  useEffect(() => {
    if (updateAutomationDetailResponse.status === LoadingStatus.SUCCESS && currentAutomation.data) {
      setNotifications((prevState) => [
        ...prevState,
        {
          header:
            'You’ve successfully updated details for ' +
            currentAutomation.data.automationDetail.automationName +
            ' automation.',
          type: 'success',
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => removeNotification('api-success'),
          id: 'api-success',
        },
      ]);
    }
  }, [updateAutomationDetailResponse.status, currentAutomation.data]);

  return (
    <AppLayout
      headerSelector="#tw-header"
      notifications={<Flashbar items={notifications} />}
      stickyNotifications={false}
      content={
        <AutomationDetailPageContent
          toolsOpen={toolsOpen}
          setToolsOpen={setToolsOpen}
          setHelpPanelContent={setHelpPanelContent}
          setNotifications={setNotifications}
          removeNotification={removeNotification}
        />
      }
      navigation={<GlobalNavigationPanel />}
      navigationOpen={isNavigationPanelOpen}
      onNavigationChange={navigationChangeHandler}
      tools={helpPanelContent}
      toolsOpen={toolsOpen}
      onToolsChange={toolsChangeHandler}
      breadcrumbs={
        <BreadcrumbLinkGroup
          links={[
            {
              text: 'Home',
              href: HOME,
            },
            {
              text: `${currentAutomation?.data?.automationDetail.automationName || ''}`,
              href: '#',
            },
          ]}
        />
      }
    />
  );
}
