import { Button, Container, Grid, SpaceBetween, TextContent } from '@cloudscape-design/components';
import React from 'react';
import { useSelector } from 'react-redux';

import { getProcessBlockBuilderPagePath } from '../../../../../common/page-route-path';
import { selectGetAutomationDetailResponse } from '../../../../../slices/AutomationDetailSlice';

export default function AutomationBlockBuilder() {
  const currentAutomation = useSelector(selectGetAutomationDetailResponse);
  if (!currentAutomation?.data) {
    return;
  }

  const { automationDetail } = currentAutomation.data;

  return (
    <Container>
      <Grid gridDefinition={[{ colspan: 4 }, { colspan: 7, offset: 1 }]}>
        <img
          alt="Block Builder"
          src="https://m.media-amazon.com/images/G/01/taskless/workplace/block-builder-container-icon.png"
        />
        <TextContent>
          <h3>Block Builder</h3>
          <SpaceBetween size="xs">
            <small>Add blocks to the process map to build more easily</small>
            <Button
              href={getProcessBlockBuilderPagePath(
                automationDetail.processId,
                automationDetail.processClientId,
                automationDetail.automationId,
              )}
              iconAlign="right"
              iconName="external"
              target="_blank"
            >
              Open
            </Button>
          </SpaceBetween>
        </TextContent>
      </Grid>
    </Container>
  );
}
