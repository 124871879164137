import { useCollection } from '@cloudscape-design/collection-hooks';
import { Box, Button, Header, Pagination, SpaceBetween, Table, TextFilter } from '@cloudscape-design/components';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { CAPABILITY_STATUS_CONFIG } from '../../../../common/constants/capability-constants';
import { getMyBlockFunctionEditPagePath, getMyBlockFunctionRegisterPagePath } from '../../../../common/page-route-path';
import { CapabilityStatus, LoadingStatus, PageViewType } from '../../../../common/types/enums';
import { IListCapabilitiesForBlockData } from '../../../../common/types/interfaces';
import { getMatchesCountText } from '../../../../common/utils';
import { NoMatchState } from '../../../../components/polaris/TableElement';
import { AuthProfileSelector } from '../../../../slices/AuthStateSlice';
import {
  listCapabilitiesForBlock,
  selectListCapabilitiesForBlockResponse,
  selectUpdateCapabilityResponse,
  updateCapability,
} from '../../../../slices/CapabilityDetailSlice';
import { AppDispatch } from '../../../../store/store';
import {
  COLUMN_DEFINITIONS,
  DEFAULT_PREFERENCES,
  PAGINATION_LABELS,
  Preferences,
} from '../config/functions-table.config';

function EmptyState() {
  return (
    <Box textAlign="center" color="inherit">
      <SpaceBetween size="s">
        <b>No functions</b>
      </SpaceBetween>
    </Box>
  );
}

interface FunctionsTabHeaderProps {
  listCapabilitiesForBlockData: IListCapabilitiesForBlockData[];
  selectedCapability?: IListCapabilitiesForBlockData;
}

function FunctionsTabHeader({ listCapabilitiesForBlockData, selectedCapability }: FunctionsTabHeaderProps) {
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const updateCapabilityResult = useSelector(selectUpdateCapabilityResponse);

  const isCapabilityEditable = CAPABILITY_STATUS_CONFIG[selectedCapability?.capabilityStatus]?.isEditable;

  return (
    <Header
      counter={`(${listCapabilitiesForBlockData.length})`}
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Button
            ariaLabel="Edit function"
            href={getMyBlockFunctionEditPagePath(params.blockId, selectedCapability?.capabilityId)}
            disabled={!isCapabilityEditable}
          >
            Edit
          </Button>
          <Button
            ariaLabel="Publish function"
            disabled={selectedCapability?.capabilityStatus !== CapabilityStatus.REGISTERED}
            loading={updateCapabilityResult.status === LoadingStatus.PENDING}
            onClick={() => {
              void dispatch(
                updateCapability({
                  blockId: params.blockId,
                  capabilityId: selectedCapability?.capabilityId,
                  capabilityStatus: CapabilityStatus.PUBLISHED,
                }),
              );
            }}
          >
            Publish
          </Button>
          <Button ariaLabel="Register new function" href={getMyBlockFunctionRegisterPagePath(params.blockId)}>
            Register new function
          </Button>
        </SpaceBetween>
      }
    >
      Functions
    </Header>
  );
}

export default function MyBlockFunctionsTab() {
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const selectedAuthProfile = useSelector(AuthProfileSelector);
  const listCapabilitiesForBlockData = useSelector(selectListCapabilitiesForBlockResponse);
  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);
  const [selectedCapability, setSelectedCapability] = useState<IListCapabilitiesForBlockData>();
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    void dispatch(
      listCapabilitiesForBlock({
        blockId: params.blockId,
        clientId: selectedAuthProfile.ClientId,
        pageViewType: PageViewType.SELF_VIEW,
      }),
    );
  }, [selectedAuthProfile]);

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } =
    useCollection<IListCapabilitiesForBlockData>(listCapabilitiesForBlockData.data, {
      filtering: {
        empty: <EmptyState />,
        noMatch: (
          <NoMatchState
            clearFilterAction={() => {
              actions.setFiltering('');
            }}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {
        defaultState: {
          sortingColumn: COLUMN_DEFINITIONS[COLUMN_DEFINITIONS.length - 1],
          isDescending: true,
        },
      },
    });

  return (
    <Table
      columnDefinitions={COLUMN_DEFINITIONS}
      {...collectionProps}
      items={items}
      loading={listCapabilitiesForBlockData.status === LoadingStatus.PENDING}
      loadingText="Loading functions"
      resizableColumns
      header={
        <FunctionsTabHeader
          listCapabilitiesForBlockData={listCapabilitiesForBlockData.data}
          selectedCapability={selectedCapability}
        />
      }
      filter={
        <TextFilter
          {...filterProps}
          countText={getMatchesCountText(filteredItemsCount)}
          filteringAriaLabel="Filter functions"
          filteringPlaceholder="Find functions"
        />
      }
      visibleColumns={preferences.visibleContent}
      pagination={<Pagination {...paginationProps} ariaLabels={PAGINATION_LABELS} />}
      preferences={<Preferences preferences={preferences} setPreferences={setPreferences} />}
      selectionType="single"
      onSelectionChange={({ detail }) => {
        setSelectedCapability(detail.selectedItems[0]);
        setSelectedItems(detail.selectedItems);
      }}
      selectedItems={selectedItems}
    />
  );
}
