import './SignInContainer.scss';

import {
  AppLayout,
  Box,
  Button,
  ColumnLayout,
  Container,
  ContentLayout,
  Flashbar,
  SpaceBetween,
} from '@cloudscape-design/components';
import { FlashbarProps } from '@cloudscape-design/components/flashbar';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectIsNavigationPanelOpen, setNavigationPanelOpen } from '../../slices/SitePreferencesSlice';
import { AppDispatch } from '../../store/store';
import GlobalHelpPanel from '../GlobalHelpPanel';
import RegisterTeamForm from './components/RegisterTeamForm';
import SignInNavigationPanel from './SignInNavigationPanel';

interface ISignInComponentProps {
  onSignInClick: () => void;
}

export default function SignInContainer(props: ISignInComponentProps) {
  const dispatch = useDispatch<AppDispatch>();
  const isNavigationPanelOpen = useSelector(selectIsNavigationPanelOpen);
  const [notifications, setNotifications] = useState<FlashbarProps.MessageDefinition[]>([]);

  const removeNotification = (notificationId: string) => {
    const filteredNotification = notifications.filter((notification) => notification.id !== notificationId);
    setNotifications(filteredNotification);
  };

  const navigationChangeHandler = () => {
    dispatch(setNavigationPanelOpen(!isNavigationPanelOpen));
  };

  return (
    <AppLayout
      headerSelector="#tw-header"
      notifications={<Flashbar items={notifications} />}
      stickyNotifications={false}
      content={
        <ContentLayout>
          <Container
            header={
              <Box textAlign="left" variant="h2">
                Sign in to Developer Console
              </Box>
            }
          >
            <ColumnLayout borders="vertical" columns={2}>
              <Box textAlign="left" variant="p" fontSize="heading-xs">
                <SpaceBetween size="xl">
                  Sign in via Midway authentication
                  <Button variant="primary" onClick={props.onSignInClick}>
                    Sign In
                  </Button>
                </SpaceBetween>
              </Box>

              <RegisterTeamForm setNotifications={setNotifications} removeNotification={removeNotification} />
            </ColumnLayout>
          </Container>
        </ContentLayout>
      }
      navigation={<SignInNavigationPanel />}
      navigationOpen={isNavigationPanelOpen}
      onNavigationChange={navigationChangeHandler}
      tools={<GlobalHelpPanel />}
    />
  );
}
