import { useCollection } from '@cloudscape-design/collection-hooks';
import { Box, Button, Header, Pagination, SpaceBetween, Table, TextFilter } from '@cloudscape-design/components';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { BLOCK_TYPE_MAP } from '../../../../common/constants/block-constants';
import { getMyBlockCreatePagePath } from '../../../../common/page-route-path';
import { BlockType, LoadingStatus } from '../../../../common/types/enums';
import { IListBlocksForClientData } from '../../../../common/types/interfaces';
import { getMatchesCountText } from '../../../../common/utils';
import { NoMatchState } from '../../../../components/polaris/TableElement';
import { selectListBlocksForClientResponse } from '../../../../slices/BlockDetailSlice';
import { COLUMN_DEFINITIONS, DEFAULT_PREFERENCES, paginationLabels, Preferences } from '../config/blocks-table.config';

function EmptyState({ blockType }: { blockType: BlockType }) {
  return (
    <Box textAlign="center" color="inherit">
      <SpaceBetween size="s">
        <b>No {BLOCK_TYPE_MAP[blockType].pluralName}</b>
      </SpaceBetween>
    </Box>
  );
}

interface IMyBlocksPageTableProps {
  blockType: BlockType;
  blockList: IListBlocksForClientData[];
}

export default function MyBlocksPageTable({ blockType, blockList }: IMyBlocksPageTableProps) {
  const listBlocksForClientResult = useSelector(selectListBlocksForClientResponse);
  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } =
    useCollection<IListBlocksForClientData>(blockList, {
      filtering: {
        empty: <EmptyState blockType={blockType} />,
        noMatch: (
          <NoMatchState
            clearFilterAction={() => {
              actions.setFiltering('');
            }}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {
        defaultState: {
          sortingColumn: COLUMN_DEFINITIONS[COLUMN_DEFINITIONS.length - 1],
          isDescending: true,
        },
      },
    });

  return (
    <Table
      columnDefinitions={COLUMN_DEFINITIONS}
      {...collectionProps}
      items={items}
      loading={listBlocksForClientResult.status === LoadingStatus.PENDING}
      loadingText={`Loading ${BLOCK_TYPE_MAP[blockType].pluralName} details`}
      resizableColumns
      header={
        <Header
          counter={`(${blockList.length})`}
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button ariaLabel="create-block" href={getMyBlockCreatePagePath(blockType)} iconAlign="right">
                Create a {BLOCK_TYPE_MAP[blockType].name}
              </Button>
            </SpaceBetween>
          }
        >
          {BLOCK_TYPE_MAP[blockType].pascalPluralName}
        </Header>
      }
      filter={
        <TextFilter
          {...filterProps}
          countText={getMatchesCountText(filteredItemsCount)}
          filteringAriaLabel={`Filter ${BLOCK_TYPE_MAP[blockType].pluralName}`}
          filteringPlaceholder={`Find ${BLOCK_TYPE_MAP[blockType].pluralName}`}
        />
      }
      visibleColumns={preferences.visibleContent}
      pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
      preferences={<Preferences preferences={preferences} setPreferences={setPreferences} />}
    />
  );
}
