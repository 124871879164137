import { InferType, object, string } from 'yup';
import { UseFormProps } from 'react-hook-form/dist/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { AWS_ACCOUNT_ID } from '../constants/form-constants';

const AutomationPipelineSchema = object({
  [AWS_ACCOUNT_ID]: string()
    .required()
    .matches(/^\d{12}$/, 'It must be a valid AWS account ID')
    .label('AWS account ID'),
});

export type AutomationPipelineSchemaType = InferType<typeof AutomationPipelineSchema>;

export const AutomationPipelineFormProps: UseFormProps<AutomationPipelineSchemaType, any> = {
  mode: 'onSubmit',
  reValidateMode: 'onChange',
  resolver: yupResolver(AutomationPipelineSchema),
};
