import { Alert, AppLayout, Button, ContentLayout, Spinner } from '@cloudscape-design/components';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { AutomationTypeDisplayNameMap } from '../../../common/constants/automation-constants';
import { HOME } from '../../../common/constants/route-paths';
import { getAutomationDetailPagePath } from '../../../common/page-route-path';
import { AutomationType, LoadingStatus, PipelineStatus } from '../../../common/types/enums';
import GlobalHelpPanel from '../../../components/GlobalHelpPanel';
import GlobalNavigationPanel from '../../../components/GlobalNavigationPanel';
import BreadcrumbLinkGroup from '../../../components/polaris/BreadcrumbLinkGroup';
import { AuthProfileSelector } from '../../../slices/AuthStateSlice';
import {
  getAutomationDetail,
  selectCreateAutomationPipelineResponse,
  selectGetAutomationDetailResponse,
} from '../../../slices/AutomationDetailSlice';
import { selectIsNavigationPanelOpen, setNavigationPanelOpen } from '../../../slices/SitePreferencesSlice';
import { AppDispatch } from '../../../store/store';
import CreateAutomationPipelineForm from './component/CreateAutomationPipelineForm';

function CreateAutomationPipelinePageContent() {
  const currentAutomation = useSelector(selectGetAutomationDetailResponse);

  return currentAutomation.data ? (
    currentAutomation.data.automationDetail.automationType === AutomationType.WINDOWS_ASSISTANT ? (
      !currentAutomation.data.pipelineDetail.pipelineStatus ||
      currentAutomation.data.pipelineDetail.pipelineStatus === PipelineStatus.FAILED ? (
        <ContentLayout>
          <CreateAutomationPipelineForm />
        </ContentLayout>
      ) : currentAutomation.data.pipelineDetail.pipelineStatus === PipelineStatus.SUCCESS ? (
        <Alert
          className="tw-mt5"
          statusIconAriaLabel="Success"
          type="success"
          header="Pipeline creation completed"
          action={
            <Button href={getAutomationDetailPagePath(currentAutomation.data.automationDetail.automationId)}>
              Go back to automation details page
            </Button>
          }
        >
          Pipeline is already created for {currentAutomation.data.automationDetail.automationName} automation.
        </Alert>
      ) : (
        <Alert
          className="tw-mt5"
          statusIconAriaLabel="Info"
          type="info"
          header="Pipeline creation in progress"
          action={
            <Button href={getAutomationDetailPagePath(currentAutomation.data.automationDetail.automationId)}>
              Go back to automation details page
            </Button>
          }
        >
          Pipeline creation is already in progress for {currentAutomation.data.automationDetail.automationName}{' '}
          automation. It may take around 5-10 mins for pipeline creation to complete.
        </Alert>
      )
    ) : (
      <Alert
        className="tw-mt5"
        statusIconAriaLabel="Error"
        type="error"
        header="Unsupported pipeline creation request"
        action={
          <Button href={getAutomationDetailPagePath(currentAutomation.data.automationDetail.automationId)}>
            Go back to automation details page
          </Button>
        }
      >
        Pipeline creation is not supported for{' '}
        {AutomationTypeDisplayNameMap[currentAutomation.data.automationDetail.automationType]} automations.
      </Alert>
    )
  ) : (
    <div className="tw-page-loading-spinner">
      <Spinner size="large" />
    </div>
  );
}

export default function CreateAutomationPipelinePage() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const isNavigationPanelOpen = useSelector(selectIsNavigationPanelOpen);
  const selectedAuthProfile = useSelector(AuthProfileSelector);
  const currentAutomation = useSelector(selectGetAutomationDetailResponse);
  const createAutomationPipelineResponse = useSelector(selectCreateAutomationPipelineResponse);

  const navigationChangeHandler = () => {
    dispatch(setNavigationPanelOpen(!isNavigationPanelOpen));
  };

  useEffect(() => {
    void dispatch(getAutomationDetail(params.automationId));
  }, [selectedAuthProfile]);

  useEffect(() => {
    if (createAutomationPipelineResponse.status === LoadingStatus.SUCCESS) {
      navigate(getAutomationDetailPagePath(params.automationId));
    }
  }, [createAutomationPipelineResponse.status]);

  return (
    <AppLayout
      headerSelector="#tw-header"
      content={<CreateAutomationPipelinePageContent />}
      contentType="form"
      navigation={<GlobalNavigationPanel />}
      navigationOpen={isNavigationPanelOpen}
      onNavigationChange={navigationChangeHandler}
      tools={<GlobalHelpPanel />}
      breadcrumbs={
        <BreadcrumbLinkGroup
          links={
            currentAutomation?.data
              ? [
                  {
                    text: 'Home',
                    href: HOME,
                  },
                  {
                    text: currentAutomation.data.automationDetail.automationName,
                    href: getAutomationDetailPagePath(params.automationId),
                  },
                  {
                    text: 'Create pipeline',
                    href: '#',
                  },
                ]
              : [
                  {
                    text: 'Home',
                    href: HOME,
                  },
                  {
                    text: '',
                    href: '#',
                  },
                ]
          }
        />
      }
    />
  );
}
