import { CodeEditorProps } from '@cloudscape-design/components';

export const AuthConstants = {
  ClientIdAndRoleSeparationDelimiter: '#',
  MultipleClientIdAndRoleSeparationDelimiter: ',',
  ClientIdAndRoleSeparationUIDelimiter: ' - ',
  AuthorizationAndAuthProfileSeparationDelimiter: '##',
  WorkplaceClientIdPrefix: 'Workplace_',
};

export const CODE_EDITOR_I18N_STRINGS: CodeEditorProps.I18nStrings = {
  loadingState: 'Loading code editor',
  errorState: 'There was an error loading the code editor.',
  errorStateRecovery: 'Retry',
  editorGroupAriaLabel: 'Code editor',
  statusBarGroupAriaLabel: 'Status bar',
  cursorPosition: (row, column) => `Ln ${row}, Col ${column}`,
  errorsTab: 'Errors',
  warningsTab: 'Warnings',
  preferencesButtonAriaLabel: 'Preferences',
  paneCloseButtonAriaLabel: 'Close',
  preferencesModalHeader: 'Preferences',
  preferencesModalCancel: 'Cancel',
  preferencesModalConfirm: 'Confirm',
  preferencesModalWrapLines: 'Wrap lines',
  preferencesModalTheme: 'Theme',
  preferencesModalLightThemes: 'Light themes',
  preferencesModalDarkThemes: 'Dark themes',
};

export const QUERY_PARAMS = {
  clientId: 'clientId',
  role: 'role',
  tabId: 'tabId',
  q: 'q', // generic query param for all the search operations
};

export const AUTO_DEV_AUTOMATION_PREVIEW_TICKET_TEMPLATE_URL =
  'https://t.corp.amazon.com/create/templates/a55c155e-89a4-44ff-a3f6-b240660f2796';
export const CLOUD_AUTOMATION_ONBOARDING_TICKET_TEMPLATE_URL =
  'https://t.corp.amazon.com/create/templates/7a05dfe5-08e9-406b-a133-9476887f716b';
