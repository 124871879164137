import axios, { AxiosError } from 'axios';

import { ResourceType } from '../common/types/enums';
import { infraManagerApiEndpoint } from '../config/endpoints';
import { store } from '../store/store';

interface ISynchronizeResourceAccountException {
  errorCode?: string;
  message: string;
}

export enum SynchronizeResourceAccountExceptionErrorCode {
  ACCOUNT_ONBOARDING_STACK_DEPLOYED_INCORRECTLY = 'ACCOUNT_ONBOARDING_STACK_DEPLOYED_INCORRECTLY',
}

export interface ISynchronizeResourceAccountRequest {
  awsAccountId: string;
  resourceType: ResourceType;
}

interface ISynchronizeResourceAccountResponse {
  isSynchronized: boolean;
  isSynchronizationTriggered: boolean;
  stackUrl?: string;
}

export async function synchronizeResourceAccountApi(
  request: ISynchronizeResourceAccountRequest,
): Promise<ISynchronizeResourceAccountResponse> {
  try {
    const response = await axios.post<ISynchronizeResourceAccountResponse>(
      `${infraManagerApiEndpoint}/synchronize-resource-account`,
      {
        ...request,
        devTeamId: store.getState().authState.selectedAuthProfile.ClientId,
      },
    );
    return response.data;
  } catch (e) {
    if ((e as AxiosError)?.response?.data) {
      if (
        ((e as AxiosError)?.response?.data as ISynchronizeResourceAccountException)?.errorCode ===
        SynchronizeResourceAccountExceptionErrorCode.ACCOUNT_ONBOARDING_STACK_DEPLOYED_INCORRECTLY
      ) {
        throw new Error(SynchronizeResourceAccountExceptionErrorCode.ACCOUNT_ONBOARDING_STACK_DEPLOYED_INCORRECTLY);
      }
      throw (e as AxiosError).response.data;
    }
    throw e;
  }
}

interface ICreateAutomationPipelineResponse {
  automationId: string;
  pipelineDeploymentTriggered: false;
}

export async function createAutomationPipelineApi(pipelineDetails: Record<string, string>) {
  try {
    const response = await axios.post<ICreateAutomationPipelineResponse>(
      `${infraManagerApiEndpoint}/create-automation-pipeline`,
      {
        ...pipelineDetails,
        devTeamId: store.getState().authState.selectedAuthProfile.ClientId,
      },
    );
    if (response.data.pipelineDeploymentTriggered) {
      return response.data;
    } else {
      throw new Error('The automation pipeline for this process is already created.');
    }
  } catch (e) {
    if ((e as AxiosError)?.response?.data) {
      throw (e as AxiosError).response.data;
    }
    throw e;
  }
}

export interface ICreateBlockPipelineRequest {
  blockId: string;
  awsAccountId: string;
}

export async function createBlockPipelineApi(request: ICreateBlockPipelineRequest) {
  try {
    const response = await axios.post<VoidFunction>(`${infraManagerApiEndpoint}/create-block-pipeline`, {
      ...request,
      devTeamId: store.getState().authState.selectedAuthProfile.ClientId,
    });

    return response.data;
  } catch (error) {
    if ((error as AxiosError)?.response?.data) {
      throw (error as AxiosError).response.data;
    }
    throw error;
  }
}
