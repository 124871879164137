import '../BlocksHomePage.scss';

import { Box, Cards, Grid, SpaceBetween } from '@cloudscape-design/components';
import React from 'react';

import { BLOCK_CATEGORY_MAP } from '../../../../common/constants/block-constants';
import { LIBRARY_BLOCKS } from '../../../../common/constants/route-paths';
import { getLibraryBlocksCategoryPagePath } from '../../../../common/page-route-path';
import TALink from '../../../../components/polaris/TALink';

export default function BlocksHomePageContent() {
  return (
    <Box margin={{ bottom: 'l' }}>
      <div className="tw-home-header">
        <Box padding={{ vertical: 'xxxl', horizontal: 's' }}>
          <Grid gridDefinition={[{ offset: { l: 2, xxs: 1 } }]}>
            <div className="tw-home-header-title">
              <Box variant="h1" fontWeight="heavy" padding="n" fontSize="display-l" color="inherit">
                Blocks Marketplace
              </Box>
              <Box variant="p" padding={{ top: 's' }} fontWeight="normal">
                <span className="tw-home-header-sub-title">
                  Explore pre-built components to streamline development and deliver automations at rapid pace.
                </span>
              </Box>
            </div>
          </Grid>
        </Box>
      </div>
      <Box padding={{ top: 'xxxl', horizontal: 's' }}>
        <Grid gridDefinition={[{ offset: { l: 1, xxs: 0 } }]}>
          <SpaceBetween size="l">
            <TALink href={LIBRARY_BLOCKS} fontSize={'heading-xl'}>
              Libraries
            </TALink>
            <div className="tw-home-cards">
              <Cards
                cardDefinition={{
                  header: (category) => {
                    return <img className="tw-home-category-logo" src={BLOCK_CATEGORY_MAP[category].iconUrl} alt="" />;
                  },
                  sections: [
                    {
                      content: (category) => {
                        return (
                          <a className="tw-home-link" href={getLibraryBlocksCategoryPagePath(category.toLowerCase())}>
                            {BLOCK_CATEGORY_MAP[category].name}
                          </a>
                        );
                      },
                    },
                  ],
                }}
                cardsPerRow={[{ cards: 6 }]}
                items={Object.keys(BLOCK_CATEGORY_MAP)}
              />
            </div>
          </SpaceBetween>
        </Grid>
      </Box>
    </Box>
  );
}
