export const processNameColId = 'processName';
export const requestedDateColId = 'createdOn';
export const requestedByColId = 'createdBy';
export const expectedAnnualSavingsColId = 'expectedAnnualSavings';
export const requestIdColId = 'requestId';
export const requestOwnerClientIdColId = 'requestOwnerClientId';
export const colIdToNameMap = {
    [processNameColId]: 'Process name',
    [requestedDateColId]: 'Request date',
    [requestedByColId]: 'Requested by',
    [expectedAnnualSavingsColId]: 'Expected annual saving (FTE)',
    [requestIdColId]: 'Request detail',
    [requestOwnerClientIdColId]: 'Requester team'
};
