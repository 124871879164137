import { Badge, ColumnLayout, Container, Header, SpaceBetween } from '@cloudscape-design/components';
import React from 'react';
import { useSelector } from 'react-redux';

import BoxElement from '../../../../components/polaris/BoxElement';
import { selectGetBlockResponse } from '../../../../slices/BlockDetailSlice';

export default function BlockAddnInfoContainer() {
  const currentBlock = useSelector(selectGetBlockResponse);

  return (
    currentBlock.data && (
      <Container header={<Header variant="h2">Additional Information</Header>}>
        <ColumnLayout borders="vertical" columns={1}>
          <BoxElement headerText="Published by" description={currentBlock.data.ownerClientId} />
          <BoxElement headerText="Category" description={currentBlock.data.blockCategory || '-'} />
          <BoxElement
            headerText="Tags"
            description={
              <SpaceBetween direction="horizontal" size="xxs">
                {(currentBlock.data.tags || []).map((tag) => (
                  <Badge key={tag}>{tag}</Badge>
                ))}
              </SpaceBetween>
            }
          />
        </ColumnLayout>
      </Container>
    )
  );
}
