import { AppLayout, ContentLayout, Header, SpaceBetween } from '@cloudscape-design/components';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { BLOCK_TYPE_MAP } from '../../../common/constants/block-constants';
import { MY_BLOCKS } from '../../../common/constants/route-paths';
import { getMyBlockLibraryDetailPagePath } from '../../../common/page-route-path';
import { BlockDetailTabId, BlockType, LoadingStatus } from '../../../common/types/enums';
import GlobalHelpPanel from '../../../components/GlobalHelpPanel';
import GlobalNavigationPanel from '../../../components/GlobalNavigationPanel';
import BreadcrumbLinkGroup from '../../../components/polaris/BreadcrumbLinkGroup';
import { selectCreateBlockForClient } from '../../../slices/BlockDetailSlice';
import { selectIsNavigationPanelOpen, setNavigationPanelOpen } from '../../../slices/SitePreferencesSlice';
import { AppDispatch } from '../../../store/store';
import CreateBlockForm from './components/CreateBlockForm';

interface ICreateBlockPageContentProps {
  blockType: BlockType;
}

function CreateBlockPageContent({ blockType }: ICreateBlockPageContentProps) {
  return (
    <ContentLayout header={<Header variant="h1">Create a {BLOCK_TYPE_MAP[blockType].name}</Header>}>
      <SpaceBetween size="xl">
        <CreateBlockForm blockType={blockType} />
      </SpaceBetween>
    </ContentLayout>
  );
}

export default function CreateBlockPage() {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const isNavigationPanelOpen = useSelector(selectIsNavigationPanelOpen);
  const createBlockForClientResponse = useSelector(selectCreateBlockForClient);

  let blockType = params.blockType?.toUpperCase() as BlockType;
  blockType = blockType in BlockType ? blockType : BlockType.LIBRARY;

  const navigationChangeHandler = () => {
    dispatch(setNavigationPanelOpen(!isNavigationPanelOpen));
  };

  useEffect(() => {
    if (createBlockForClientResponse.status === LoadingStatus.SUCCESS) {
      navigate(getMyBlockLibraryDetailPagePath(createBlockForClientResponse.blockId, BlockDetailTabId.PIPELINE));
    }
  }, [createBlockForClientResponse?.status]);

  return (
    <AppLayout
      headerSelector="#tw-header"
      content={<CreateBlockPageContent blockType={blockType} />}
      contentType="form"
      navigation={<GlobalNavigationPanel />}
      navigationOpen={isNavigationPanelOpen}
      onNavigationChange={navigationChangeHandler}
      tools={<GlobalHelpPanel />}
      breadcrumbs={
        <BreadcrumbLinkGroup
          links={[
            {
              text: 'My blocks',
              href: MY_BLOCKS,
            },
            {
              text: `Create a ${BLOCK_TYPE_MAP[blockType].name}`,
              href: '#',
            },
          ]}
        />
      }
    />
  );
}
