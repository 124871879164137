import { BadgeProps } from '@cloudscape-design/components/badge';

import { AutomationRequestStatus } from '../enums/automation-request-enums';

interface IAutomationRequestStatusConfig {
  automationRequestStatus: AutomationRequestStatus;
  statusDisplayText: string;
  badgeProps: BadgeProps;
}

export const AutomationRequestStatusConfig: Partial<Record<AutomationRequestStatus, IAutomationRequestStatusConfig>> = {
  UNDER_DEV: {
    automationRequestStatus: AutomationRequestStatus.UNDER_DEV,
    statusDisplayText: 'Development',
    badgeProps: {
      color: 'grey',
    },
  },
  UAT: {
    automationRequestStatus: AutomationRequestStatus.UAT,
    statusDisplayText: 'UAT',
    badgeProps: {
      color: 'blue',
    },
  },
  PROD: {
    automationRequestStatus: AutomationRequestStatus.PROD,
    statusDisplayText: 'Prod',
    badgeProps: {
      color: 'green',
    },
  },
};
