import { AppLayout, ContentLayout, Spinner } from '@cloudscape-design/components';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { MY_BLOCKS } from '../../../common/constants/route-paths';
import { getMyBlockFunctionDetailPagePath, getMyBlockLibraryDetailPagePath } from '../../../common/page-route-path';
import { BlockDetailTabId, LoadingStatus, PageViewType } from '../../../common/types/enums';
import GlobalHelpPanel from '../../../components/GlobalHelpPanel';
import GlobalNavigationPanel from '../../../components/GlobalNavigationPanel';
import BreadcrumbLinkGroup from '../../../components/polaris/BreadcrumbLinkGroup';
import { AuthProfileSelector } from '../../../slices/AuthStateSlice';
import { listBlockArtifacts } from '../../../slices/BlockDetailSlice';
import {
  getCapability,
  selectGetCapabilityResponse,
  selectUpdateCapabilityResponse,
} from '../../../slices/CapabilityDetailSlice';
import { selectIsNavigationPanelOpen, setNavigationPanelOpen } from '../../../slices/SitePreferencesSlice';
import { AppDispatch } from '../../../store/store';
import MyBlockFunctionEditForm from './component/MyBlockFunctionEditForm';

function MyBlockFunctionEditPageContent() {
  const getCapabilityData = useSelector(selectGetCapabilityResponse);

  return getCapabilityData.data ? (
    <ContentLayout>
      <MyBlockFunctionEditForm />
    </ContentLayout>
  ) : (
    <div className="tw-page-loading-spinner">
      <Spinner size="large" />
    </div>
  );
}

export default function MyBlockFunctionEditPage() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const isNavigationPanelOpen = useSelector(selectIsNavigationPanelOpen);
  const selectedAuthProfile = useSelector(AuthProfileSelector);
  const getCapabilityData = useSelector(selectGetCapabilityResponse);
  const selectUpdateCapabilityResult = useSelector(selectUpdateCapabilityResponse);

  const navigationChangeHandler = () => {
    dispatch(setNavigationPanelOpen(!isNavigationPanelOpen));
  };

  useEffect(() => {
    void dispatch(
      getCapability({
        blockId: params.blockId,
        capabilityId: params.capabilityId,
        capabilityVersion: 'LATEST',
        clientId: selectedAuthProfile.ClientId,
        pageViewType: PageViewType.SELF_VIEW,
      }),
    );
    void dispatch(
      listBlockArtifacts({
        blockId: params.blockId,
        clientId: selectedAuthProfile.ClientId,
        pageViewType: PageViewType.SELF_VIEW,
      }),
    );
  }, [selectedAuthProfile]);

  useEffect(() => {
    if (selectUpdateCapabilityResult.status === LoadingStatus.SUCCESS) {
      navigate(getMyBlockLibraryDetailPagePath(params.blockId, BlockDetailTabId.FUNCTIONS));
    }
  }, [selectUpdateCapabilityResult.status]);

  return (
    <AppLayout
      headerSelector="#tw-header"
      content={<MyBlockFunctionEditPageContent />}
      contentType="form"
      navigation={<GlobalNavigationPanel />}
      navigationOpen={isNavigationPanelOpen}
      onNavigationChange={navigationChangeHandler}
      tools={<GlobalHelpPanel />}
      breadcrumbs={
        <BreadcrumbLinkGroup
          links={
            getCapabilityData?.data
              ? [
                  {
                    text: 'My blocks',
                    href: MY_BLOCKS,
                  },
                  {
                    text: getCapabilityData.data.blockDetail.blockName,
                    href: getMyBlockLibraryDetailPagePath(params.blockId, BlockDetailTabId.FUNCTIONS),
                  },
                  {
                    text: getCapabilityData.data.capabilityDetail.capabilityName,
                    href: getMyBlockFunctionDetailPagePath(params.blockId, params.capabilityId),
                  },
                  {
                    text: 'Edit',
                    href: '#',
                  },
                ]
              : [
                  {
                    text: 'My blocks',
                    href: MY_BLOCKS,
                  },
                  {
                    text: '',
                    href: '#',
                  },
                ]
          }
        />
      }
    />
  );
}
