import { Button, Header, SpaceBetween } from '@cloudscape-design/components';
import { FlashbarProps } from '@cloudscape-design/components/flashbar';
import React, { SetStateAction, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { updateCapabilityApi } from '../../../../api/capability-registry-api';
import { getMyBlockFunctionEditPagePath } from '../../../../common/page-route-path';
import { CapabilityStatus, PageViewType } from '../../../../common/types/enums';
import { AuthProfileSelector } from '../../../../slices/AuthStateSlice';
import { getCapability, selectGetCapabilityResponse } from '../../../../slices/CapabilityDetailSlice';
import { AppDispatch } from '../../../../store/store';

interface MyBlockFunctionViewPageContentHeaderProps {
  setNotifications: React.Dispatch<SetStateAction<FlashbarProps.MessageDefinition[]>>;
  removeNotification: (notificationId: string) => void;
}

export default function MyBlockFunctionViewPageContentHeader(props: MyBlockFunctionViewPageContentHeaderProps) {
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const [publishButtonLoading, setPublishButtonLoading] = useState(false);
  const selectedAuthProfile = useSelector(AuthProfileSelector);
  const getCapabilityData = useSelector(selectGetCapabilityResponse);

  async function handlePublishButton() {
    try {
      setPublishButtonLoading(true);

      const updateCapabilityResponse = await updateCapabilityApi({
        blockId: params.blockId,
        capabilityId: params.capabilityId,
        capabilityStatus: CapabilityStatus.PUBLISHED,
      });
      void dispatch(
        getCapability({
          blockId: params.blockId,
          capabilityId: params.capabilityId,
          clientId: selectedAuthProfile.ClientId,
          capabilityVersion: 'LATEST',
          pageViewType: PageViewType.SELF_VIEW,
        }),
      );
      props.setNotifications((prevState) => [
        ...prevState,
        {
          header: `You have successfully published function ${updateCapabilityResponse?.capabilityName}.`,
          type: 'success',
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => props.removeNotification('api-success'),
          id: 'api-success',
        },
      ]);
    } catch (error: any) {
      props.setNotifications((prevState) => [
        ...prevState,
        {
          header: error?.message || 'Unable to update/publish block. Please try again.',
          type: 'error',
          dismissible: true,
          dismissLabel: 'Dismiss error',
          onDismiss: () => props.removeNotification('api-error'),
          id: 'api-error',
        },
      ]);
    } finally {
      setPublishButtonLoading(false);
    }
  }

  return (
    getCapabilityData.data && (
      <Header
        variant="h1"
        actions={
          <SpaceBetween direction="horizontal" size="l">
            {getCapabilityData.data.capabilityDetail.capabilityStatus === CapabilityStatus.REGISTERED && (
              <Button
                ariaLabel="Capability publish button"
                variant="primary"
                loading={publishButtonLoading}
                onClick={handlePublishButton}
              >
                Publish
              </Button>
            )}
            <Button
              ariaLabel="Capability edit button"
              href={getMyBlockFunctionEditPagePath(params.blockId, params.capabilityId)}
            >
              Edit
            </Button>
          </SpaceBetween>
        }
      >
        <SpaceBetween direction="vertical" size="s">
          {getCapabilityData.data.capabilityDetail.capabilityName}
        </SpaceBetween>
      </Header>
    )
  );
}
