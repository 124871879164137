import { useCollection } from '@cloudscape-design/collection-hooks';
import { Header, Pagination, Table, TextFilter } from '@cloudscape-design/components';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import IAutomationRequestDetails from '../../../../common/interfaces/AutomationRequestDetails';
import { LoadingStatus } from '../../../../common/types/enums';
import { getMatchesCountText } from '../../../../common/utils';
import { EmptyState, NoMatchState } from '../../../../components/polaris/TableElement';
import { selectListAutomationRequestsResponse } from '../../../../slices/AutomationRequestSlice';
import { requestedDateColId } from '../config/ongoing-request-column-constants';
import {
  COLUMN_DEFINITIONS,
  DEFAULT_PREFERENCES,
  paginationLabels,
  Preferences,
} from '../config/ongoing-request-table.config';

interface IAutomationRequestDetailProps {
  requestsList: IAutomationRequestDetails[];
}

export default function AutomationOngoingRequestsTable({ requestsList }: IAutomationRequestDetailProps) {
  const listAutomationRequestsData = useSelector(selectListAutomationRequestsResponse);
  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } =
    useCollection<IAutomationRequestDetails>(requestsList, {
      filtering: {
        empty: (
          <EmptyState>
            <b>No ongoing requests</b>
          </EmptyState>
        ),
        noMatch: (
          <NoMatchState
            clearFilterAction={() => {
              actions.setFiltering('');
            }}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {
        defaultState: {
          sortingColumn: COLUMN_DEFINITIONS.find((column) => column.id === requestedDateColId),
          isDescending: true,
        },
      },
    });

  return (
    <Table
      columnDefinitions={COLUMN_DEFINITIONS}
      {...collectionProps}
      items={items}
      loading={listAutomationRequestsData.status === LoadingStatus.PENDING}
      loadingText="Loading automation ongoing requests"
      resizableColumns
      header={<Header counter={`(${requestsList.length})`}>Ongoing requests</Header>}
      filter={
        <TextFilter
          {...filterProps}
          countText={getMatchesCountText(filteredItemsCount)}
          filteringAriaLabel="Filter ongoing requests"
          filteringPlaceholder="Find ongoing requests"
        />
      }
      visibleColumns={preferences.visibleContent}
      pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
      preferences={<Preferences preferences={preferences} setPreferences={setPreferences} />}
    />
  );
}
