export enum AuthStates {
  SIGNED_IN = 'signedIn',
  SIGN_IN_ONGOING = 'signIn_OnGoing',
  SIGNED_OUT = 'signedOut',
}

export enum PipelineBuildStatus {
  FAIL = 'FAILED',
  SUCCESS = 'SUCCEEDED',
}

export enum LoadingStatus {
  IDLE,
  PENDING,
  FAILED,
  SUCCESS,
}

export enum ArtifactEnvironment {
  BETA = 'BETA',
  PROD = 'PROD',
}

export enum ArtifactPlatform {
  WIN_X64 = 'WIN_X64',
  WIN_X86 = 'WIN_X86',
  STEP_FUNCTION = 'STEP_FUNCTION',
}

export enum AutomationStatus {
  DEVELOPMENT = 'DEVELOPMENT',
  UAT = 'UAT',
  POST_MAINTENANCE_UAT = 'POST_MAINTENANCE_UAT',
  PROD = 'PROD',
  MAINTENANCE = 'MAINTENANCE',
}

export enum AutomationType {
  WINDOWS_ASSISTANT = 'WINDOWS_ASSISTANT',
  AUTO_DEV = 'AUTO_DEV',
  CLOUD = 'CLOUD',
}

export enum ExecutionEnvironment {
  WINDOWS_DESKTOP = 'WINDOWS_DESKTOP',
  WINDOWS_EC2 = 'WINDOWS_EC2',
  AWS_SFN = 'AWS_SFN',
}

export enum WindowsEC2SourceType {
  AWS_EC2_INSTANCE = 'AWS_EC2_INSTANCE',
  AWS_EC2_AMI = 'AWS_EC2_AMI',
}

export enum BlockType {
  LIBRARY = 'LIBRARY',
  SERVICE = 'SERVICE',
}

export enum BlockStatus {
  REGISTERED = 'REGISTERED',
  PUBLISHED = 'PUBLISHED',
  DEPRECATED = 'DEPRECATED',
}

export enum PageViewType {
  NORMAL_VIEW = 'NORMAL_VIEW',
  SELF_VIEW = 'SELF_VIEW',
}

export enum BlockDetailTabId {
  EXECUTABLES = 'executables',
  PIPELINE = 'pipeline',
  FUNCTIONS = 'functions',
}

export enum PipelineStatus {
  REQUESTED = 'REQUESTED',
  INITIATED = 'INITIATED',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export enum ArtifactStatus {
  REGISTERED = 'REGISTERED',
  PUBLISHED = 'PUBLISHED',
  INITIATED = 'INITIATED',
  DEPRECATED = 'DEPRECATED',
}

export enum Framework {
  DOTNET_FRAMEWORK_472 = 'DOTNET_FRAMEWORK_472',
}

export enum ResourceType {
  AUTOMATION = 'AUTOMATION',
  BLOCK = 'BLOCK',
}

export enum CapabilityType {
  FUNCTION = 'FUNCTION',
  API_ENDPOINT = 'API_ENDPOINT',
}

export enum CapabilityStatus {
  REGISTERED = 'REGISTERED',
  PUBLISHED = 'PUBLISHED',
  DEPRECATED = 'DEPRECATED',
}
