import { AppLayout, ContentLayout, Flashbar, Header, SpaceBetween } from '@cloudscape-design/components';
import { FlashbarProps } from '@cloudscape-design/components/flashbar';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { HOME, SDK_SEARCH_PAGE } from '../../../common/constants/route-paths';
import GlobalHelpPanel from '../../../components/GlobalHelpPanel';
import GlobalNavigationPanel from '../../../components/GlobalNavigationPanel';
import BreadcrumbLinkGroup from '../../../components/polaris/BreadcrumbLinkGroup';
import { searchSDKDocWithIntent, selectSearchSDKDocWithIntentResults } from '../../../slices/SDKSearchSlice';
import { selectIsNavigationPanelOpen, setNavigationPanelOpen } from '../../../slices/SitePreferencesSlice';
import { AppDispatch } from '../../../store/store';
import SDKSearchResults from './components/SDKSearchResults';
import SearchBar from './components/SearchBar';
import { SEARCH_QUERY_PARAM_KEY } from './sdk-search.contants';

function SDKDocSearchPageContent() {
  return (
    <ContentLayout header={<Header>Search documentation</Header>}>
      <SpaceBetween size="xl">
        <SearchBar />
        <SDKSearchResults />
      </SpaceBetween>
    </ContentLayout>
  );
}

export default function SDKSearchPage() {
  const dispatch = useDispatch<AppDispatch>();
  const isNavigationPanelOpen = useSelector(selectIsNavigationPanelOpen);
  const [notifications, setNotifications] = useState<FlashbarProps.MessageDefinition[]>([]);
  const sdkSearchResults = useSelector(selectSearchSDKDocWithIntentResults);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    // Perform search action only if the search query is not empty.
    if (searchParams.get(SEARCH_QUERY_PARAM_KEY)) {
      void dispatch(searchSDKDocWithIntent(searchParams.get(SEARCH_QUERY_PARAM_KEY)));
    }
  }, [searchParams]);

  const removeNotification = (notificationId: string) => {
    const filteredNotification = notifications.filter((notification) => notification.id !== notificationId);
    setNotifications(filteredNotification);
  };

  const navigationChangeHandler = () => {
    dispatch(setNavigationPanelOpen(!isNavigationPanelOpen));
  };

  useEffect(() => {
    removeNotification('api-error');
    if (sdkSearchResults.error) {
      setNotifications((prevState) => [
        ...prevState,
        {
          header: 'Failed to search',
          type: 'error',
          content: sdkSearchResults.error,
          dismissible: true,
          dismissLabel: 'Dismiss error',
          onDismiss: () => removeNotification('api-error'),
          id: 'api-error',
        },
      ]);
    }
  }, [sdkSearchResults.error]);

  return (
    <AppLayout
      headerSelector="#tw-header"
      notifications={<Flashbar items={notifications} />}
      stickyNotifications={false}
      content={<SDKDocSearchPageContent />}
      navigation={<GlobalNavigationPanel />}
      navigationOpen={isNavigationPanelOpen}
      onNavigationChange={navigationChangeHandler}
      tools={<GlobalHelpPanel />}
      breadcrumbs={
        <BreadcrumbLinkGroup
          links={[
            { text: 'Home', href: HOME },
            {
              text: 'Search documentation',
              href: SDK_SEARCH_PAGE,
            },
          ]}
        />
      }
    />
  );
}
