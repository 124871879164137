import { array, InferType, object, string } from 'yup';
import { UseFormProps } from 'react-hook-form/dist/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { BLOCK_CATEGORY, BLOCK_NAME, BLOCK_SUMMARY, EMAIL_ADDRESS, TAGS } from '../constants/form-constants';

export const BlockDetailSchema = object({
  [BLOCK_NAME]: string()
    .required()
    .matches(RegExp('^(?=[A-Za-z])[A-Za-z0-9]*(?:\\.[A-Za-z0-9]+)*[A-Za-z0-9]$'), {
      message: 'Name is not in valid format.',
    })
    .min(3)
    .max(100)
    .label('Name'),
  [BLOCK_CATEGORY]: string().required().label('Category'),
  [BLOCK_SUMMARY]: string().min(10).max(200).label('Summary'),
  [TAGS]: array().max(10).label('Tags'),
  [EMAIL_ADDRESS]: string().required().email().min(10).max(100).label('Email address'),
});

export type BlockDetailSchemaType = InferType<typeof BlockDetailSchema>;

export const BlockDetailsFormProps: UseFormProps<BlockDetailSchemaType, any> = {
  mode: 'onSubmit',
  reValidateMode: 'onChange',
  resolver: yupResolver(BlockDetailSchema),
};
