import { ColumnLayout, Container, Header, Link } from '@cloudscape-design/components';
import moment from 'moment-timezone';
import React from 'react';
import { useSelector } from 'react-redux';

import { ArtifactEnvironment } from '../../../../common/types/enums';
import BoxElement from '../../../../components/polaris/BoxElement';
import { selectGetAutomationDetailResponse } from '../../../../slices/AutomationDetailSlice';
import AutomationDetailHelpPanel from './AutomationDetailHelpPanel';

interface AutomationDevelopmentTimelineContainerProps {
  toolsOpen: boolean;
  setToolsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setHelpPanelContent: React.Dispatch<React.SetStateAction<React.ReactNode>>;
}

export default function AutomationDevelopmentTimelineContainer(props: AutomationDevelopmentTimelineContainerProps) {
  const currentAutomation = useSelector(selectGetAutomationDetailResponse);
  if (!currentAutomation?.data) {
    return;
  }

  const developmentStartTime = currentAutomation.data.automationDetail.createdOn;
  let developmentEndTime;
  let developmentTime = moment().utc().diff(developmentStartTime, 'days');
  for (const executableInfo of currentAutomation.data.executableList) {
    if (executableInfo.artifactEnvironment === ArtifactEnvironment.PROD) {
      developmentEndTime = executableInfo.createdTimestamp;
      developmentTime = moment(developmentEndTime).utc().diff(developmentStartTime, 'days');
    }
  }

  return (
    <Container
      header={
        <Header
          variant="h2"
          info={
            <Link
              key="development-timeline-help-panel"
              variant="info"
              ariaLabel="Development timeline help panel"
              onFollow={() => {
                props.setToolsOpen(!props.toolsOpen);
                props.setHelpPanelContent(<AutomationDetailHelpPanel />);
              }}
            >
              Info
            </Link>
          }
        >
          Development timeline
        </Header>
      }
    >
      <ColumnLayout borders="vertical" columns={3}>
        <BoxElement
          key="Development start date"
          headerText="Development start date"
          description={developmentStartTime ? new Date(developmentStartTime).toISOString().split('T')[0] : '-'}
        />
        <BoxElement
          key="First release date"
          headerText="First release date"
          description={developmentEndTime ? new Date(developmentEndTime).toISOString().split('T')[0] : '-'}
        />
        <BoxElement key="Development time" headerText="Development time" description={`${developmentTime} days`} />
      </ColumnLayout>
    </Container>
  );
}
