import { Badge, ColumnLayout, Container, Header, SpaceBetween, StatusIndicator } from '@cloudscape-design/components';
import React from 'react';
import { useSelector } from 'react-redux';

import { CAPABILITY_STATUS_CONFIG } from '../../../../common/constants/capability-constants';
import { convertDateToISOWithTimeZone } from '../../../../common/utils';
import BoxElement from '../../../../components/polaris/BoxElement';
import { selectGetCapabilityResponse } from '../../../../slices/CapabilityDetailSlice';

export default function BlockFunctionViewPageContainer() {
  const { capabilityDetail } = useSelector(selectGetCapabilityResponse).data;

  return (
    capabilityDetail && (
      <SpaceBetween size="xl">
        <Container header={<Header variant="h2">Basic Information</Header>}>
          <ColumnLayout borders="vertical" columns={3}>
            <BoxElement headerText="Current version" description={capabilityDetail.capabilityVersion} />
            <BoxElement
              headerText="Status"
              description={
                <StatusIndicator
                  iconAriaLabel=""
                  type={CAPABILITY_STATUS_CONFIG[capabilityDetail.capabilityStatus].statusIndicator}
                >
                  {CAPABILITY_STATUS_CONFIG[capabilityDetail.capabilityStatus].statusText}
                </StatusIndicator>
              }
            />
            <BoxElement headerText="Minimum supported version" description={capabilityDetail.minArtifactVersion} />
            <BoxElement headerText="Maximum supported version" description={capabilityDetail.maxArtifactVersion} />
            <BoxElement
              headerText="Tags"
              description={
                capabilityDetail.tags ? (
                  <SpaceBetween direction="horizontal" size="xxs">
                    {capabilityDetail.tags.map((tag) => (
                      <Badge key={tag}>{tag}</Badge>
                    ))}
                  </SpaceBetween>
                ) : (
                  '-'
                )
              }
            />
            <BoxElement
              headerText="Created on"
              description={convertDateToISOWithTimeZone(new Date(capabilityDetail.createdAt))}
            />
            <BoxElement
              headerText="Last updated on"
              description={convertDateToISOWithTimeZone(new Date(capabilityDetail.updatedAt))}
            />
          </ColumnLayout>
        </Container>
        <Container header={<Header variant="h2">Description</Header>}>
          <BoxElement headerText="" description={capabilityDetail.summary || '-'} />
        </Container>
        {/* TODO: Need to check we can show input and output format configuration in public view */}
      </SpaceBetween>
    )
  );
}
