import { AppLayout, ContentLayout, Flashbar, SpaceBetween, Spinner } from '@cloudscape-design/components';
import { FlashbarProps } from '@cloudscape-design/components/flashbar';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { HOME } from '../../../common/constants/route-paths';
import { getAutomationDetailPagePath } from '../../../common/page-route-path';
import { LoadingStatus } from '../../../common/types/enums';
import GlobalHelpPanel from '../../../components/GlobalHelpPanel';
import GlobalNavigationPanel from '../../../components/GlobalNavigationPanel';
import BreadcrumbLinkGroup from '../../../components/polaris/BreadcrumbLinkGroup';
import { AuthProfileSelector } from '../../../slices/AuthStateSlice';
import {
  getAutomationDetail,
  selectGetAutomationDetailResponse,
  selectUpdateAutomationDetailResponse,
} from '../../../slices/AutomationDetailSlice';
import { selectIsNavigationPanelOpen, setNavigationPanelOpen } from '../../../slices/SitePreferencesSlice';
import { AppDispatch } from '../../../store/store';
import EditAutomationDetailForm from './components/EditAutomationDetailForm';

function EditAutomationDetailPageContent() {
  const currentAutomation = useSelector(selectGetAutomationDetailResponse);

  return currentAutomation.data ? (
    <ContentLayout>
      <SpaceBetween size="xl">
        <EditAutomationDetailForm />
      </SpaceBetween>
    </ContentLayout>
  ) : (
    <div className="tw-page-loading-spinner">
      <Spinner size="large" />
    </div>
  );
}

export default function EditAutomationDetailPage() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const isNavigationPanelOpen = useSelector(selectIsNavigationPanelOpen);
  const [notifications, setNotifications] = useState<FlashbarProps.MessageDefinition[]>([]);
  const selectedAuthProfile = useSelector(AuthProfileSelector);
  const currentAutomation = useSelector(selectGetAutomationDetailResponse);
  const updateAutomationDetailResponse = useSelector(selectUpdateAutomationDetailResponse);

  const removeNotification = (notificationId: string) => {
    const filteredNotification = notifications.filter((notification) => notification.id !== notificationId);
    setNotifications(filteredNotification);
  };

  const navigationChangeHandler = () => {
    dispatch(setNavigationPanelOpen(!isNavigationPanelOpen));
  };

  useEffect(() => {
    void dispatch(getAutomationDetail(params.automationId));
  }, [selectedAuthProfile]);
  useEffect(() => {
    removeNotification('api-error');
    if (currentAutomation.error) {
      setNotifications((prevState) => [
        ...prevState,
        {
          header: 'Failed to load automation detail',
          type: 'error',
          content: currentAutomation.error,
          dismissible: true,
          dismissLabel: 'Dismiss error',
          onDismiss: () => removeNotification('api-error'),
          id: 'api-error',
        },
      ]);
    }
  }, [currentAutomation.error]);
  useEffect(() => {
    if (updateAutomationDetailResponse.status === LoadingStatus.SUCCESS) {
      navigate(getAutomationDetailPagePath(params.automationId));
    }
  }, [updateAutomationDetailResponse?.status]);

  return (
    <AppLayout
      headerSelector="#tw-header"
      notifications={<Flashbar items={notifications} />}
      stickyNotifications={false}
      content={<EditAutomationDetailPageContent />}
      contentType="form"
      navigation={<GlobalNavigationPanel />}
      navigationOpen={isNavigationPanelOpen}
      onNavigationChange={navigationChangeHandler}
      tools={<GlobalHelpPanel />}
      breadcrumbs={
        <BreadcrumbLinkGroup
          links={
            currentAutomation?.data
              ? [
                  {
                    text: 'Home',
                    href: HOME,
                  },
                  {
                    text: `${currentAutomation.data.automationDetail.automationName || ''}`,
                    href: getAutomationDetailPagePath(params.automationId),
                  },
                  {
                    text: 'Edit Automation',
                    href: '#',
                  },
                ]
              : [
                  {
                    text: 'Home',
                    href: HOME,
                  },
                  {
                    text: '',
                    href: '',
                  },
                ]
          }
        />
      }
    />
  );
}
