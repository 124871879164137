import { Button, Form, Header, SpaceBetween } from '@cloudscape-design/components';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { getMyBlockLibraryDetailPagePath } from '../../../../common/page-route-path';
import { BlockDetailTabId, LoadingStatus } from '../../../../common/types/enums';
import { createBlockPipeline, selectCreateBlockPipelineResponse } from '../../../../slices/BlockDetailSlice';
import { selectSynchronizeResourceAccountResponse } from '../../../../slices/InfraManagerSlice';
import { AppDispatch } from '../../../../store/store';
import { BlockPipelineFormProps, BlockPipelineSchemaType } from '../config/block-pipeline-form.config';
import CreateBlockPipelineContainer from './CreateBlockPipelineContainer';

export default function CreateBlockPipelineForm() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const blockPipelineFormMethods = useForm<BlockPipelineSchemaType>(BlockPipelineFormProps);
  const createBlockPipelineResult = useSelector(selectCreateBlockPipelineResponse);
  const synchronizeResourceAccountResponse = useSelector(selectSynchronizeResourceAccountResponse);
  const [formErrorText, setFormErrorText] = useState('');

  useEffect(() => {
    setFormErrorText(createBlockPipelineResult.error);
  }, [createBlockPipelineResult.error]);

  function handleButtonClick() {
    return async () => {
      if (!synchronizeResourceAccountResponse.data?.isSynchronized) {
        setFormErrorText('Please synchronize your account before creating pipeline.');
        return;
      }

      const formFieldValues = {
        ...blockPipelineFormMethods.getValues(),
        blockId: params.blockId,
      };

      await blockPipelineFormMethods.handleSubmit(() => dispatch(createBlockPipeline(formFieldValues)))();
    };
  }

  return (
    <Form
      header={<Header variant="h1">Create pipeline</Header>}
      actions={
        <SpaceBetween direction="horizontal" size="xl">
          <Button
            formAction="none"
            variant="link"
            onClick={() => navigate(getMyBlockLibraryDetailPagePath(params.blockId, BlockDetailTabId.PIPELINE))}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleButtonClick()}
            loading={createBlockPipelineResult.status === LoadingStatus.PENDING}
            disabled={createBlockPipelineResult.status === LoadingStatus.PENDING}
          >
            Create
          </Button>
        </SpaceBetween>
      }
      errorText={formErrorText}
      variant="embedded"
    >
      <FormProvider {...blockPipelineFormMethods}>
        <CreateBlockPipelineContainer setFormErrorText={setFormErrorText} />
      </FormProvider>
    </Form>
  );
}
