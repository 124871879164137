import { Box, Button, Header, Modal, Select, SpaceBetween, StatusIndicator } from '@cloudscape-design/components';
import { FlashbarProps } from '@cloudscape-design/components/flashbar';
import { SelectProps } from '@cloudscape-design/components/select';
import React, { SetStateAction, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { updateBlockArtifactApi } from '../../../../api/block-registry-api';
import { ArtifactStatus, LoadingStatus, PageViewType } from '../../../../common/types/enums';
import { IListBlockArtifactsData } from '../../../../common/types/interfaces';
import { AuthProfileSelector } from '../../../../slices/AuthStateSlice';
import {
  getBlock,
  listBlockArtifacts,
  selectGetBlockResponse,
  selectListBlockArtifactsResponse,
} from '../../../../slices/BlockDetailSlice';
import { AppDispatch } from '../../../../store/store';

interface PublishBlockArtifactModalProps {
  visible: boolean;
  onDismiss: () => void;
  setNotifications: React.Dispatch<SetStateAction<FlashbarProps.MessageDefinition[]>>;
  removeNotification: (notificationId: string) => void;
}

function getBlockArtifactListForSelection(blockArtifactsList: IListBlockArtifactsData[]): SelectProps.Options {
  return blockArtifactsList
    .filter(
      (blockArtifact) =>
        blockArtifact.artifactStatus === ArtifactStatus.REGISTERED ||
        (blockArtifact.artifactStatus === ArtifactStatus.INITIATED && blockArtifact.updatedAt > 30 * 60 * 1000),
    )
    .map((blockArtifact): SelectProps.Option => {
      return {
        label: blockArtifact.version,
        value: blockArtifact.version,
      };
    });
}

export default function PublishBlockArtifactModal(props: PublishBlockArtifactModalProps) {
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const selectedAuthProfile = useSelector(AuthProfileSelector);
  const currentBlock = useSelector(selectGetBlockResponse);
  const listBlockArtifactsData = useSelector(selectListBlockArtifactsResponse);
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const [publishBlockArtifactError, setPublishBlockArtifactError] = useState('');
  const [selectedBlockExecutable, setSelectedBlockExecutable] = useState<SelectProps.Option>(null);

  function getSelectStatusType() {
    if (listBlockArtifactsData.status === LoadingStatus.PENDING) {
      return 'loading';
    } else if (listBlockArtifactsData.status === LoadingStatus.SUCCESS) {
      return 'finished';
    }
    return 'error';
  }

  async function handleConfirmButton() {
    try {
      if (!selectedBlockExecutable) {
        setPublishBlockArtifactError('Select an executable version to publish');
        return;
      }

      setSubmitButtonLoading(true);
      setPublishBlockArtifactError('');

      const updateBlockResponse = await updateBlockArtifactApi({
        blockId: params.blockId,
        version: selectedBlockExecutable.value,
        artifactStatus: ArtifactStatus.PUBLISHED,
      });
      void dispatch(
        getBlock({
          blockId: params.blockId,
          clientId: selectedAuthProfile.ClientId,
          pageViewType: PageViewType.SELF_VIEW,
        }),
      );
      void dispatch(
        listBlockArtifacts({
          blockId: params.blockId,
          clientId: selectedAuthProfile.ClientId,
          pageViewType: PageViewType.SELF_VIEW,
        }),
      );
      props.onDismiss();
      props.setNotifications((prevState) => [
        ...prevState,
        {
          header:
            updateBlockResponse.artifactStatus === ArtifactStatus.INITIATED
              ? `Publishing is initiated for ${currentBlock.data?.blockName} version ${selectedBlockExecutable.value}.`
              : `You have successfully published ${currentBlock.data?.blockName} version ${selectedBlockExecutable.value}.`,
          content:
            updateBlockResponse.artifactStatus === ArtifactStatus.INITIATED
              ? `${currentBlock.data?.blockName} will be published within 10-15 minutes.`
              : '',
          type: 'success',
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => props.removeNotification('api-success'),
          id: 'api-success',
        },
      ]);
    } catch (error: any) {
      setPublishBlockArtifactError(error?.message);
    } finally {
      setSubmitButtonLoading(false);
    }
  }

  return (
    <Modal
      visible={props.visible}
      onDismiss={() => props.onDismiss()}
      header={<Header variant="h2">Publish the library</Header>}
      closeAriaLabel="close-modal"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={() => props.onDismiss()}>
              Cancel
            </Button>
            <Button variant="primary" loading={submitButtonLoading} onClick={handleConfirmButton}>
              Confirm
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween direction="vertical" size="l">
        <Select
          ariaRequired={true}
          placeholder="Select an executable version"
          options={getBlockArtifactListForSelection(listBlockArtifactsData.data)}
          loadingText="Loading executables"
          statusType={getSelectStatusType()}
          recoveryText="Unable to fetch executables. Please try again."
          empty="No executables"
          selectedOption={selectedBlockExecutable}
          onChange={(event) => setSelectedBlockExecutable(event.detail.selectedOption)}
        />
        {publishBlockArtifactError && (
          <StatusIndicator type="error" iconAriaLabel="error-label">
            {publishBlockArtifactError}
          </StatusIndicator>
        )}
      </SpaceBetween>
    </Modal>
  );
}
