import { AppLayout, ContentLayout, Flashbar, Grid, SpaceBetween, Spinner } from '@cloudscape-design/components';
import { FlashbarProps } from '@cloudscape-design/components/flashbar';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { BLOCKS, LIBRARY_BLOCKS } from '../../../common/constants/route-paths';
import { getLibraryBlocksCategoryPagePath } from '../../../common/page-route-path';
import { LoadingStatus, PageViewType } from '../../../common/types/enums';
import { checkIfClientIdAndRoleMissing } from '../../../common/utils';
import GlobalHelpPanel from '../../../components/GlobalHelpPanel';
import GlobalNavigationPanel from '../../../components/GlobalNavigationPanel';
import BreadcrumbLinkGroup from '../../../components/polaris/BreadcrumbLinkGroup';
import { AuthProfileSelector } from '../../../slices/AuthStateSlice';
import { getBlock, selectGetBlockResponse, selectListBlockArtifactsResponse } from '../../../slices/BlockDetailSlice';
import { selectIsNavigationPanelOpen, setNavigationPanelOpen } from '../../../slices/SitePreferencesSlice';
import { AppDispatch } from '../../../store/store';
import BlockAddnInfoContainer from './components/BlockAddnInfoContainer';
import BlockDetailContainer from './components/BlockDetailContainer';
import BlockDetailPageContentHeader from './components/BlockDetailContentHeader';

function BlockDetailPageContent() {
  const currentBlock = useSelector(selectGetBlockResponse);

  return currentBlock.data ? (
    <ContentLayout header={<BlockDetailPageContentHeader />}>
      <SpaceBetween size="xl">
        <Grid gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}>
          <BlockDetailContainer />
          <BlockAddnInfoContainer />
        </Grid>
      </SpaceBetween>
    </ContentLayout>
  ) : (
    currentBlock.status === LoadingStatus.PENDING && (
      <div className="tw-page-loading-spinner">
        <Spinner size="large" />
      </div>
    )
  );
}

export default function BlockDetailPage() {
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const isNavigationPanelOpen = useSelector(selectIsNavigationPanelOpen);
  const [notifications, setNotifications] = useState<FlashbarProps.MessageDefinition[]>([]);
  const selectedAuthProfile = useSelector(AuthProfileSelector);
  const currentBlock = useSelector(selectGetBlockResponse);
  const listBlockArtifactsData = useSelector(selectListBlockArtifactsResponse);

  const removeNotification = (notificationId: string) => {
    const filteredNotification = notifications.filter((notification) => notification.id !== notificationId);
    setNotifications(filteredNotification);
  };

  const navigationChangeHandler = () => {
    dispatch(setNavigationPanelOpen(!isNavigationPanelOpen));
  };

  useEffect(() => {
    removeNotification('api-error');
    if (currentBlock.error || listBlockArtifactsData.error) {
      setNotifications((prevState) => [
        ...prevState,
        {
          header: 'Failed to load block detail',
          type: 'error',
          content: currentBlock.error || listBlockArtifactsData.error,
          dismissible: true,
          dismissLabel: 'Dismiss error',
          onDismiss: () => removeNotification('api-error'),
          id: 'api-error',
        },
      ]);
    }
  }, [currentBlock.error, listBlockArtifactsData.error]);
  useEffect(() => {
    if (checkIfClientIdAndRoleMissing(selectedAuthProfile)) {
      return;
    }

    void dispatch(
      getBlock({
        blockId: params.blockId,
        pageViewType: PageViewType.NORMAL_VIEW,
      }),
    );
  }, [selectedAuthProfile]);

  return (
    <AppLayout
      headerSelector="#tw-header"
      notifications={<Flashbar items={notifications} />}
      stickyNotifications={false}
      content={<BlockDetailPageContent />}
      navigation={<GlobalNavigationPanel />}
      navigationOpen={isNavigationPanelOpen}
      onNavigationChange={navigationChangeHandler}
      tools={<GlobalHelpPanel />}
      breadcrumbs={
        <BreadcrumbLinkGroup
          links={
            currentBlock?.data
              ? [
                  {
                    text: 'Blocks',
                    href: BLOCKS,
                  },
                  {
                    text: 'Libraries',
                    href: LIBRARY_BLOCKS,
                  },
                  {
                    text: `${currentBlock.data.blockCategory || ''}`,
                    href: getLibraryBlocksCategoryPagePath(currentBlock.data.blockCategory?.toLowerCase()),
                  },
                  {
                    text: `${currentBlock.data.blockName || ''}`,
                    href: '',
                  },
                ]
              : [
                  {
                    text: 'Blocks',
                    href: BLOCKS,
                  },
                  {
                    text: 'Libraries',
                    href: LIBRARY_BLOCKS,
                  },
                  {
                    text: '',
                    href: '',
                  },
                ]
          }
        />
      }
    />
  );
}
