import { SideNavigation } from '@cloudscape-design/components';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

import { HOME } from '../../common/constants/route-paths';
import { devDocumentationUrl, sdkDocumentationEndpoint } from '../../config/endpoints';

function SignInNavigationPanel() {
  const location = useLocation();
  return (
    <SideNavigation
      header={{ text: 'Developer Console', href: HOME }}
      items={[
        { type: 'link', text: 'Tutorials', href: devDocumentationUrl, external: true },
        { type: 'link', text: 'Documentation', href: sdkDocumentationEndpoint, external: true },
      ]}
      activeHref={location.pathname}
    />
  );
}

export default SignInNavigationPanel;
