import { Container, FormField, Header, Input, SpaceBetween } from '@cloudscape-design/components';
import { SelectProps } from '@cloudscape-design/components/select';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { WindowsEC2SourceTypeDisplayNameMap } from '../../../../common/constants/automation-constants';
import { WindowsEC2SourceType } from '../../../../common/types/enums';
import InputFormField from '../../../../components/polaris/InputFormField';
import SelectFormField from '../../../../components/polaris/SelectFormField';
import { selectGetAutomationDetailResponse } from '../../../../slices/AutomationDetailSlice';
import { AutomationDetailsSchemaType } from '../config/automation-form.config';
import {
  EXECUTION_AWS_ACCOUNT_ID,
  EXECUTION_AWS_REGION,
  EXECUTION_ENVIRONMENT_MEMORY,
  EXECUTION_ENVIRONMENT_RESOURCE_ID,
  EXECUTION_ENVIRONMENT_RESOURCE_TYPE,
  EXECUTION_ENVIRONMENT_STORAGE,
  SUPPORTED_CONCURRENT_EXECUTIONS,
} from '../constants/form-constants';

function getExecutionEnvironmentResourceTypeOptions(): SelectProps.Option[] {
  return Object.values(WindowsEC2SourceType).map((resourceType) => ({
    label: WindowsEC2SourceTypeDisplayNameMap[resourceType],
    value: resourceType,
  }));
}

export default function ExecutionEnvironmentDetailContainer() {
  const currentAutomation = useSelector(selectGetAutomationDetailResponse);
  const { setValue } = useFormContext<AutomationDetailsSchemaType>();
  const [selectedExecutionEnvironmentResourceType, setSelectedExecutionEnvironmentResourceType] =
    useState<SelectProps.Option>({
      label:
        WindowsEC2SourceTypeDisplayNameMap[
          currentAutomation.data.automationDetail.executionEnvironmentDetails?.resourceType
        ],
      value: currentAutomation.data.automationDetail.executionEnvironmentDetails?.resourceType,
    });
  const { executionEnvironmentDetails } = currentAutomation.data.automationDetail;

  return (
    <Container header={<Header variant="h2">Execution environment details</Header>}>
      <SpaceBetween direction="vertical" size="l">
        <InputFormField
          name={EXECUTION_AWS_ACCOUNT_ID}
          label="AWS account ID"
          description="AWS account ID in which the resource is present."
          ariaRequired={true}
          defaultValue={executionEnvironmentDetails?.awsAccountId}
          readOnly
        />
        {executionEnvironmentDetails?.awsAccountId && (
          <FormField label="AWS IAM role" description="The IAM role for Workplace to access the execution AWS account">
            <Input
              value={`arn:aws:iam::${String(
                executionEnvironmentDetails.awsAccountId,
              )}:taskless-workplace-automation-execution-manager-service-role`}
              readOnly
            />
          </FormField>
        )}
        <SelectFormField
          name={EXECUTION_ENVIRONMENT_RESOURCE_TYPE}
          label="Resource type"
          description="Execution environment resource type."
          control={null}
          ariaRequired={true}
          placeholder="Select an execution environment resource type"
          options={getExecutionEnvironmentResourceTypeOptions()}
          selectedOption={selectedExecutionEnvironmentResourceType}
          defaultValue={selectedExecutionEnvironmentResourceType.value}
          onChange={(event) => {
            const executionEnvironmentResourceType = event.detail.selectedOption.value as WindowsEC2SourceType;
            setValue(EXECUTION_ENVIRONMENT_RESOURCE_TYPE, executionEnvironmentResourceType);
            setSelectedExecutionEnvironmentResourceType(event.detail.selectedOption);
          }}
          empty="No resource type available"
        />
        <InputFormField
          name={EXECUTION_ENVIRONMENT_RESOURCE_ID}
          label={
            (selectedExecutionEnvironmentResourceType.value as WindowsEC2SourceType)
              ? `${String(
                  WindowsEC2SourceTypeDisplayNameMap[
                    selectedExecutionEnvironmentResourceType.value as WindowsEC2SourceType
                  ],
                )} ID`
              : 'Resource ID'
          }
          description={
            (selectedExecutionEnvironmentResourceType.value as WindowsEC2SourceType)
              ? `${String(
                  WindowsEC2SourceTypeDisplayNameMap[
                    selectedExecutionEnvironmentResourceType.value as WindowsEC2SourceType
                  ],
                )} ID for the execution environment.`
              : 'Resource ID for the execution environment.'
          }
          ariaRequired={true}
          defaultValue={executionEnvironmentDetails?.resourceId}
        />
        <InputFormField
          name={EXECUTION_AWS_REGION}
          label="AWS region"
          description="AWS region in which the resource is present."
          ariaRequired={true}
          defaultValue={executionEnvironmentDetails?.region}
        />
        <InputFormField
          name={SUPPORTED_CONCURRENT_EXECUTIONS}
          label="Supported concurrent executions"
          description="Max supported number of concurrent executions."
          ariaRequired={true}
          defaultValue={executionEnvironmentDetails?.supportedConcurrentExecutions}
          type={'number'}
        />
        <InputFormField
          name={EXECUTION_ENVIRONMENT_MEMORY}
          label="Required memory"
          description="Minimum memory required for automation execution (in GB)."
          ariaRequired={true}
          defaultValue={executionEnvironmentDetails?.memory}
          type={'number'}
        />
        <InputFormField
          name={EXECUTION_ENVIRONMENT_STORAGE}
          label="Required storage"
          description="Minimum storage required for automation execution (in GB)."
          ariaRequired={true}
          defaultValue={executionEnvironmentDetails?.storage}
          type={'number'}
        />
      </SpaceBetween>
    </Container>
  );
}
