export const HOME = '/';
export const CREATE_AUTOMATION = '/create-automation';
export const CREATE_AUTOMATION_PIPELINE = '/automations/:automationId/create-pipeline';
export const AUTOMATION_DETAIL = '/automations/:automationId';
export const EDIT_AUTOMATION_DETAIL = '/automations/:automationId/edit';
export const AUTOMATION_INSTANT_UI_PREVIEW = '/automations/:automationId/ui-preview';
export const SDK_SEARCH_PAGE = '/search-sdk';

export const MY_BLOCKS = '/myblocks';
export const MY_BLOCK_CREATE = '/myblocks/:blockType/create';
export const MY_BLOCK_LIBRARY_DETAIL = '/myblocks/library/:blockId';
export const MY_BLOCK_SERVICE_DETAIL = '/myblocks/service/:blockId';
export const MY_BLOCK_CREATE_PIPELINE = '/myblocks/library/:blockId/create-pipeline';
export const BLOCKS = '/blocks';
export const LIBRARY_BLOCKS = '/blocks/library';
export const LIBRARY_BLOCKS_CATEGORY = '/blocks/library/category/:blockCategory';
export const LIBRARY_BLOCK_DETAIL = '/blocks/library/:blockId';
export const BLOCK_FUNCTION_DETAIL = '/blocks/library/:blockId/functions/:capabilityId';

export const MY_BLOCK_FUNCTION_REGISTER = '/myblocks/library/:blockId/functions/register';
export const MY_BLOCK_FUNCTION_DETAIL = '/myblocks/library/:blockId/functions/:capabilityId';
export const MY_BLOCK_FUNCTION_EDIT = '/myblocks/library/:blockId/functions/:capabilityId/edit';

export const AUTOMATION_REQUESTS = '/automation-requests';
