import { Container, Header, SpaceBetween } from '@cloudscape-design/components';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { ExecutionEnvironment } from '../../../../common/types/enums';
import { CheckboxFormField } from '../../../../components/polaris/CheckboxFormField';
import InputFormField from '../../../../components/polaris/InputFormField';
import { selectGetAutomationDetailResponse } from '../../../../slices/AutomationDetailSlice';
import {
  AUTOMATION_NAME,
  DASHBOARD_LINK,
  EMAIL_ADDRESS,
  IS_WINDOWS_APPLICATION_AUTOMATION,
} from '../constants/form-constants';
import ExecutionEnvironmentDetailContainer from './ExecutionEnvironmentDetailContainer';

export default function EditAutomationDetailContainer() {
  const currentAutomation = useSelector(selectGetAutomationDetailResponse);
  const [isAutomationOnWindowsEC2] = useState<boolean>(
    currentAutomation.data.automationDetail.executionEnvironment === ExecutionEnvironment.WINDOWS_EC2,
  );
  if (!currentAutomation?.data) {
    return;
  }
  const { automationDetail, pipelineDetail } = currentAutomation.data;

  return (
    <SpaceBetween direction="vertical" size="xl">
      <Container header={<Header variant="h2">Automation details</Header>}>
        <SpaceBetween direction="vertical" size="l">
          <InputFormField
            name={AUTOMATION_NAME}
            label="Automation name"
            description="A descriptive name which will be visible to your team members."
            ariaRequired={true}
            defaultValue={automationDetail.automationName}
          />
          <InputFormField
            name={EMAIL_ADDRESS}
            label="Email address"
            description="The email address (use team email address instead of individual email address) to receive notifications regarding the automation, including pipeline readiness and build statuses."
            ariaRequired={true}
            defaultValue={automationDetail.emailAddress}
          />
          {isAutomationOnWindowsEC2 && (
            <CheckboxFormField
              name={IS_WINDOWS_APPLICATION_AUTOMATION}
              label="Is windows application automation"
              description="Flag to signify if the automation includes windows desktop applications?"
              control={null}
              defaultValue={automationDetail.isWindowsApplicationAutomation}
            />
          )}
        </SpaceBetween>
      </Container>
      {isAutomationOnWindowsEC2 && <ExecutionEnvironmentDetailContainer />}
      <Container header={<Header variant="h2">Analytics</Header>}>
        <SpaceBetween direction="vertical" size="l">
          <InputFormField
            name={DASHBOARD_LINK}
            label="Analytics URL"
            description="The external link to view analytics of the automation."
            ariaRequired={true}
            placeholder="https://"
            defaultValue={pipelineDetail.dashboardLink}
          />
        </SpaceBetween>
      </Container>
    </SpaceBetween>
  );
}
