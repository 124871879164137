import { Button, Form, SpaceBetween } from '@cloudscape-design/components';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { getMyBlockLibraryDetailPagePath } from '../../../../common/page-route-path';
import { BlockDetailTabId, LoadingStatus } from '../../../../common/types/enums';
import { selectUpdateCapabilityResponse, updateCapability } from '../../../../slices/CapabilityDetailSlice';
import { AppDispatch } from '../../../../store/store';
import { CAPABILITY_HANDLER } from '../../MyBlockFunctionRegisterPage/constants/form-constants';
import { UpdateCapabilityFormProps, UpdateCapabilitySchemaType } from '../config/capability-form.config';
import {
  CAPABILITY_INPUT,
  CAPABILITY_NAME,
  CAPABILITY_OUTPUT,
  CAPABILITY_SUMMARY,
  MIN_BLOCK_ARTIFACT_VERSION,
  TAGS,
} from '../constants/form-constants';
import MyBlockFunctionEditContainer from './MyBlockFunctionEditContainer';

export default function MyBlockFunctionEditForm() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const updateCapabilityFormMethods = useForm<UpdateCapabilitySchemaType>(UpdateCapabilityFormProps);
  const updateCapabilityResult = useSelector(selectUpdateCapabilityResponse);

  function handleSaveChangeButton() {
    return async () => {
      const formFieldValues = updateCapabilityFormMethods.getValues();

      const updateCapabilityRequest = {
        blockId: params.blockId,
        capabilityId: params.capabilityId,
        capabilityName: formFieldValues[CAPABILITY_NAME],
        summary: formFieldValues[CAPABILITY_SUMMARY],
        tags: formFieldValues[TAGS]?.length ? formFieldValues[TAGS] : null,
        capabilityConfigMap: {
          handler: {
            functionHandler: formFieldValues[CAPABILITY_HANDLER],
          }, // TODO: Need to remove this field once BE validation is completed
          input: formFieldValues[CAPABILITY_INPUT],
          output: formFieldValues[CAPABILITY_OUTPUT],
        },
        minArtifactVersion: formFieldValues[MIN_BLOCK_ARTIFACT_VERSION],
      };

      await updateCapabilityFormMethods.handleSubmit(() => dispatch(updateCapability(updateCapabilityRequest)))();
    };
  }

  return (
    <Form
      actions={
        <SpaceBetween direction="horizontal" size="xl">
          <Button
            formAction="none"
            variant="link"
            onClick={() => navigate(getMyBlockLibraryDetailPagePath(params.blockId, BlockDetailTabId.FUNCTIONS))}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleSaveChangeButton()}
            loading={updateCapabilityResult.status === LoadingStatus.PENDING}
            disabled={updateCapabilityResult.status === LoadingStatus.PENDING}
          >
            Save Changes
          </Button>
        </SpaceBetween>
      }
      errorText={updateCapabilityResult.error}
      variant="embedded"
    >
      <FormProvider {...updateCapabilityFormMethods}>
        <MyBlockFunctionEditContainer />
      </FormProvider>
    </Form>
  );
}
