import {
  CollectionPreferences,
  CollectionPreferencesProps,
  StatusIndicator,
  TableProps,
} from '@cloudscape-design/components';

import TALink from '../../../../components/polaris/TALink';
import React from 'react';
import {
  CAPABILITY_NAME_COL_ID,
  CAPABILITY_STATUS_COL_ID,
  CAPABILITY_VERSION_COL_ID,
  COL_ID_TO_NAME_MAP,
  CREATED_ON_COL_ID,
} from './functions-column-constants';
import { IListCapabilitiesForBlockData } from '../../../../common/types/interfaces';
import { CAPABILITY_STATUS_CONFIG } from '../../../../common/constants/capability-constants';
import { getBlockFunctionDetailPagePath } from '../../../../common/page-route-path';

export const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<IListCapabilitiesForBlockData>[] = [
  {
    id: CAPABILITY_NAME_COL_ID,
    header: COL_ID_TO_NAME_MAP[CAPABILITY_NAME_COL_ID],
    cell: (item) => (
      <TALink href={getBlockFunctionDetailPagePath(item.blockId, item.capabilityId)}>{item.capabilityName}</TALink>
    ),
    sortingField: CAPABILITY_NAME_COL_ID,
  },
  {
    id: CAPABILITY_VERSION_COL_ID,
    header: COL_ID_TO_NAME_MAP[CAPABILITY_VERSION_COL_ID],
    cell: (item) => item.capabilityVersion,
    sortingField: CAPABILITY_VERSION_COL_ID,
  },
  {
    id: CAPABILITY_STATUS_COL_ID,
    header: COL_ID_TO_NAME_MAP[CAPABILITY_STATUS_COL_ID],
    cell: (item) => (
      <StatusIndicator iconAriaLabel="" type={CAPABILITY_STATUS_CONFIG[item.capabilityStatus].statusIndicator}>
        {CAPABILITY_STATUS_CONFIG[item.capabilityStatus].statusText}
      </StatusIndicator>
    ),
    sortingField: CAPABILITY_STATUS_COL_ID,
  },
  {
    id: CREATED_ON_COL_ID,
    header: COL_ID_TO_NAME_MAP[CREATED_ON_COL_ID],
    cell: (item) => new Date(item.createdAt).toISOString().split('T')[0],
    sortingField: CREATED_ON_COL_ID,
  },
];

export const DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences = {
  visibleContent: [CAPABILITY_NAME_COL_ID, CAPABILITY_VERSION_COL_ID, CAPABILITY_STATUS_COL_ID, CREATED_ON_COL_ID],
  pageSize: 10,
};

export const VISIBLE_CONTENT_OPTIONS = [
  {
    label: 'Properties',
    options: [
      { id: CAPABILITY_VERSION_COL_ID, label: COL_ID_TO_NAME_MAP[CAPABILITY_VERSION_COL_ID] },
      { id: CAPABILITY_STATUS_COL_ID, label: COL_ID_TO_NAME_MAP[CAPABILITY_STATUS_COL_ID] },
      { id: CREATED_ON_COL_ID, label: COL_ID_TO_NAME_MAP[CREATED_ON_COL_ID] },
    ],
  },
];

export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: '10 functions' },
  { value: 30, label: '30 functions' },
  { value: 50, label: '50 functions' },
];

export const PAGINATION_LABELS = {
  nextPageLabel: 'Next page',
  pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page',
};

interface IPreferencesProps {
  preferences: typeof DEFAULT_PREFERENCES;
  setPreferences: (preferences: typeof DEFAULT_PREFERENCES) => void;
  visibleContentOptions?: typeof VISIBLE_CONTENT_OPTIONS;
  pageSizeOptions?: typeof PAGE_SIZE_OPTIONS;
}

export const Preferences = ({
  preferences,
  setPreferences,
  visibleContentOptions = VISIBLE_CONTENT_OPTIONS,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
}: IPreferencesProps) => (
  <CollectionPreferences
    title="Preferences"
    confirmLabel="Confirm"
    cancelLabel="Cancel"
    preferences={preferences}
    onConfirm={({ detail }) => setPreferences(detail)}
    visibleContentPreference={{
      title: 'Select visible columns',
      options: visibleContentOptions,
    }}
    pageSizePreference={{
      title: 'Page size',
      options: pageSizeOptions,
    }}
  />
);
