import { ColumnLayout, Container, Header, StatusIndicator } from '@cloudscape-design/components';
import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';

import { ArtifactPlatform, PipelineBuildStatus } from '../../../../common/types/enums';
import { AutomationBuildDetail } from '../../../../common/types/interfaces';
import { convertDateToISOWithTimeZone } from '../../../../common/utils';
import BoxElement, { HeadBoxElement } from '../../../../components/polaris/BoxElement';
import { selectGetAutomationDetailResponse } from '../../../../slices/AutomationDetailSlice';
import { PLATFORM_DISPLAY_MAP } from '../constants/page-constants';

export default function AutomationBuildInfoContainer() {
  const currentAutomation = useSelector(selectGetAutomationDetailResponse);
  if (!currentAutomation.data) {
    return;
  }

  const { automationDetail, artifactBuildInfoList } = currentAutomation.data;
  const automationTypePlatformList = _.cloneDeep(PLATFORM_DISPLAY_MAP[automationDetail.automationType] || []);

  const artifactBuildInfoMap = artifactBuildInfoList.reduce<{
    [key in ArtifactPlatform]?: AutomationBuildDetail;
  }>((artifactBuildInfoMap, artifactBuildInfo) => {
    artifactBuildInfoMap[artifactBuildInfo.platform] = artifactBuildInfo;
    return artifactBuildInfoMap;
  }, {});

  for (const automationTypePlatformInfo of automationTypePlatformList) {
    const artifactBuildInfo = artifactBuildInfoMap[automationTypePlatformInfo.artifactPlatform];
    if (artifactBuildInfo) {
      automationTypePlatformInfo.buildStatus = artifactBuildInfo.buildStatus;
      automationTypePlatformInfo.buildTimestamp = artifactBuildInfo.buildTimestamp;
    }
  }

  return (
    automationTypePlatformList.length > 0 && (
      <Container header={<Header variant="h2">Builds</Header>}>
        <ColumnLayout borders="vertical" columns={automationTypePlatformList.length}>
          {automationTypePlatformList.map((platformInfo) => (
            <HeadBoxElement
              key={platformInfo.artifactPlatform}
              headerText={platformInfo.platformDisplayText}
              description=""
            />
          ))}
          {automationTypePlatformList.map((platformInfo) => (
            <BoxElement
              key={platformInfo.artifactPlatform}
              headerText={'Last build status '}
              description={
                platformInfo.buildStatus ? (
                  <StatusIndicator
                    key={`build-status-indicator-${platformInfo.artifactPlatform}`}
                    iconAriaLabel=""
                    type={platformInfo.buildStatus === PipelineBuildStatus.SUCCESS ? 'success' : 'error'}
                  >
                    {platformInfo.buildStatus === PipelineBuildStatus.SUCCESS ? 'Pass' : 'Fail'}
                  </StatusIndicator>
                ) : (
                  '-'
                )
              }
            />
          ))}
          {automationTypePlatformList.map((platformInfo) => (
            <BoxElement
              key={`build-timestamp-${platformInfo.artifactPlatform}`}
              headerText="Last build on"
              description={
                platformInfo.buildTimestamp ? convertDateToISOWithTimeZone(new Date(platformInfo.buildTimestamp)) : '-'
              }
            />
          ))}
        </ColumnLayout>
      </Container>
    )
  );
}
