import { AppLayout, ContentLayout, Header, Spinner } from '@cloudscape-design/components';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { MY_BLOCKS } from '../../../common/constants/route-paths';
import { getMyBlockLibraryDetailPagePath } from '../../../common/page-route-path';
import { BlockDetailTabId, LoadingStatus, PageViewType } from '../../../common/types/enums';
import GlobalHelpPanel from '../../../components/GlobalHelpPanel';
import GlobalNavigationPanel from '../../../components/GlobalNavigationPanel';
import BreadcrumbLinkGroup from '../../../components/polaris/BreadcrumbLinkGroup';
import { AuthProfileSelector } from '../../../slices/AuthStateSlice';
import { getBlock, selectGetBlockResponse } from '../../../slices/BlockDetailSlice';
import { selectCreateCapabilityResponse } from '../../../slices/CapabilityDetailSlice';
import { selectIsNavigationPanelOpen, setNavigationPanelOpen } from '../../../slices/SitePreferencesSlice';
import { AppDispatch } from '../../../store/store';
import MyBlockFunctionRegisterForm from './component/MyBlockFunctionRegisterForm';

function MyBlockFunctionRegisterPageContent() {
  const currentBlock = useSelector(selectGetBlockResponse);

  return currentBlock.data ? (
    <ContentLayout header={<Header variant="h1">Register new function</Header>}>
      <MyBlockFunctionRegisterForm />
    </ContentLayout>
  ) : (
    <div className="tw-page-loading-spinner">
      <Spinner size="large" />
    </div>
  );
}

export default function MyBlockFunctionRegisterPage() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const isNavigationPanelOpen = useSelector(selectIsNavigationPanelOpen);
  const selectedAuthProfile = useSelector(AuthProfileSelector);
  const currentBlock = useSelector(selectGetBlockResponse);
  const selectCreateCapabilityResult = useSelector(selectCreateCapabilityResponse);

  const navigationChangeHandler = () => {
    dispatch(setNavigationPanelOpen(!isNavigationPanelOpen));
  };

  useEffect(() => {
    void dispatch(
      getBlock({
        blockId: params.blockId,
        clientId: selectedAuthProfile.ClientId,
        pageViewType: PageViewType.SELF_VIEW,
      }),
    );
  }, [selectedAuthProfile]);

  useEffect(() => {
    if (selectCreateCapabilityResult.status === LoadingStatus.SUCCESS) {
      navigate(getMyBlockLibraryDetailPagePath(params.blockId, BlockDetailTabId.FUNCTIONS));
    }
  }, [selectCreateCapabilityResult.status]);

  return (
    <AppLayout
      headerSelector="#tw-header"
      content={<MyBlockFunctionRegisterPageContent />}
      contentType="form"
      navigation={<GlobalNavigationPanel />}
      navigationOpen={isNavigationPanelOpen}
      onNavigationChange={navigationChangeHandler}
      tools={<GlobalHelpPanel />}
      breadcrumbs={
        <BreadcrumbLinkGroup
          links={
            currentBlock?.data
              ? [
                  {
                    text: 'My blocks',
                    href: MY_BLOCKS,
                  },
                  {
                    text: currentBlock.data.blockName,
                    href: getMyBlockLibraryDetailPagePath(params.blockId, BlockDetailTabId.FUNCTIONS),
                  },
                  {
                    text: 'Register new function',
                    href: '#',
                  },
                ]
              : [
                  {
                    text: 'My blocks',
                    href: MY_BLOCKS,
                  },
                  {
                    text: '',
                    href: '#',
                  },
                ]
          }
        />
      }
    />
  );
}
