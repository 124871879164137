import { Button, Header, SpaceBetween } from '@cloudscape-design/components';
import { FlashbarProps } from '@cloudscape-design/components/flashbar';
import React, { SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { LoadingStatus, PageViewType } from '../../../../common/types/enums';
import { AuthProfileSelector } from '../../../../slices/AuthStateSlice';
import {
  getBlockArtifactAsset,
  selectGetBlockArtifactAssetResponse,
  selectGetBlockResponse,
  selectListBlockArtifactsResponse,
} from '../../../../slices/BlockDetailSlice';
import { AppDispatch } from '../../../../store/store';
import PublishBlockArtifactModal from './PublishBlockArtifactModal';

interface MyBlockDetailPageContentHeaderProps {
  setNotifications: React.Dispatch<SetStateAction<FlashbarProps.MessageDefinition[]>>;
  removeNotification: (notificationId: string) => void;
}

export default function MyBlockDetailPageContentHeader(props: MyBlockDetailPageContentHeaderProps) {
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const selectedAuthProfile = useSelector(AuthProfileSelector);
  const currentBlock = useSelector(selectGetBlockResponse);
  const listBlockArtifactsData = useSelector(selectListBlockArtifactsResponse);
  const getBlockArtifactAssetData = useSelector(selectGetBlockArtifactAssetResponse);
  const [publishBlockArtifactModalVisible, setPublishBlockArtifactModalVisible] = useState(false);

  useEffect(() => {
    if (getBlockArtifactAssetData.status === LoadingStatus.SUCCESS) {
      window.location.href = getBlockArtifactAssetData.artifactAssetUrl;
    }
  }, [getBlockArtifactAssetData.status]);

  return (
    currentBlock.data && (
      <Header
        variant="h1"
        actions={
          <SpaceBetween direction="horizontal" size="l">
            <Button
              download
              onClick={() => {
                void dispatch(
                  getBlockArtifactAsset({
                    blockId: params.blockId,
                    version: listBlockArtifactsData.data[0].version,
                    clientId: selectedAuthProfile.ClientId,
                    pageViewType: PageViewType.SELF_VIEW,
                  }),
                );
              }}
              disabled={!listBlockArtifactsData.data?.length}
              loading={getBlockArtifactAssetData.status === LoadingStatus.PENDING}
            >
              Download
            </Button>
            <Button
              ariaLabel="Publish artifact button"
              variant="primary"
              onClick={() => setPublishBlockArtifactModalVisible(true)}
            >
              Publish
            </Button>
            <PublishBlockArtifactModal
              visible={publishBlockArtifactModalVisible}
              onDismiss={() => setPublishBlockArtifactModalVisible(false)}
              {...props}
            />
          </SpaceBetween>
        }
      >
        <SpaceBetween direction="vertical" size="s">
          {currentBlock.data.blockName}
        </SpaceBetween>
      </Header>
    )
  );
}
