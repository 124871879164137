import { useCollection } from '@cloudscape-design/collection-hooks';
import { Button, Header, Pagination, SpaceBetween, Table, TextFilter } from '@cloudscape-design/components';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CREATE_AUTOMATION } from '../../../../common/constants/route-paths';
import IAutomationRequestDetails from '../../../../common/interfaces/AutomationRequestDetails';
import { LoadingStatus } from '../../../../common/types/enums';
import { getMatchesCountText } from '../../../../common/utils';
import { EmptyState, NoMatchState } from '../../../../components/polaris/TableElement';
import { selectListAutomationRequestsResponse } from '../../../../slices/AutomationRequestSlice';
import { requestedDateColId } from '../config/queued-request-column-constants';
import {
  COLUMN_DEFINITIONS,
  DEFAULT_PREFERENCES,
  paginationLabels,
  Preferences,
} from '../config/queued-request-table.config';

interface IAutomationRequestDetailProps {
  requestsList: IAutomationRequestDetails[];
}

export default function AutomationQueuedRequestsTable({ requestsList }: IAutomationRequestDetailProps) {
  const navigate = useNavigate();
  const listAutomationRequestsData = useSelector(selectListAutomationRequestsResponse);
  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);
  const [selectedItems, setSelectedItems] = useState<IAutomationRequestDetails[]>([]);

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } =
    useCollection<IAutomationRequestDetails>(requestsList, {
      filtering: {
        empty: (
          <EmptyState>
            <b>No queued requests</b>
          </EmptyState>
        ),
        noMatch: (
          <NoMatchState
            clearFilterAction={() => {
              actions.setFiltering('');
            }}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {
        defaultState: {
          sortingColumn: COLUMN_DEFINITIONS.find((column) => column.id === requestedDateColId),
          isDescending: true,
        },
      },
    });

  return (
    <Table
      columnDefinitions={COLUMN_DEFINITIONS}
      {...collectionProps}
      items={items}
      loading={listAutomationRequestsData.status === LoadingStatus.PENDING}
      loadingText="Loading automation queued requests"
      resizableColumns
      header={
        <Header
          counter={`(${requestsList.length})`}
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                ariaLabel="create-automation"
                disabled={!selectedItems.length}
                onClick={() => {
                  navigate(CREATE_AUTOMATION, {
                    state: { requestId: selectedItems[0]?.requestId },
                  });
                }}
              >
                Create automation
              </Button>
            </SpaceBetween>
          }
        >
          Queued requests
        </Header>
      }
      filter={
        <TextFilter
          {...filterProps}
          countText={getMatchesCountText(filteredItemsCount)}
          filteringAriaLabel="Filter queued requests"
          filteringPlaceholder="Find queued requests"
        />
      }
      visibleColumns={preferences.visibleContent}
      pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
      preferences={<Preferences preferences={preferences} setPreferences={setPreferences} />}
      selectionType="single"
      onSelectionChange={({ detail }) => {
        setSelectedItems(detail.selectedItems);
      }}
      selectedItems={selectedItems}
    />
  );
}
