import { Amplify, Auth, Hub } from 'aws-amplify';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { QUERY_PARAMS } from './common/constants/string-constants';
import AuthProfileData from './common/interfaces/AuthProfileData';
import { AuthStates } from './common/types/enums';
import GlobalTopNavBar from './components/GlobalTopNavBar';
import SignInContainer from './components/SignInPage/SignInContainer';
import SignInTopNavBar from './components/SignInPage/SignInTopNavBar';
import awsConfig from './config/amplify.config';
import AppRoutes from './router/Routes';
import { AuthProfileSelector, selectAuthState, setAuthState } from './slices/AuthStateSlice';
import { AppDispatch } from './store/store';

Amplify.configure(awsConfig);

export default function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const authState = useSelector(selectAuthState);
  const selectedAuthProfile: AuthProfileData = useSelector(AuthProfileSelector);
  const dispatch = useDispatch<AppDispatch>();

  function setAuthProfileDataInQueryParam() {
    const searchParams = new URLSearchParams(location.search);
    if (
      (searchParams.get(QUERY_PARAMS.clientId) === selectedAuthProfile.ClientId &&
        searchParams.get(QUERY_PARAMS.role) === selectedAuthProfile.Role) ||
      !selectedAuthProfile.ClientId ||
      !selectedAuthProfile.Role
    ) {
      return;
    }

    searchParams.set(QUERY_PARAMS.clientId, selectedAuthProfile.ClientId);
    searchParams.set(QUERY_PARAMS.role, selectedAuthProfile.Role);
    Array.from(searchParams.keys()).forEach((queryParam) => {
      if (!(queryParam in QUERY_PARAMS)) {
        searchParams.delete(queryParam);
      }
    });

    navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
  }

  useEffect(() => {
    Auth.currentSession()
      .then(() => {
        dispatch(setAuthState(AuthStates.SIGNED_IN));

        setAuthProfileDataInQueryParam();
      })
      .catch(() => {
        dispatch(setAuthState(AuthStates.SIGNED_OUT));

        navigate(location.pathname, { replace: true });
      });
  }, [navigate, dispatch, selectedAuthProfile]);

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI': {
          dispatch(setAuthState(AuthStates.SIGNED_IN));
          break;
        }
        case 'signOut':
        case 'signIn_failure':
        case 'cognitoHostedUI_failure': {
          dispatch(setAuthState(AuthStates.SIGNED_OUT));
          break;
        }
      }
    });
  }, [dispatch]);

  if (authState !== AuthStates.SIGNED_IN) {
    return (
      <React.Fragment>
        <SignInTopNavBar />
        <SignInContainer onSignInClick={async () => await Auth.federatedSignIn()} />
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <GlobalTopNavBar onSignOutClick={async () => await Auth.signOut()} />
      <AppRoutes />
    </React.Fragment>
  );
}
