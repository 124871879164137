import { Box, Button, Modal, SpaceBetween, StatusIndicator } from '@cloudscape-design/components';
import { FlashbarProps } from '@cloudscape-design/components/flashbar';
import axios, { AxiosResponse } from 'axios';
import React, { SetStateAction, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { updateAutomationExecutableApi, uploadAutomationExecutableApi } from '../../../../api/automation-registry-api';
import { ArtifactEnvironment } from '../../../../common/types/enums';
import {
  getCompleteAutomationDetail,
  selectGetAutomationDetailResponse,
} from '../../../../slices/AutomationDetailSlice';
import { AppDispatch } from '../../../../store/store';

interface UpdateUIInputModalProps {
  visible: boolean;
  onDismiss: () => void;
  setNotifications: React.Dispatch<SetStateAction<FlashbarProps.MessageDefinition[]>>;
  removeNotification: (notificationId: string) => void;
  uiInputJSON: string;
}

export default function UpdateUIInputModal(props: UpdateUIInputModalProps) {
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const [updateUIInputError, setUpdateUIInputError] = useState('');
  const currentAutomation = useSelector(selectGetAutomationDetailResponse);

  async function saveAutomationArtifactExecutables() {
    const jsonContent = JSON.stringify(JSON.parse(props.uiInputJSON), null, 4);

    const uploadExecutableRequest = {
      automationId: currentAutomation.data.automationDetail.automationId,
      artifactEnvironment: ArtifactEnvironment.BETA,
    };
    const uploadExecutableResponse = await uploadAutomationExecutableApi(uploadExecutableRequest);

    const versionSet = uploadExecutableResponse.executableList.reduce(
      (versionSet, executable) => versionSet.add(executable.version),
      new Set<string>(),
    );
    if (versionSet.size !== 1) {
      throw new Error(
        'Invalid artifact version exists across platform. Please create same new version across platforms',
      );
    }

    const uploadPromises: Array<Promise<AxiosResponse>> = [];
    uploadExecutableResponse.executableList.forEach((executable) => {
      uploadPromises.push(axios.put(executable.uiInputPreSignedURL, jsonContent, { headers: {} }));
    });

    await Promise.all(uploadPromises);

    const updatePromises: Array<Promise<VoidFunction>> = [];
    uploadExecutableResponse.executableList.forEach((executable) => {
      const updateAutomationRequest = {
        automationId: params.automationId,
        executable: {
          environment: ArtifactEnvironment.BETA,
          platform: executable.platform,
          version: executable.version,
        },
      };
      updatePromises.push(updateAutomationExecutableApi(updateAutomationRequest));
    });

    await Promise.all(updatePromises);
  }

  async function handleConfirmButton() {
    try {
      setSubmitButtonLoading(true);
      setUpdateUIInputError('');

      await saveAutomationArtifactExecutables();

      void dispatch(getCompleteAutomationDetail(params.automationId));
      props.onDismiss();
      props.setNotifications((prevState) => [
        ...prevState,
        {
          header: currentAutomation.data?.automationDetail.automationName + ' UI Input successfully updated.',
          type: 'success',
          dismissible: true,
          dismissLabel: 'Dismiss message',
          onDismiss: () => props.removeNotification('api-success'),
          id: 'api-success',
        },
      ]);
    } catch (error: any) {
      setUpdateUIInputError(error?.message);
    } finally {
      setSubmitButtonLoading(false);
    }
  }

  return (
    <Modal
      visible={props.visible}
      onDismiss={() => props.onDismiss()}
      header="Save and deploy"
      closeAriaLabel="close-modal"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={() => props.onDismiss()}>
              Cancel
            </Button>
            <Button variant="primary" loading={submitButtonLoading} onClick={handleConfirmButton}>
              Confirm
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween direction="vertical" size="l">
        Saved changes will be automatically deployed. New UAT version will be available on Workplace Assistant and won’t
        be synced to Prod until you release to Prod again. Are you sure you want to save the UI changes?
        {updateUIInputError && (
          <StatusIndicator type="error" iconAriaLabel="error-label">
            {updateUIInputError}
          </StatusIndicator>
        )}
      </SpaceBetween>
    </Modal>
  );
}
