import { ControlledMultiselect, ControlledMultiselectProps } from '@amzn/react-hook-form-cloudscape';
import { FormField } from '@cloudscape-design/components';
import { FormFieldProps } from '@cloudscape-design/components/form-field';
import { MultiselectProps } from '@cloudscape-design/components/multiselect';
import React from 'react';
import { FieldValues, get, useFormContext } from 'react-hook-form';

interface IMultiselectFormFieldProps
  extends Partial<MultiselectProps>,
    FormFieldProps,
    ControlledMultiselectProps<FieldValues> {
  name: string;
  errorText?: string;
  i18nStrings?: MultiselectProps.I18nStrings & FormFieldProps.I18nStrings;
}

export default function MultiselectFormField(props: IMultiselectFormFieldProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <FormField {...props} errorText={get(errors, `${props.name}.message`)}>
      <ControlledMultiselect
        {...props}
        errorText={get(errors, `${props.name}.message`)}
        name={props.name}
        control={control}
      />
    </FormField>
  );
}
