import { Box, Cards, Grid, Header, SpaceBetween, StatusIndicator } from '@cloudscape-design/components';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ArtifactStatus, LoadingStatus, PageViewType } from '../../../../common/types/enums';
import BoxElement from '../../../../components/polaris/BoxElement';
import { AuthProfileSelector } from '../../../../slices/AuthStateSlice';
import { listBlockArtifacts, selectListBlockArtifactsResponse } from '../../../../slices/BlockDetailSlice';
import { AppDispatch } from '../../../../store/store';

export default function MyBlockExecutablesTab() {
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const selectedAuthProfile = useSelector(AuthProfileSelector);
  const listBlockArtifactsData = useSelector(selectListBlockArtifactsResponse);

  useEffect(() => {
    void dispatch(
      listBlockArtifacts({
        blockId: params.blockId,
        clientId: selectedAuthProfile.ClientId,
        pageViewType: PageViewType.SELF_VIEW,
      }),
    );
  }, [selectedAuthProfile]);

  if (!listBlockArtifactsData.data) {
    return;
  }

  return (
    <Cards
      cardDefinition={{
        header: (item) => (
          <Box variant="h3">
            <SpaceBetween size="s" direction="horizontal">
              <div>{item.version}</div>
              <div>
                {item.artifactStatus === ArtifactStatus.PUBLISHED && (
                  <StatusIndicator iconAriaLabel="success" type="success">
                    Published
                  </StatusIndicator>
                )}
              </div>
            </SpaceBetween>
          </Box>
        ),
        sections: [
          {
            header: '',
            content: (item) => (
              <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                <div>
                  <BoxElement
                    headerText="Updated on"
                    description={new Date(item.updatedAt).toISOString().split('T')[0]}
                  />
                </div>
                <div>
                  <BoxElement headerText="Updated by" description={item.updatedBy || '-'} />
                </div>
              </Grid>
            ),
          },
        ],
      }}
      header={
        <Header variant="h2" counter={`(${listBlockArtifactsData.data.length})`}>
          Executables
        </Header>
      }
      cardsPerRow={[{ cards: 1 }]}
      items={listBlockArtifactsData.data}
      loadingText="Loading executables"
      loading={listBlockArtifactsData.status === LoadingStatus.PENDING}
      empty={'The block doesn’t have any executable yet.'}
    />
  );
}
