import { HelpPanel, TextContent } from '@cloudscape-design/components';
import React from 'react';

export default function AutomationDetailHelpPanel() {
  return (
    <HelpPanel header={<h2>Development timeline </h2>}>
      <TextContent>
        <h4>Development start date</h4>
        <p>The date when you start building in Block Builder or when you create pipeline.</p>
        <h4>First release date</h4>
        <p>This is the date on which the automation was released to Prod for the first time.</p>
        <h4>Development time</h4>
        <p>
          If the automation has not been released in Prod, the development time is the days passed since the development
          start date.
        </p>
        <p>
          If the automation has been released in Prod, the development time is the number of days between the
          development start date and the first release date.
        </p>
      </TextContent>
    </HelpPanel>
  );
}
