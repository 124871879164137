import axios from 'axios';

import { teamRegistryApiEndpoint } from '../config/endpoints';

export interface IRegisterTeamRequest {
  teamName: string;
  authorizerId: string;
  authorizerType: string;
  entityType: string;
}

export interface IRegisterTeamResponse {
  teamId: string;
}

export async function registerTeamApi(requestBody: IRegisterTeamRequest) {
  const response = await axios.post<IRegisterTeamResponse>(`${teamRegistryApiEndpoint}/register-team`, requestBody);
  return response.data;
}
