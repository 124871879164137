import { HelpPanel, TextContent } from '@cloudscape-design/components';
import React from 'react';

import TALink from './polaris/TALink';

export default function GlobalHelpPanel() {
  return (
    <HelpPanel header={<h2>Help </h2>}>
      <TextContent>
        <p>
          Need help? Drop your question on our Slack channel{' '}
          <TALink external href="https://amzn-fgbs.slack.com/archives/C04T3VDAG7K">
            #taskless-workplace-automation-dev-community
          </TALink>
          .
        </p>
        <p>Let us know how we can improve your experience as a developer.</p>
        <p>
          <TALink external href="https://t.corp.amazon.com/create/templates/5740d047-54c2-4b60-866f-f616d351586b">
            Provide your feedback
          </TALink>
        </p>
      </TextContent>
    </HelpPanel>
  );
}
