import { ColumnLayout, Container, Header } from '@cloudscape-design/components';
import React from 'react';
import { useSelector } from 'react-redux';

import { extractCodeRepoName, extractPipelineName } from '../../../../common/utils';
import CopyText from '../../../../components/CopyText';
import BoxElement from '../../../../components/polaris/BoxElement';
import TALink from '../../../../components/polaris/TALink';
import { selectGetAutomationDetailResponse } from '../../../../slices/AutomationDetailSlice';

export default function AutomationPipelineInfoContainer() {
  const currentAutomation = useSelector(selectGetAutomationDetailResponse);
  if (!currentAutomation?.data) {
    return;
  }

  const { automationDetail, pipelineDetail } = currentAutomation.data;

  return (
    <Container header={<Header variant="h2">Pipeline details</Header>}>
      <ColumnLayout borders="vertical" columns={2}>
        <BoxElement
          headerText="AWS account ID"
          description={
            automationDetail.awsAccountId ? (
              <CopyText
                copyText={automationDetail.awsAccountId}
                copyButtonLabel="AWS account ID"
                successText="AWS account ID copied"
                errorText="Failed to copy"
              />
            ) : (
              '-'
            )
          }
        />
        <BoxElement
          headerText="Code repository"
          description={
            pipelineDetail.codeRepositoryUrl ? (
              <CopyText
                copyText={extractCodeRepoName(pipelineDetail.codeRepositoryUrl)}
                copyButtonLabel="Copy repository name"
                successText="Repository name copied"
                errorText="Failed to copy"
              />
            ) : (
              '-'
            )
          }
        />
        <BoxElement
          headerText="Code pipeline"
          description={
            pipelineDetail?.codePipelineUrl ? (
              <TALink external externalIconAriaLabel="Opens in new tab" href={pipelineDetail.codePipelineUrl}>
                {extractPipelineName(pipelineDetail.codePipelineUrl)}
              </TALink>
            ) : (
              '-'
            )
          }
        />
        <BoxElement
          headerText="SDK assume role ARN"
          description={
            automationDetail.sdkAccessRoleArn ? (
              <CopyText
                copyText={automationDetail.sdkAccessRoleArn}
                copyButtonLabel="SDK assume role ARN"
                successText="SDK assume role ARN copied"
                errorText="Failed to copy"
              />
            ) : (
              '-'
            )
          }
        />
      </ColumnLayout>
    </Container>
  );
}
