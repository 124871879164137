import { yupResolver } from '@hookform/resolvers/yup';
import { UseFormProps } from 'react-hook-form/dist/types';
import { boolean, InferType, object, string } from 'yup';
import {
  AUTOMATION_NAME,
  AUTOMATION_REQUEST,
  AUTOMATION_TYPE,
  EMAIL_ADDRESS,
  EXECUTION_ENVIRONMENT,
  IS_WINDOWS_APPLICATION_AUTOMATION,
} from '../constants/form-constants';

export const AutomationDetailSchema = object({
  [AUTOMATION_REQUEST]: string().required().length(36).label('Automation request'),
  [AUTOMATION_NAME]: string().required().min(1).max(200).label('Automation name'),
  [AUTOMATION_TYPE]: string().required().label('Executable type'),
  [EXECUTION_ENVIRONMENT]: string().required().label('Execution environment'),
  [IS_WINDOWS_APPLICATION_AUTOMATION]: boolean().label('Is windows application automation'),
  [EMAIL_ADDRESS]: string().required().email().min(10).max(100).label('Email address'),
});

export type AutomationDetailSchemaType = InferType<typeof AutomationDetailSchema>;

export const AutomationDetailsFormProps: UseFormProps<AutomationDetailSchemaType, any> = {
  mode: 'onSubmit',
  reValidateMode: 'onChange',
  resolver: yupResolver(AutomationDetailSchema),
};
