/**
 * This is a redux store configuration.
 */
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authStateReducer from '../slices/AuthStateSlice';
import automationDetailReducer from '../slices/AutomationDetailSlice';
import automationRequestReducer from '../slices/AutomationRequestSlice';
import blockDetailReducer from '../slices/BlockDetailSlice';
import capabilityDetailReducer from '../slices/CapabilityDetailSlice';
import infraManagerReducer from '../slices/InfraManagerSlice';
import sdkSearchReducer from '../slices/SDKSearchSlice';
import sitePreferencesStateReducer from '../slices/SitePreferencesSlice';
import teamDetailReducer from '../slices/TeamDetailSlice';

const persistConfig = {
  key: 'root',
  storage,
};
const reducer = combineReducers({
  authState: persistReducer(persistConfig, authStateReducer),
  sitePreferencesState: persistReducer(persistConfig, sitePreferencesStateReducer),
  automationDetail: automationDetailReducer,
  infraManager: infraManagerReducer,
  sdkSearch: sdkSearchReducer,
  blockDetail: blockDetailReducer,
  capabilityDetail: capabilityDetailReducer,
  teamDetail: teamDetailReducer,
  automationRequest: automationRequestReducer,
});

export const store = configureStore({ reducer });

export function getStoreWithState(preloadedState?: Partial<RootState>) {
  return configureStore({ reducer, preloadedState });
}

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
