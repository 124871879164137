import {
  Alert,
  Button,
  CodeEditor,
  Container,
  ContentLayout,
  FlashbarProps,
  Form,
  Grid,
  Header,
  Link,
  Select,
  SpaceBetween,
  Spinner,
} from '@cloudscape-design/components';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import { saveAs } from 'file-saver';
import React, { SetStateAction, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { CODE_EDITOR_I18N_STRINGS } from '../../../../common/constants/string-constants';
import { ArtifactEnvironment, AutomationType } from '../../../../common/types/enums';
import { getUIInputJSONFromPresignedURL, loadAce } from '../../../../common/utils';
import { selectGetAutomationDetailResponse } from '../../../../slices/AutomationDetailSlice';
import { UI_INPUT_FILENAME, UI_INPUT_JSON_DEFAULT_VALUE, UI_PREVIEW } from '../constants/page-constants';
import PreviewContainer from './PreviewContainer';
import UpdateUIInputModal from './UpdateUIInputModal';

interface InstantUIPreviewPageContentProps {
  setNotifications: React.Dispatch<SetStateAction<FlashbarProps.MessageDefinition[]>>;
  removeNotification: (notificationId: string) => void;
}

export default function InstantUIPreviewPageContent(props: InstantUIPreviewPageContentProps) {
  const currentAutomation = useSelector(selectGetAutomationDetailResponse);
  const [ace, setAce] = useState({});
  const [loading, setLoading] = React.useState(true);
  const [preferences, setPreferences] = React.useState({});
  const [uiInputJSON, setUIInputJSON] = useState('');
  const [isPreSignedURLFetched, setIsPreSignedURLFetched] = useState<boolean>(false);
  const [selectedEnvironment, setSelectedEnvironment] = React.useState<OptionDefinition>({
    label: ArtifactEnvironment.PROD,
    value: ArtifactEnvironment.PROD,
  });
  const [showMissingUIInputAlert, setShowMissingUIInputAlert] = useState<boolean>(false);
  const [showUIInputUpdateModalVisible, setShowUIInputUpdateModalVisible] = useState(false);

  useEffect(() => {
    loadAce()
      .then((ace) => setAce(ace))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (!currentAutomation.data) {
      return;
    }

    const artifactEnvironment = (selectedEnvironment.value as ArtifactEnvironment) || ArtifactEnvironment.BETA;
    void getUIInputJSONFromPresignedURL(currentAutomation.data.executableList, artifactEnvironment).then(
      (inputJSON) => {
        if (!inputJSON) {
          setShowMissingUIInputAlert(true);
          setUIInputJSON(JSON.stringify(JSON.parse(UI_INPUT_JSON_DEFAULT_VALUE), null, 4));
        } else {
          setShowMissingUIInputAlert(false);
          setUIInputJSON(JSON.stringify(inputJSON, null, 4));
        }
        setIsPreSignedURLFetched(true);
      },
    );
  }, [currentAutomation.data, selectedEnvironment]);

  const exportToJson = () => {
    const jsonContent = JSON.stringify(JSON.parse(uiInputJSON), null, 4);
    const blob = new Blob([jsonContent], { type: 'application/json' });
    saveAs(blob, UI_INPUT_FILENAME);
  };

  function checkIfUIInputIsUpdatable() {
    if (
      !new Set<AutomationType>([AutomationType.CLOUD, AutomationType.AUTO_DEV]).has(
        currentAutomation.data.automationDetail.automationType,
      )
    ) {
      return false;
    }

    if (selectedEnvironment.value !== ArtifactEnvironment.BETA) {
      return false;
    }

    const versionSet = currentAutomation.data.executableList
      .filter((executable) => executable.artifactEnvironment === selectedEnvironment.value)
      .reduce((versionSet, executable) => versionSet.add(executable.version), new Set<string>());

    return versionSet.size === 1;
  }

  if (!currentAutomation.data || !isPreSignedURLFetched) {
    return (
      <div className="tw-page-loading-spinner">
        <Spinner size="large" />
      </div>
    );
  }

  return (
    <ContentLayout
      header={
        <Header
          variant="h1"
          description={
            <SpaceBetween direction="horizontal" size="xxs">
              Configure the automation user interface by editing UIInput.json
              <Link external href="https://w.amazon.com/bin/view/TasklessWorkplace/Assistant/DynamicUIGeneration">
                [Wiki]
              </Link>
            </SpaceBetween>
          }
        >
          {currentAutomation.data.automationDetail.automationName} - {UI_PREVIEW}
        </Header>
      }
    >
      <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
        <Form
          actions={
            checkIfUIInputIsUpdatable() && (
              <>
                <Button variant="primary" onClick={() => setShowUIInputUpdateModalVisible(true)}>
                  Save
                </Button>
                <UpdateUIInputModal
                  visible={showUIInputUpdateModalVisible}
                  onDismiss={() => setShowUIInputUpdateModalVisible(false)}
                  uiInputJSON={uiInputJSON}
                  {...props}
                />
              </>
            )
          }
        >
          <Container>
            <SpaceBetween size="l">
              <Header
                variant="h2"
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    <Select
                      selectedOption={selectedEnvironment}
                      onChange={({ detail }) => setSelectedEnvironment(detail.selectedOption)}
                      options={[
                        { label: ArtifactEnvironment.PROD, value: ArtifactEnvironment.PROD },
                        { label: 'UAT', value: ArtifactEnvironment.BETA },
                      ]}
                    />
                    <Button variant={'primary'} onClick={exportToJson}>
                      Export
                    </Button>
                  </SpaceBetween>
                }
              >
                Configurator
              </Header>
              {showMissingUIInputAlert && (
                <Alert
                  statusIconAriaLabel="Info"
                  type="info"
                  dismissible={true}
                  onDismiss={() => setShowMissingUIInputAlert(false)}
                  header="UIInput.json file missing"
                >
                  UIInput.json missing from {selectedEnvironment.label} version.
                </Alert>
              )}
              <CodeEditor
                ace={ace}
                i18nStrings={CODE_EDITOR_I18N_STRINGS}
                language="json"
                loading={loading}
                onChange={({ detail }) => {
                  setUIInputJSON(detail.value);
                }}
                onPreferencesChange={(event) => setPreferences(event.detail)}
                preferences={preferences}
                value={uiInputJSON}
              />
            </SpaceBetween>
          </Container>
        </Form>
        <PreviewContainer uiInputJson={uiInputJSON} />
      </Grid>
    </ContentLayout>
  );
}
