import '../HomePage.scss';

import { useCollection } from '@cloudscape-design/collection-hooks';
import { Box, Button, Header, Input, Pagination, Select, SpaceBetween, Table } from '@cloudscape-design/components';
import { SelectProps } from '@cloudscape-design/components/select';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AutomationStatusConfig } from '../../../common/constants/automation-constants';
import { CREATE_AUTOMATION } from '../../../common/constants/route-paths';
import { AutomationStatus, LoadingStatus } from '../../../common/types/enums';
import { IListAutomationsData } from '../../../common/types/interfaces';
import { getMatchesCountText } from '../../../common/utils';
import { NoMatchState } from '../../../components/polaris/TableElement';
import { selectListAutomationsResponse } from '../../../slices/AutomationDetailSlice';
import {
  COLUMN_DEFINITIONS,
  DEFAULT_PREFERENCES,
  paginationLabels,
  Preferences,
} from '../config/automations-table.config';

function EmptyState() {
  const navigate = useNavigate();

  return (
    <Box textAlign="center" color="inherit">
      <SpaceBetween size="s">
        <b>No automation</b>
        <Button onClick={() => navigate(CREATE_AUTOMATION)}>Create automation</Button>
      </SpaceBetween>
    </Box>
  );
}

const defaultOptionOfAutomationStatus: SelectProps.Option = {
  value: 'any state',
  label: 'Any state',
};

function getSelectOptionsOfAutomationStatus(): SelectProps.Options {
  return [
    defaultOptionOfAutomationStatus,
    ...Object.values(AutomationStatusConfig)
      .filter((config) => config.automationStatus !== AutomationStatus.POST_MAINTENANCE_UAT)
      .map((config) => ({
        value: config.automationStatus,
        label: config.automationStatusDisplayText,
      })),
  ];
}

export default function AutomationsTable() {
  const navigate = useNavigate();
  const listAutomationsResponse = useSelector(selectListAutomationsResponse);
  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);
  const [selectedAutomationStatus, setSelectedAutomationStatus] = useState<SelectProps.Option>(
    defaultOptionOfAutomationStatus,
  );

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } =
    useCollection<IListAutomationsData>(listAutomationsResponse.data, {
      filtering: {
        filteringFunction: (item, filteringText) => {
          let currentAutomationStatus = item.automationStatus;
          if (item.automationStatus === AutomationStatus.POST_MAINTENANCE_UAT) {
            currentAutomationStatus = AutomationStatus.UAT;
          }

          if (
            selectedAutomationStatus.value !== defaultOptionOfAutomationStatus.value &&
            selectedAutomationStatus.value !== currentAutomationStatus
          ) {
            return false;
          }

          return preferences.visibleContent
            .map((columnName) => item[columnName as keyof IListAutomationsData])
            .some((value) => typeof value === 'string' && value.toLowerCase().includes(filteringText.toLowerCase()));
        },
        empty: <EmptyState />,
        noMatch: (
          <NoMatchState
            clearFilterAction={() => {
              actions.setFiltering('');
              setSelectedAutomationStatus(defaultOptionOfAutomationStatus);
            }}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {
        defaultState: {
          sortingColumn: COLUMN_DEFINITIONS[COLUMN_DEFINITIONS.length - 1],
          isDescending: true,
        },
      },
    });

  return (
    <Table
      columnDefinitions={COLUMN_DEFINITIONS}
      {...collectionProps}
      items={items}
      loading={listAutomationsResponse.status === LoadingStatus.PENDING}
      loadingText="Loading automation details"
      resizableColumns
      header={
        <Header
          actions={
            <Button variant="primary" onClick={() => navigate(CREATE_AUTOMATION)}>
              Create automation
            </Button>
          }
          counter={`(${listAutomationsResponse.data.length})`}
        >
          Automations
        </Header>
      }
      filter={
        <div className="ta-filter-container">
          <div className="ta-text-filter">
            <Input
              type="search"
              value={filterProps.filteringText}
              onChange={(event) => {
                actions.setFiltering(event.detail.value);
              }}
              ariaLabel="Filter automations"
              placeholder="Find automation"
            />
          </div>
          <div className="ta-select-filter">
            <Select
              expandToViewport={true}
              selectedOption={selectedAutomationStatus}
              onChange={(event) => setSelectedAutomationStatus(event.detail.selectedOption)}
              options={getSelectOptionsOfAutomationStatus()}
            />
          </div>
          <div aria-live="polite">
            {(filterProps.filteringText ||
              selectedAutomationStatus.value !== defaultOptionOfAutomationStatus.value) && (
              <span className="ta-filtering-results">{getMatchesCountText(filteredItemsCount)}</span>
            )}
          </div>
        </div>
      }
      visibleColumns={preferences.visibleContent}
      pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
      preferences={<Preferences preferences={preferences} setPreferences={setPreferences} />}
    />
  );
}
