import { JSONVisualizer, validateRunConfigForUIInputArray, ViewType } from '@amzn/taskless-workplace-ui-commons-lib';
import { Button, Container, Header, SegmentedControl, SpaceBetween } from '@cloudscape-design/components';
import React, { useEffect, useState } from 'react';

import { ASSISTANT_ID } from '../constants/page-constants';

interface PreviewContainerProps {
  readonly uiInputJson: string;
}

export default function PreviewContainer(props: PreviewContainerProps) {
  const [selectedId, setSelectedId] = useState(ASSISTANT_ID);
  const [enableRunButton, setEnableRunButton] = useState<boolean>(false);
  const [runConfig, setRunConfig] = useState<{ [name: string]: any }>([]);

  useEffect(() => {
    try {
      const uiInputJson = JSON.parse(props.uiInputJson);

      if (!uiInputJson.UIInputs.length) {
        setEnableRunButton(false);
      } else {
        const isRunConfigValid = validateRunConfigForUIInputArray(uiInputJson.UIInputs, runConfig);
        setEnableRunButton(isRunConfigValid);
      }
    } catch (e) {
      setEnableRunButton(false);
    }
  }, [props.uiInputJson]);

  const runConfigChangeHandler = (updatedRunConfig: { [name: string]: any }, isRunConfigValid: boolean) => {
    setRunConfig(updatedRunConfig);
    setEnableRunButton(isRunConfigValid);
  };

  return (
    <Container>
      <SpaceBetween size="l">
        <Header
          variant="h2"
          actions={
            <SegmentedControl
              selectedId={selectedId}
              onChange={({ detail }) => setSelectedId(detail.selectedId)}
              options={[
                { text: 'Assistant', id: ASSISTANT_ID },
                { text: 'Portal', id: 'portal' },
              ]}
            />
          }
        >
          UI Preview
        </Header>
        <div className="tw-ui-preview-scroll">
          <SpaceBetween size="l">
            <JSONVisualizer
              isUiEnabled={true}
              inputJson={props.uiInputJson}
              viewType={
                selectedId === ASSISTANT_ID ? ViewType.WORKPLACE_ASSISTANT : ViewType.RUN_ON_WORKPLACE_ASSISTANT
              }
              runConfigChangeHandler={runConfigChangeHandler}
            />
            <Button variant="primary" disabled={!enableRunButton}>
              Run
            </Button>
          </SpaceBetween>
        </div>
      </SpaceBetween>
    </Container>
  );
}
