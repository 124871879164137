import { array, boolean, InferType, object, string } from 'yup';
import { UseFormProps } from 'react-hook-form/dist/types';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  CAPABILITY_HANDLER,
  CAPABILITY_INPUT,
  CAPABILITY_NAME,
  CAPABILITY_OUTPUT,
  CAPABILITY_SUMMARY,
  HAS_NO_CAPABILITY_INPUT,
  HAS_NO_CAPABILITY_OUTPUT,
  MIN_BLOCK_ARTIFACT_VERSION,
  TAGS,
} from '../constants/form-constants';
import { isValidJSON } from '../../../../common/utils';

const UpdateCapabilitySchema = object({
  [CAPABILITY_NAME]: string().min(3).max(100).label('Name'),
  [CAPABILITY_HANDLER]: string().required().label('Resource name'),
  [TAGS]: array().max(10).label('Tags'),
  [CAPABILITY_SUMMARY]: string().min(10).max(500).label('Summary'),
  [MIN_BLOCK_ARTIFACT_VERSION]: string().required().label('Minimum supported block executable version'),
  [CAPABILITY_INPUT]: string().nullable().test(isValidJSON).label('Input'),
  [HAS_NO_CAPABILITY_INPUT]: boolean()
    .when(CAPABILITY_INPUT, {
      is: (val: string) => !val,
      then: boolean().equals([true], 'Tick the checkbox in case input is not required'),
      otherwise: boolean().equals([false], 'Untick the checkbox in case input is required'),
    })
    .label('Input'),
  [CAPABILITY_OUTPUT]: string().nullable().test(isValidJSON).label('Output'),
  [HAS_NO_CAPABILITY_OUTPUT]: boolean()
    .when(CAPABILITY_OUTPUT, {
      is: (val: string) => !val,
      then: boolean().equals([true], 'Tick the checkbox in case output is not required'),
      otherwise: boolean().equals([false], 'Untick the checkbox in case output is required'),
    })
    .label('Output'),
});

export type UpdateCapabilitySchemaType = InferType<typeof UpdateCapabilitySchema>;

export const UpdateCapabilityFormProps: UseFormProps<UpdateCapabilitySchemaType, any> = {
  mode: 'onSubmit',
  reValidateMode: 'onChange',
  resolver: yupResolver(UpdateCapabilitySchema),
};
